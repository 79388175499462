import { Routes, Route } from "react-router-dom"
import Landing from "./Landing"
import Register from '../components/register/Register'
// import Dashboard from '../components/NewDashboard/Main'
import Login from '../components/login/Login'
import Director from '../components/directorateadmin/Layout/Main'
import ChangePassword from "../components/forgotpassword/Forgotpassword"
import CollegePortal from "../components/collegeadmin/Layout/Main"

function Guest() {


  return (
    <>
    
    <Routes>
      <Route path="/*" element={<Landing/>}/>
      <Route path="/register" element={<Register/>}/>
      {/* <Route path="/main" element={<Dashboard/>}/> */}
      {/* <Route path="/admin/*" element={<Director/>}/> */}
      {/* <Route path="/college/*" element={<CollegePortal/>}/> */}
      <Route path="/login" element={<Login/>}/>
      <Route path="/changepassword" element={<ChangePassword/>}/>
      </Routes>
      
</>
  )
}

export default Guest
