import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-modal";

const Images = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    getImages();
  }, []);

  const getImages = () => {
    axios
      .get("http://backend.dirpc.form-seef.com/mou")
      .then((response) => {
        if (response.status === 200) {
          setImages(response.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalIsOpen(false);
  };

  return (
    <div className="container mx-auto py-4">
      <div className="p-10">
        {images.length > 0 ? (
          images.map((image) => (
            <div key={image.id} onClick={() => openModal(image)}>
              <div className="shadow cursor-pointer text-center text-xl font-bold underline bg-white p-4">
                MOU
                <img
                  src={"http://backend.dirpc.form-seef.com/mou" + image.image}
                  alt={image.mou_college_name}
                  className="border border-solid border-gray-300 rounded-md p-1"
                />
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-red-500">
            <p>No Image Found</p>
          </div>
        )}
      </div>

      {/* Modal for Image Zoom */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="overlay"
      >
        <div className="text-center">
          <img
            src={selectedImage ? `http://localhost:8000/MOUs/${selectedImage.image}` : ''}
            alt={selectedImage ? selectedImage.mou_college_name : ''}
            className="border border-solid border-gray-300 rounded-md p-1"
          />
        </div>
        <button onClick={closeModal} className="mt-4">
          Close
        </button>
      </Modal>
    </div>
  );
};

export default Images;
