import React, { useState, useEffect } from 'react';
import PrimaryNavbar from '../components/PrimaryNavbar';
import "../components/Navbar.css";
import TopNav from '../components/TopNav';
import Home from "../components/Home";
import About from "../components/About";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Vision from '../components/Vision';
import Leaders from '../components/Leaders';
import PrivateColleges from '../components/PrivateColleges';
import AuthUser from '../components/api/Api';
import { IoIosArrowUp } from 'react-icons/io';

const Landing = () => {
  const [showScrollUp, setShowScrollUp] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setShowScrollUp(scrollY > 184);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <TopNav />
      <div className="primary-navbar">
        <PrimaryNavbar />
      </div>
      <main>
        <div id="home">
          <Home />
        </div>
        <div id="vision">
          <Vision />
        </div>
        <div id="leaders">
          <Leaders />
        </div>
        <div id="privatecolleges">
          {/* <PrivateColleges /> */}
        </div>
        <div id="about">
          {/* <About /> */}
        </div>
        <div id="contact">
          {/* <Contact /> */}
        </div>
      </main>
      <Footer />
      {showScrollUp && (
        <div
          className="scroll-up"
          onClick={scrollToTop}
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            borderRadius: '50%',
            backgroundColor: '#007bff',
            width: '50px',
            height: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
            zIndex: '1000'
          }}
          onMouseEnter={(e) => { e.target.style.backgroundColor = '#0056b3'; }}
          onMouseLeave={(e) => { e.target.style.backgroundColor = '#007bff'; }}
        >
          <IoIosArrowUp size={30} color="#fff" />
        </div>
      )}
    </>
  );
};

export default Landing;
