import React, { useState, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSelectBox from "../../common/CustomSelectBox/CustomSelectBox";
import CustomInputBox from "../../common/CustomInputBox/CustomInputBox";
import Divider from "@mui/material/Divider";
import AuthUser from "../../../../../api/Api";

const AddCollegeOwnerInfo = ({ setEdit, collegeId, edit, nextStep }) => {
  const { http } = AuthUser();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const GET_COLLEGE_BASIC_FACILITIES = "college-basicfacilities";

  const [collegeBasicFacilities, setCollegeBasicFacilities] = useState({
    drinking_water_facility: "",
    alternative_energy_source: "",
    alternative_energy_type: "",
    alternative_other_energy_type: "",
    cctv_camera: "",
    fire_extinguisher: "",
    emergency_exit: "",
    first_aid: "",
    ramp_for_disable_person: "",
    transport_pick_drop: "",
  });

  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    // Your logic for any additional calculations or side effects
  }, [collegeBasicFacilities]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setCollegeBasicFacilities((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchData = async () => {
    try {
      const res = await http.get(`${GET_COLLEGE_BASIC_FACILITIES}/${collegeId}`);
      console.log('API Response:', res);
      if (res?.data?.data) {
        setCollegeBasicFacilities(res.data.data[0]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(collegeId); // Pass collegeId as an argument to fetchData
  }, [collegeId]);


  const handleSubmit = async () => {
    // console.log("College Basic Facilities:", collegeBasicFacilities);
    if (setEdit) {
      setButtonDisabled(true);
      setLoading(true);
      try {
        const res = await http.post(
          `${GET_COLLEGE_BASIC_FACILITIES}`,
          collegeBasicFacilities
        );
  
        // Show alert based on response message
        alert(res.data.message);
        // console.log("Data successfully updated!");
  
        // console.log("upload Response:", res);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error fetching data");
      } finally {
        nextStep();
      // Reset buttonDisabled to false after submission is completed
      setButtonDisabled(false);
      setLoading(false);
      }
    }
  };
  

  return (
    <div className="md:p-6 mt-3">
      <div className="mb-4">
        <button
          className="mt-5 shadow-sm border bg-white text-gray-500 rounded-lg w-28 h-8 flex justify-center items-center mb-10"
          onClick={() => setEdit(false)}
        >
          <IoIosArrowBack /> Back
        </button>
      </div>
      <div className="w-full border rounded-md">
        <div className="p-4 text-gray-400 border-b bg-gray-100">
          <h1 className="text-lg font-semibold">Basic Facilities</h1>
        </div>

        <div className="p-4">
          <CustomSelectBox
            label="Purified Drinking Water Facility"
            name="drinking_water_facility"
            value={collegeBasicFacilities?.drinking_water_facility}
            options={[
              { value: "R.O.", label: "R.O." },
              { value: "Water Filter", label: "Water Filter" },
              { value: "Dispenser", label: "Dispenser" },
              { value: "Cooler", label: "Cooler" },
            ]}
            onChange={handleChange}
          />

          <Divider className="p-2" />

          <CustomSelectBox
            label="Alternative Energy Source"
            name="alternative_energy_source"
            value={collegeBasicFacilities?.alternative_energy_source}
            options={[
              { value: "Yes", label: "Yes" },
              { value: "No", label: "No" },
            ]}
            onChange={handleChange}
          />

          {collegeBasicFacilities?.alternative_energy_source === "Yes" && (
            <>
              <CustomSelectBox
                label="Type of Alternative Energy"
                name="alternative_energy_type"
                value={collegeBasicFacilities?.alternative_energy_type}
                options={[
                  { value: "Solar", label: "Solar" },
                  { value: "Generator", label: "Generator" },
                  { value: "Others", label: "Others" },
                ]}
                onChange={handleChange}
              />

              {collegeBasicFacilities?.alternative_energy_type === "Others" && (
                <CustomInputBox
                  label="Type of Alternative Source of Energy"
                  name="alternative_other_energy_type"
                  value={collegeBasicFacilities?.alternative_other_energy_type}
                  onChange={handleChange}
                />
              )}
            </>
          )}

          <Divider className="p-2" />

          <CustomSelectBox
            label="CCTV Camera"
            name="cctv_camera"
            value={collegeBasicFacilities?.cctv_camera}
            options={[
              { value: "Yes", label: "Yes" },
              { value: "No", label: "No" },
            ]}
            onChange={handleChange}
          />

          <CustomSelectBox
            label="Fire Extinguisher"
            name="fire_extinguisher"
            value={collegeBasicFacilities?.fire_extinguisher}
            options={[
              { value: "Yes", label: "Yes" },
              { value: "No", label: "No" },
            ]}
            onChange={handleChange}
          />

          <CustomSelectBox
            label="Emergency Exit"
            name="emergency_exit"
            value={collegeBasicFacilities?.emergency_exit}
            options={[
              { value: "Yes", label: "Yes" },
              { value: "No", label: "No" },
            ]}
            onChange={handleChange}
          />

          <CustomSelectBox
            label="First Aid"
            name="first_aid"
            value={collegeBasicFacilities?.first_aid}
            options={[
              { value: "Yes", label: "Yes" },
              { value: "No", label: "No" },
            ]}
            onChange={handleChange}
          />

          <CustomSelectBox
            label="Ramp for Disable Person"
            name="ramp_for_disable_person"
            value={collegeBasicFacilities?.ramp_for_disable_person}
            options={[
              { value: "Yes", label: "Yes" },
              { value: "No", label: "No" },
            ]}
            onChange={handleChange}
          />

          <Divider className="p-2" />

          <CustomSelectBox
            label="Transport Pick & Drop By"
            name="transport_pick_drop"
            value={collegeBasicFacilities?.transport_pick_drop}
            options={[
              { value: "Parents", label: "Parents" },
              { value: "College", label: "College" },
              { value: "Both (Parents & College)", label: "Both (Parents & College)" },
            ]}
            onChange={handleChange}
          />

          {/* Additional fields for Basic Facilities can be added here */}

          <Divider className="p-2" />

          <div>
          <Button
  variant="contained"
  disabled={buttonDisabled}
  sx={{
    mt: 1,
    mr: 1,
    color: "white !important",
    backgroundColor: "#059669 !important",
  }}
  onClick={handleSubmit}
>
  {buttonDisabled ? (
    <div style={{ display: "flex", alignItems: "center" }}>
      <CircularProgress size={24} sx={{ marginRight: 1 }} />
      <span>Please wait...</span>
    </div>
  ) : (
    "Save & Next"
  )}
</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCollegeOwnerInfo;
