// Import necessary React components and libraries
import React, { useState, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import AuthUser from "../../../../../api/Api";
import IntermediateSection from './IntermediateCourse'
import DegreeTwoYears from "./DegreeTwoYears";
import DegreeFourYears from "./DegreeFourYears";
import CustomInputBox from '../../common/CustomInputBox/CustomInputBox';
// Main component for adding college enrollment details
const AddCollegeEnrollment = ({ setEdit, collegeId, edit, nextStep }) => {
  // Destructure the http function from AuthUser
  const { http } = AuthUser();
  // Define API endpoints
  const POST_COLLEGES_GENERAL = "colleges-general";
  const POST_INTERMEDIATE = "passpercentage";
  // const POST_DEGREE2YEARS = "deg-twoyears-enrollment";
  // const POST_DEGREE4YEARS = "deg-4years-enrollment";

  // State variables for managing loading, errors, and enrollment data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [selectedSemesterDetails, setselectedSemesterDetails] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('');
  const [collegelevel, setCollegelevel] = useState('');
  const [intermediateFaculty, setIntermediateFaculty] = useState([]);
  const [degreeFaculty, setDegreeFaculty] = useState([]);
  const [collegeProfileData, setCollegeProfileData] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [intermediateCourses, setIntermediateCourses] = useState([]);
  const [degreeCourses, setDegreeCourses] = useState([]);
  // const [ProgramOptions, setProgramOptions] = useState([]);

  // const [inputValues, setInputValues] = useState({});
  const [collegePasspercentage, setIntermediateEnrollment] = useState({
    overallTotal: 0,
    

  });
  const [acadYear, setAcadYear] = useState(""); // State for academic year
  // const handleInputChange = (inputValues) => {
  //   setInputValues(inputValues);
  // };

  const handleUpdate = (collegePasspercentage) => {
    setIntermediateEnrollment(collegePasspercentage);
  };

// console.log("at parent", collegePasspercentage)
  
  // Function to fetch data from the API
  const fetchData = async () => {
    try {
      // Fetch data from the specified endpoint
      const response = await http.get(`${POST_COLLEGES_GENERAL}/${collegeId}`);
      const responseData = response.data.data[0];

      // Extract relevant information from the API response
      const {
        college_intermediate_courses,
        college_degree_courses,
        college_category,
        college_level,
        college_program_type,
        college_program_offered,
      } = responseData;

      // Update state variables with the extracted data
      setCollegeProfileData({
        college_category,
        college_level,
        intermediate_courses: college_intermediate_courses,
        program_type: college_program_type,
        program_offered: college_program_offered,
        degree_courses: college_degree_courses,
      });

      setCollegelevel(college_level);
      setSelectedCategory(college_category);
      setIntermediateCourses(college_intermediate_courses);
      setIntermediateFaculty(college_intermediate_courses);
      setDegreeCourses(college_degree_courses);
      setDegreeFaculty(college_degree_courses);

    } catch (error) {
      setError("Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  // useEffect hook to fetch data when the component mounts
  useEffect(() => {
    fetchData(collegeId);
  }, []);

  // Function to handle intermediate enrollment data updates
  // const handleIntermediateEnrollmentData = (data) => {
  //   setEnrollmentData(data);
  // };

  // Function to handle form submission
 // Function to handle form submission
const handleSubmit = async () => {
  try {
    // Set loading state to true to indicate form submission is in progress
    setLoading(true);
    // Disable the button to prevent multiple submissions
    setButtonDisabled(true);

    // Prepare data for submission
    const postData = {
      college_level: collegeProfileData.college_level,
      college_category: collegeProfileData.college_category,
      college_passpercentage: collegePasspercentage,
      program_type: collegeProfileData.program_type,
      program_offered: collegeProfileData.program_offered,
      acadYear: acadYear, // Include academic year in the data to be submitted
    };

    // Log the data to be sent to the server
    console.log("Post Data:", postData);

    // Make a POST request to the specified endpoint
    const response = await http.post(POST_INTERMEDIATE, postData);

    // Log the server response
    // console.log("Server response:", response.data);

    // Show alert based on response message
    alert(response.data.message);
  } catch (error) {
    console.error("Error submitting data:", error);
  } finally {

    nextStep();
    // Reset loading state after form submission is completed
    // Enable the button after form submission completes (whether successful or failed)
    setButtonDisabled(false);
    setLoading(false);
  }
};

  // Function to render different program options based on college level and program type
  const renderProgramOptions = () => {
    if (collegeProfileData.college_level === "Intermediate") {
      return (
        <>
        {/* <div className="p-4 text-gray-600 mt-2 text-xl font-bold flex justify-between items-center">
            Category of College:
            <div className="text-lg uppercase text-blue-800 font-semibold ">
              {selectedCategory}
            </div>
          </div> */}
        <IntermediateSection
          intermediateCourses={intermediateFaculty}
          collegeCategory={selectedCategory}
          // onDataUpdate={handleIntermediateEnrollmentData}
          // onInputChange={handleInputChange}
          onDataUpdate={handleUpdate}
          />
          </>
      );
    } else if (
      collegeProfileData.college_level === "Degree" &&
      collegeProfileData.program_type === "2 Years"
    ) {
      return (
        <>
          <div className="p-4 border-y-4 text-gray-600 border-b-4 text-xl font-bold flex justify-between items-center">
            Program
            <div className="text-lg uppercase text-blue-800 font-semibold ">
              {collegeProfileData.program_type}
            </div>
          </div>
          {/* <div className="p-4 text-gray-600 mt-2 text-xl font-bold flex justify-between items-center">
            Category of College:
            <div className="text-lg uppercase text-blue-800 font-semibold ">
              {selectedCategory}
            </div>

          </div> */}
          <DegreeTwoYears
            degreeCourses={degreeFaculty}
            collegeCategory={selectedCategory}
            onUpdateDegreeTwoYears={handleUpdate}
          />
        </>
      );
    } else if (
      collegeProfileData.college_level === "Degree" &&
      collegeProfileData.program_type === "4 Years"
    ) {
      return (
        <>
          <div className="p-4 border-y-4 text-gray-600 border-b-4 text-xl font-bold flex justify-between items-center">
            Program
            <div className="text-lg uppercase text-blue-800 font-semibold ">
              {collegeProfileData.program_type}
            </div>
          </div>
          <div className="p-4 text-gray-600 border-b-4 text-xl font-bold flex justify-between items-center">
            Program Offered
            <div className="text-lg uppercase text-blue-800 font-semibold ">
              {collegeProfileData.program_offered}
            </div>
          </div>
          {/* <div className="p-4 text-gray-600 mt-2 text-xl font-bold flex justify-between items-center">
            Category of College:
            <div className="text-lg uppercase text-blue-800 font-semibold ">
              {selectedCategory}
            </div>
          </div> */}
          <DegreeFourYears
            collegeCategory={selectedCategory}
            onuUpdateDegreeFourYears={handleUpdate}
          />
        </>
      );
    }
    return null;
  };

  // Return the JSX for the component
  return (
    <div className="md:p-6 mt-3">
      <div className="mb-4">
        {/* Button to go back to the previous screen */}
        <button
          className="mt-5 shadow-sm border bg-white text-gray-500 rounded-lg w-28 h-8 flex justify-center items-center mb-10"
          onClick={() => setEdit(false)}
        >
          <IoIosArrowBack /> Back
        </button>
      </div>
      {/* Container for enrollment details */}
      <div className="w-full border rounded-md">
        {/* Header section */}
        <div className="p-4 text-gray-400 border-b bg-gray-100">
          <h1 className="text-lg font-semibold">RESULTS OF PAST YEARS</h1>
        </div>
        <div className="p-4">
          {/* Display college level and category details */}
          <div className="p-4 text-gray-600 border-b-4 text-xl font-bold flex justify-between items-center">
            Level of College:
            <div className="text-lg uppercase text-blue-800 font-semibold ">
              {collegeProfileData.college_level}
            </div>

            
          </div>
          <div className="p-4 text-gray-600 mt-2 text-xl font-bold flex justify-between items-center">
            Category of College:
            <div className="text-lg uppercase text-blue-800 font-semibold ">
              {selectedCategory}
            </div>
          </div>
          <div className="p-4 text-gray-600 mt-2 text-xl font-bold flex justify-between items-center">
    Academic Year:
    <div style={{ textAlign: 'right' }} className="flex text-center space-x-4">
      <CustomInputBox 
        // placeholder="2000"
        // label="Academic Year"
        value={acadYear}
        onChange={(e) => {
          const input = e.target.value;
          // Ensure only digits are entered
          const regex = /^[0-9]*$/;
          if (regex.test(input) && input.length <= 4) {
            setAcadYear(input);
          }
        }}
      />
      <div className=" text-blue-500 font-normal text-base underline w-full">
      Note: <br />For Academic Session (2019-20) Enter Year as 2019
    </div>
    </div>
  </div>
          {/* Render different program options based on college level and program type */}
          {renderProgramOptions()}
          {/* Button to submit the form */}

          
          <div>
          <Button
            variant="contained"
            disabled={buttonDisabled}
            sx={{
              mt: 1,
              mr: 1,
              color: "white !important",
              backgroundColor: "#059669 !important",
            }}
            onClick={handleSubmit}
          >
            {buttonDisabled ? (
              <>
                <CircularProgress size={24} color="inherit" />
                <span style={{ marginLeft: '8px' }}>Please wait...</span>
              </>
            ) : (
              "Save & Next"
            )}
          </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Export the component as the default export
export default AddCollegeEnrollment;
