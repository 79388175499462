import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import TablePagination from '@mui/material/TablePagination';
import AuthUser from "../../../../api/Api";

import {
  Paper,
  TextField,
  Button,
  FormControlLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
  Select,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const AddStudent = () => {
  const { http, collegeId } = AuthUser();
  const POST_STUDENT_URL = 'student';
  const GET_STUDENT_URL = 'student';

  const [studentList, setStudentList] = useState([]);
  const [newStudent, setNewStudent] = useState({
    name: '',
    fatherName: '',
    gender: 'Select Gender',
    dateOfBirth: '',
    Bform: '',
    class: '',
    admission_year: '',
    faculty: '',
    fee: 'Select',
    contact: '',
  });
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [editStudentId, setEditStudentId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [previewData, setPreviewData] = useState([]);
  const [isPreviewDialogOpen, setPreviewDialogOpen] = useState(false); // State to manage preview dialog

  useEffect(() => {
    fetchStudentList();
  }, []);

  const fetchStudentList = async () => {
    try {
      const response = await http.get(`${GET_STUDENT_URL}/${collegeId}`);
      setStudentList(response.data.data);
    } catch (error) {
      console.error('Error fetching student list:', error);
    }
  };

  // Utility function to group students by class
  const groupStudentsByClass = (students) => {
    return students.reduce((groups, student) => {
      const { class: studentClass } = student;
      if (!groups[studentClass]) {
        groups[studentClass] = [];
      }
      groups[studentClass].push(student);
      return groups;
    }, {});
  };

  const groupedStudents = groupStudentsByClass(studentList);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const exportToExcel = () => {
    const headers = [
      'Serial No.',
      'Name',
      "Father's Name",
      'Gender',
      'Date of Birth',
      'CNIC / B.Form No.',
      'Class',
      'Admission Year',
      'Faculty',
      'Category',
    ];
  
    const data = studentList.map((student, index) => {
      return [
        index + 1,
        student.name,
        student.fatherName,
        student.gender,
        student.dateOfBirth,
        student.Bform,
        student.class,
        student.admission_year,
        student.faculty,
        student.fee,
      ];
    });
  
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'StudentList');
    
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(dataBlob, 'StudentList.xlsx');
  };
  

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setEditStudentId(null);
    resetForm();
  };

  const resetForm = () => {
    setNewStudent({
      name: '',
      fatherName: '',
      gender: 'Select Gender',
      dateOfBirth: '',
      Bform: '',
      class: '',
      admission_year: '',
      faculty: '',
      fee: 'Select',
      contact: '',
    });
  };

  const addStudent = async () => {
    try {
      const response = await http.post(POST_STUDENT_URL, newStudent);
      setStudentList([...studentList, response.data]);
      closeDialog();
    } catch (error) {
      console.error('Error adding student:', error);
    }
  };

  const deleteStudent = async (id) => {
    try {
      await http.delete(`${POST_STUDENT_URL}/${id}`);
      setStudentList(studentList.filter((student) => student.id !== id));
    } catch (error) {
      console.error('Error deleting student:', error);
    }
  };

  const editStudent = async (id) => {
    const studentToEdit = studentList.find((student) => student.id === id);
    if (studentToEdit) {
      setNewStudent({ ...studentToEdit });
      setEditStudentId(id);
      openDialog();
    }
  };

  const updateStudent = async () => {
    try {
      await http.put(`${POST_STUDENT_URL}/${editStudentId}`, newStudent);
      const updatedStudentList = studentList.map((student) =>
        student.id === editStudentId ? { ...student, ...newStudent } : student
      );
      setStudentList(updatedStudentList);
      closeDialog();
    } catch (error) {
      console.error('Error updating student:', error);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false });
        const formattedData = jsonData.map(row => {
          const formattedRow = { ...row };
          // Check each cell in the row
          Object.keys(formattedRow).forEach(key => {
            const value = formattedRow[key];
            // Convert number to date string if it represents a date
            if (!isNaN(value) && typeof value === 'number') {
              const dateValue = XLSX.SSF.format('yyyy-mm-dd', value);
              formattedRow[key] = dateValue;
            }
          });
          return formattedRow;
        });
        setPreviewData(formattedData);
        openPreviewDialog();
      };
      reader.readAsArrayBuffer(file);
    }
  };
  
  const formatDate = (dateString) => {
    if (!dateString) return ''; // Handle empty dates
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split('T')[0];
    return formattedDate;
  };

  const openPreviewDialog = () => {
    setPreviewDialogOpen(true);
  };

  const closePreviewDialog = () => {
    setPreviewDialogOpen(false);
  };

  const confirmUpload = async () => {
    try {
      const response = await http.post(POST_STUDENT_URL + '/bulk', { data: previewData });
      if (response.status === 200) {
        alert('Bulk data uploaded successfully!');
        fetchStudentList();
        closePreviewDialog();
      }else {
        console.log('Unexpected response status:', response.status); // Log unexpected response status
        alert('Unexpected response status: ' + response.status); // Display unexpected response status in alert
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessage = Object.entries(error.response.data.errors)
          .map(([key, value]) => `${key}: ${value}`)
          .join('\n');
        alert('Error uploading bulk data:\n' + errorMessage); // Display error message with missing or erroneous fields
      } else {
        console.error('Error uploading bulk data:', error); // Log any other errors caught during the process
        alert('Error uploading bulk data: ' + error.message); // Display generic error message in alert
      }
    }
  };
  return (
    <Paper elevation={3} style={{ padding: '16px' }}>
      <Button variant="contained" color="primary" onClick={openDialog}>
        Add Student
      </Button>
      <Button variant="outlined" color="primary" onClick={exportToExcel} style={{ marginLeft: '16px' }}>
        Export to Excel
      </Button>

      <input
        accept=".xlsx, .xls"
        style={{ display: 'none' }}
        id="raised-button-file"
        type="file"
        onChange={handleFileUpload}
      />
      <label htmlFor="raised-button-file">
        <Button variant="outlined" color="primary" component="span" style={{ marginLeft: '16px' }}>
          Upload Excel
        </Button>
      </label>
      <Dialog
  className='p-18'
  open={isDialogOpen} onClose={closeDialog} disableBackdropClick
>
  <DialogTitle className='text-center uppercase underline'>Add Student</DialogTitle>
  <DialogContent className='flex flex-wrap space-y-4'>
    <TextField
      label="Name"
      fullWidth
      margin="normal"
      value={newStudent.name}
      onChange={(e) => setNewStudent({ ...newStudent, name: e.target.value })}
    />
    <TextField
      label="Father's Name"
      fullWidth
      margin="normal"
      value={newStudent.fatherName}
      onChange={(e) => setNewStudent({ ...newStudent, fatherName: e.target.value })}
    />
    <div className='w-full md:w-2/2 flex flex-col'>
      <h1 className='font-semibold'>Date of Birth</h1>
      <TextField
        type="date"
        fullWidth
        margin="normal"
        value={newStudent.dateOfBirth}
        onChange={(e) => setNewStudent({ ...newStudent, dateOfBirth: e.target.value })}
      />
    </div>
    <div className='w-full md:w-2/2 flex flex-col'>
      <h1 className='font-semibold'>B Form No.</h1>
      <TextField
        label="40001-1234567-1"
        fullWidth
        margin="normal"
        value={newStudent.Bform}
        onChange={(e) => setNewStudent({ ...newStudent, Bform: e.target.value })}
      />
    </div>
    <div className='w-full md:w-2/2 flex flex-col'>
      <h1 className='font-semibold'>Gender</h1>
      <Select
        value={newStudent.gender}
        onChange={(e) => setNewStudent({ ...newStudent, gender: e.target.value })}
      >
        <MenuItem value="Select Gender">Select Gender</MenuItem>
        <MenuItem value="Male">Male</MenuItem>
        <MenuItem value="Female">Female</MenuItem>
      </Select>
    </div>
    <TextField
      label="Class"
      fullWidth
      margin="normal"
      value={newStudent.class}
      onChange={(e) => setNewStudent({ ...newStudent, class: e.target.value })}
    /><TextField
      label="Year of Admission"
      fullWidth
      margin="normal"
      value={newStudent.admission_year}
      onChange={(e) => setNewStudent({ ...newStudent, admission_year: e.target.value })}
    />
    <TextField
      label="Faculty"
      fullWidth
      margin="normal"
      value={newStudent.faculty}
      onChange={(e) => setNewStudent({ ...newStudent, faculty: e.target.value })}
    />
    <TextField
      label="Contact No."
      fullWidth
      margin="normal"
      value={newStudent.contact}
      onChange={(e) => setNewStudent({ ...newStudent, contact: e.target.value })}
    />
    <div className='w-full md:w-2/2 flex flex-col'>
      <h1 className='font-semibold'>Fee Category</h1>
      <Select
        value={newStudent.fee}
        onChange={(e) => setNewStudent({ ...newStudent, fee: e.target.value })}
      >
        <MenuItem value="Select">Select</MenuItem>
        <MenuItem value="FEEPAYING">Full Fee</MenuItem>
        <MenuItem value="FREESHIP">Freeship</MenuItem>
      </Select>
    </div>
  </DialogContent>
  <DialogActions>
    <Button onClick={closeDialog} color="primary">
      Cancel
    </Button>
    <Button onClick={editStudentId ? updateStudent : addStudent} color="primary">
      {editStudentId ? 'Edit' : 'Add'}
    </Button>
  </DialogActions>
</Dialog>

<Dialog open={isPreviewDialogOpen} onClose={closePreviewDialog}>
        <DialogTitle>Preview Uploaded Data</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                {/* Render table headers dynamically based on preview data */}
                {previewData.length > 0 && Object.keys(previewData[0]).map((key) => (
                  <TableCell key={key}>{key}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {previewData.map((row, index) => (
                <TableRow key={index}>
                  {Object.values(row).map((value, cellIndex) => (
                    <TableCell key={cellIndex}>{value}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={closePreviewDialog} color="secondary">Cancel</Button>
          <Button onClick={confirmUpload} color="primary">Confirm Upload</Button>
        </DialogActions>
      </Dialog>


      {/* Render tables grouped by class */}
      {Object.entries(groupedStudents).map(([className, students]) => (
        <div key={className} style={{ marginTop: "20px" }}>
          <h3 className="font-bold uppercase underline">{`Class: ${className}`}</h3>
          <Table className="mt-3 border">
            <TableHead className="bg-gray-200">
              <TableRow>
                <TableCell>Serial No.</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Father Name</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>Date of Birth</TableCell>
                <TableCell>CNIC / B.Form No.</TableCell>
                <TableCell>Year of Admission</TableCell>
                <TableCell>Class</TableCell>
                <TableCell>Faculty</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students.map((student, index) => (
                <TableRow key={student.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{student.name}</TableCell>
                  <TableCell>{student.fatherName}</TableCell>
                  <TableCell>{student.gender}</TableCell>
                  <TableCell>{student.dateOfBirth}</TableCell>
                  <TableCell>{student.Bform}</TableCell>
                  <TableCell>{student.admission_year}</TableCell>
                  <TableCell>{student.class}</TableCell>
                  <TableCell>{student.faculty}</TableCell>
                  <TableCell>{student.fee}</TableCell>
                  <TableCell>
                    <IconButton edge="end" onClick={() => editStudent(student.id)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton edge="end" onClick={() => deleteStudent(student.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ))}

      <TablePagination
        rowsPerPageOptions={[5, 10, 100]}
        component="div"
        count={studentList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default AddStudent;
