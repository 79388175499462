import React, { useState, useEffect } from "react";

const CustomSelectBox = ({
  className = "",
  name,
  label,
  error,
  options,
  disabled,
  value: propValue,
  onChange,
  ...rest
}) => {
  const [selectedValue, setSelectedValue] = useState(propValue || "");

  useEffect(() => {
    setSelectedValue(propValue || "");
  }, [propValue]);

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className={className ? className : "my-4 w-full"}>
      <label
        htmlFor={name}
        style={{ color: "#AAA9A9" }}
        className="block text-sm font-bold mb-1"
      >
        {label}
      </label>
      <select
        id={name}
        name={name}
        autoComplete={name}
        value={selectedValue}
        onChange={handleChange}
        {...rest}
        className={`block w-full py-2 px-3 border bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm ${
          error ? "border-red-500" : "border-gray-300"
        }`}
      >
        <option aria-label="None" value="" />
        {options?.map((option, index) => (
          <option
            key={index}
            value={option?.value}
            disabled={option.disabled}
          >
            {option?.label}
          </option>
        ))}
      </select>
      {error && <p className="text-red-500 text-xs italic">{error}</p>}
    </div>
  );
};

export default CustomSelectBox;
