

import React, { useState, useEffect } from 'react';
import CustomInputBox from '../../common/CustomInputBox/CustomInputBox';

const IntermediateEnrollment = ({ intermediateCourses, collegeCategory, onDataUpdate, collegeEnrollment  }) => {
  const [selectedFaculties, setSelectedFaculties] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(''); // Default to boys
  const [inputValues, setInputValues] = useState({});
  const [intermediateEnrollment, setIntermediateEnrollment] = useState({
    overallTotal: 0,
    facultyTotal: 0,
    totalXI: 0,
    totalXII: 0,
    inputValues: 0,
  });

  useEffect(() => {
    if (collegeEnrollment && Object.keys(collegeEnrollment).length > 0) {
      setIntermediateEnrollment(collegeEnrollment);
    }
  }, [collegeEnrollment]);
  // state set from the parent component
  useEffect(() => {
    // Check if intermediatecourses is an object with the expected properties
    if (intermediateCourses && Array.isArray(intermediateCourses)) {
      // Set selected faculties directly from intermediatecourses
      setSelectedFaculties(intermediateCourses);
    } else {
      // console.error("intermediatecourses is not in the expected format:", intermediatecourses);
    }

    // Set selected category
    if (collegeCategory) {
      setSelectedCategory(collegeCategory);
    } else {
      console.error("college category is not defined or empty:", collegeCategory);
    }

    // Log the selected category value
    // console.log("selected category:", selectedCategory);

    // fetchData();
    // console.log("Data :", intermediateCourses, selectedCategory, collegeEnrollment);
  }, [intermediateCourses, collegeCategory, collegeEnrollment]);

  useEffect(() => {
   

    const grandTotal = selectedFaculties.reduce((acc, faculty) => {
      const xiBoys = (selectedCategory === 'Boys' || selectedCategory === 'Co-Education') ? (inputValues[`${faculty}_XI_boys`] || 0) : 0;
      const xiGirls = (selectedCategory === 'Girls' || selectedCategory === 'Co-Education') ? (inputValues[`${faculty}_XI_girls`] || 0) : 0;
      const totalXI = xiBoys + xiGirls;

      const xiiBoys = (selectedCategory === 'Boys' || selectedCategory === 'Co-Education') ? (inputValues[`${faculty}_XII_boys`] || 0) : 0;
      const xiiGirls = (selectedCategory === 'Girls' || selectedCategory === 'Co-Education') ? (inputValues[`${faculty}_XII_girls`] || 0) : 0;
      const totalXII = xiiBoys + xiiGirls;
      const Total = totalXI + totalXII;

 // Accumulate the totals for boys and girls separately
 const boysTotal = xiBoys + xiiBoys;
 const girlsTotal= xiGirls + xiiGirls;

      const facultyData = {
        faculty,
        
  [`${faculty}_XI_Boys`]: xiBoys,
  [`${faculty}_XI_Girls`]: xiGirls,
  [`${faculty}_Total_XI`]: xiBoys + xiGirls,
  [`${faculty}_XII_Boys`]: xiiBoys,
  [`${faculty}_XII_Girls`]: xiiGirls,
  [`${faculty}Total_XII`]: xiiBoys + xiiGirls,
  [`${faculty}_Total`]: Total,
  [`${faculty}_boysTotal`]: xiBoys + xiiBoys,
  [`${faculty}_girlsTotal`]: xiGirls + xiiGirls,
      };


      return {
        overallTotal: acc.overallTotal + Total,
        totalBoys: acc.totalBoys + boysTotal ,
        totalGirls: acc.totalGirls + girlsTotal,
        facultyData: [...acc.facultyData, facultyData],
      };
    }, {
      overallTotal: 0,
      facultyData: [],
      totalBoys:0,
      totalGirls:0
    });

    setIntermediateEnrollment(grandTotal);
    onDataUpdate(grandTotal);
    // Display console value after update
    // console.log("Updated state:", grandTotal, inputValues);
  }, [selectedFaculties, selectedCategory, inputValues]);

  const handleInputChange = (faculty, type, gender, value) => {
    setInputValues({
      ...inputValues,
      
      [`${faculty}_${type}_${gender}`]: value,
    });

    // console.log("Input Values updated", inputValues);
  };

  // Use useEffect to log inputValues after it's updated
  // useEffect(() => {
  //   // console.log("Input Values updated", inputValues);
  // }, [inputValues]);

  const generateInputFields = (faculty) => {
    const xiBoys = (selectedCategory === 'Boys' || selectedCategory === 'Co-Education') ? (inputValues[`${faculty}_XI_boys`] || 0) : 0;
    const xiGirls = (selectedCategory === 'Girls' || selectedCategory === 'Co-Education') ? (inputValues[`${faculty}_XI_girls`] || 0) : 0;
    const totalXI = xiBoys + xiGirls;

    const xiiBoys = (selectedCategory === 'Boys' || selectedCategory === 'Co-Education') ? (inputValues[`${faculty}_XII_boys`] || 0) : 0;
    const xiiGirls = (selectedCategory === 'Girls' || selectedCategory === 'Co-Education') ? (inputValues[`${faculty}_XII_girls`] || 0) : 0;
    const totalXII = xiiBoys + xiiGirls;
    const facultyTotal = totalXI + totalXII;

    return (
      <div key={faculty} className="m-4 border-8 p-4">
        {/* Overall Total at the top */}
        <div className="border p-4">
          <div className="border-4  rounded-lg flex text-xl font-bold items-center justify-between p-4">
            <div className="text-xl font-bold ">{faculty}</div>
            <div className='flex justify-between space-x-4'>
              <div>Total : </div>
              <div>{facultyTotal}</div>
            </div>
          </div>
        </div>
        {/* XI ENROLLMENT */}
        <div className="grid grid-cols-2 gap-4">
          {selectedCategory === 'Boys' && (
            <>
              <CustomInputBox
                label="XI Boys"
                value={xiBoys}
                onChange={(e) => handleInputChange(faculty, 'XI', 'boys', parseInt(e.target.value, 10))}
              />
              <CustomInputBox
                label="Total XI"
                value={totalXI}
                disabled
              />
            </>
          )}

          {selectedCategory === 'Girls' && (
            <>
              <CustomInputBox
                label="XI Girls"
                value={xiGirls}
                onChange={(e) => handleInputChange(faculty, 'XI', 'girls', parseInt(e.target.value, 10))}
              />
              <CustomInputBox
                label="Total XI"
                value={totalXI}
                disabled
              />
            </>
          )}
        </div>
        <div className="grid grid-cols-3 gap-4">
          {selectedCategory === 'Co-Education' && (
            <>
              <CustomInputBox
                label="XI Boys"
                value={xiBoys}
                onChange={(e) => handleInputChange(faculty, 'XI', 'boys', parseInt(e.target.value, 10))}
              />
              <CustomInputBox
                label="XI Girls"
                value={xiGirls}
                onChange={(e) => handleInputChange(faculty, 'XI', 'girls', parseInt(e.target.value, 10))}
              />
              <CustomInputBox
                label="Total XI"
                value={totalXI}
                disabled
              />
            </>
          )}
        </div>
        {/* XII ENROLLMENT */}
        <div className="grid grid-cols-2 gap-4">
          {selectedCategory === 'Boys' && (
            <>
              <CustomInputBox
                label="XII Boys"
                value={xiiBoys}
                onChange={(e) => handleInputChange(faculty, 'XII', 'boys', parseInt(e.target.value, 10))}
              />
              <CustomInputBox
                label="Total XII"
                value={totalXII}
                disabled
              />
            </>
          )}
          {selectedCategory === 'Girls' && (
            <>
              <CustomInputBox
                label="XII Girls"
                value={xiiGirls}
                onChange={(e) => handleInputChange(faculty, 'XII', 'girls', parseInt(e.target.value, 10))}
              />
              <CustomInputBox
                label="Total XII"
                value={totalXII}
                disabled
              />
            </>
          )}
        </div>
        <div className="grid grid-cols-3 gap-4">
          {selectedCategory === 'Co-Education' && (
            <>
              <CustomInputBox
                label="XII Boys"
                value={xiiBoys}
                onChange={(e) => handleInputChange(faculty, 'XII', 'boys', parseInt(e.target.value, 10))}
              />
              <CustomInputBox
                label="XII Girls"
                value={xiiGirls}
                onChange={(e) => handleInputChange(faculty, 'XII', 'girls', parseInt(e.target.value, 10))}
              />
              <CustomInputBox
                label="Total XII"
                value={totalXII}
                disabled
              />
            </>
          )}
        </div>
      </div>
    );
  };

  // Calculate overall total enrollment for all faculties
  // Calculate overall total enrollment for all selected faculties

  return (
    <div className="space-y-4">
      <div className="border p-4">
        <div className="border-4 rounded-lg flex text-xl font-bold items-center justify-between p-4">
          <div>Total Enrollment</div>
          <div>{intermediateEnrollment.overallTotal}</div>
        </div>
      </div>
      {/* Display input fields for selected faculties */}
      {selectedFaculties.map((faculty) => generateInputFields(faculty))}

      {/* Overall Total at the top */}
    </div>
  );
};

export default IntermediateEnrollment;
