import React from "react";
import CustomSelectBox from "../../common/CustomSelectBox/CustomSelectBox";
import CustomInputBox from "../../common/CustomInputBox/CustomInputBox";

const DegreeCourses = ({
  collegeProfileData,
  handleChange,
  setCollegeProfileData,
}) => {
  const durationOptions = [
    { value: "2 Years", label: "2 Years Program" },
    { value: "4 Years", label: "4 Years Program" },
  ];

  const renderDegreeCoursesCheckboxes = () => {
    const degreeCourses = [
      { value: "ADA", label: "ADA" },
      { value: "ADC", label: "ADC" },
      { value: "ADS", label: "ADS" },
    ];

    return (
      <div className="flex space-x-20 justify-center">
        {degreeCourses.map((course) => (
          <label key={course.value} className="flex items-center space-x-4">
            <input
              type="checkbox"
              name="college_selected_courses"
              value={course.value}
              checked={collegeProfileData.college_degree_courses?.includes(
                course.value
              )}
              onChange={handleChange}
            />
            <span>{course.label}</span>
          </label>
        ))}
      </div>
    );
  };

  return (
    <div className=" mt-3">
      <div>
        <CustomSelectBox
          label="Program Duration"
          name="college_program_type"
          value={collegeProfileData.college_program_type}
          options={durationOptions}
          onChange={handleChange}
        />
        {collegeProfileData.college_program_type === "2 Years" ? (
          renderDegreeCoursesCheckboxes()
        ) : collegeProfileData.college_program_type === "4 Years" ? (
          <CustomInputBox
            label="Program Offered"
            name="college_program_offered"
            value={collegeProfileData.college_program_offered}
            onChange={handleChange}
          />
        ) : null}
      </div>
    </div>
  );
};

export default DegreeCourses;
