// Profile.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import AuthUser from '../../../../api/Api';

import Dashboard from './dashboard/CollegeDashboard';
import Building from './profile/building/Building-Information';
import CollegeGeneralInfo from './profile/General-Information';
import CollegeOwnerShip from './profile/Owner-Information';
import Enrollment from './profile/EnrollmentResult/EnrollmentResult';
import PrevResult from './profile/PrevResult/Result';
import RegDet from './profile/Reg_Aff/RegDet';

const Profile = () => {
  const { college_id } = useParams(); // Get college_id from URL parameters
  const { http } = AuthUser();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});
  console.log('profiledata', data);

  useEffect(() => {
    fetchData();
  }, [college_id]); // Refetch data when college_id changes

  const fetchData = async () => {
    try {
      const response = await http.get(`college-profile/${college_id}`);
      setData(response.data.data);
      console.log('response',data)
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data');
      setLoading(false);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <Box>
      <div className=' flext p-4 underline justify-center'>

      <Typography variant="h4">{data.general[0]?.college_name}</Typography>
      </div>
     <Dashboard
        collegeregistrationData={data.registration}
        collegestaffData={data.staff}
        collegestudentData={data.students}
      />
      <div>
        {/* <Typography variant="subtitle1">ID: {college_id}</Typography> */}
        <CollegeGeneralInfo generaldata={data.general} />
        <CollegeOwnerShip ownershipdata={data.ownership} />
        <Building buildingdata={data.buildings[0]} />
        <Enrollment enrollmentdata={data.enrollment} />
        <PrevResult pastresultdata={data.results[0]} />
        <RegDet registrationdata={data.registration_details} />
      </div>
    </Box>
  );
};

export default Profile;
