import React, { useState, useEffect } from "react";
import { HiPencil } from "react-icons/hi";

const CollegeEnrollment = ({ enrollmentdata }) => {
  const [error, setError] = useState(null);
  const [selectedSemesterDetails, setSelectedSemesterDetails] = useState({});
  const [CollegeEnrollment, setCollegeEnrollment] = useState({});
  const [degreeCourses, setDegreeCourses] = useState([]);

  const formatAcademicYear = (year) => {
    if (!year) return "N/A";
    const nextYear = parseInt(year) + 1;
    return `${year}-${nextYear.toString().slice(-2)}`;
  };

  useEffect(() => {
    if (enrollmentdata) {
      setCollegeEnrollment(enrollmentdata);
    }
  }, [enrollmentdata]);
  const generateInputFields = (faculty) => (
    <div>
      <div key={faculty} className="border-4 p-3 mt-4 mb-4">
      {/* <h2 className="text-xl font-semibold mb-2">{`${faculty.faculty} Enrollment Details`}</h2> */}
      <table className="w-full border-collapse border">
        <thead>
          <tr className="border">
            <th className="w-1/5 text-center border p-2">Class</th>
            <th className="w-1.5/5 text-center border p-2">Boys</th>
            <th className="w-1.5/5 text-center border p-2">Girls</th>
            <th className="w-2.5/5 text-center border p-2">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border">
            <td className="w-1/5 text-center border p-2">XI</td>
            <td className="w-1.5/5 text-center border p-2">{faculty[`${faculty.faculty}_XI_Boys`] || 0}</td>
            <td className="w-1.5/5 text-center border p-2">{faculty[`${faculty.faculty}_XI_Girls`] || 0}</td>
            <td className="w-2.5/5 text-center border p-2">{faculty[`${faculty.faculty}_Total_XI`] || 0}</td>
          </tr>
          <tr className="border">
            <td className="w-1/5 text-center border p-2">XII</td>
            <td className="w-1.5/5 text-center border p-2">{faculty[`${faculty.faculty}_XII_Boys`] || 0}</td>
            <td className="w-1.5/5 text-center border p-2">{faculty[`${faculty.faculty}_XII_Girls`] || 0}</td>
            <td className="w-2.5/5 text-center border p-2">{faculty[`${faculty.faculty}Total_XII`] || 0}</td>
          </tr>
        </tbody>
      </table>
    </div>
      
    
    </div>
  );
  
  const renderProgramOptions = () => {
    if (CollegeEnrollment?.college_level === "Intermediate") {
      return (
        <>
        <div className="md:flex p-2">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                College Category
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {CollegeEnrollment?.college_category
                  ? CollegeEnrollment?.college_category
                  : "N/A"}
              </p>
            </div>
            
            </div>
            <div className="md:flex p-2">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Academic Session
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
              {formatAcademicYear(CollegeEnrollment?.acadYear)}
                {/* {CollegeEnrollment?.acadYear
                  ? CollegeEnrollment?.acadYear
                  : "N/A"} */}
              </p>
            </div>
            
            </div>
            
        <div className="border-4 flex justify-between p-2  font-bold text-xl">
        <div>
          Total Enrollment :
      </div>
      <div>
        {CollegeEnrollment.college_enrollment.overallTotal}
          </div>
        </div>
        
        <div>
        <div className=" justify-between underline p-2 font-bold text mt-2">
         Faculty / Gender / Class Wise Enrollment :
        </div>

          {CollegeEnrollment.college_enrollment.facultyData.map((faculty, index) => (
            <div
              key={faculty.index}
              className="p-1 m-2 border-2 uppercase font-semibold space-x-2"
            >
              <span className="underline text">{faculty.faculty}</span>
              
              {/* <div className="font-semibold text-right px-5">
                Total Enrollment in {course.label}:{" "}
                {calculateFacultyTotal(course.value, "xi") +
                  calculateFacultyTotal(course.value, "xii")}
              </div> */}
              {generateInputFields(faculty, index)} {/* Display data instead of input fields */}
            </div>
          ))}
        </div>
        
        </>
      );
    } 
    // else if (CollegeEnrollment.college_level === "Degree") {
    //   return (
    //     <div className="p-4 text-gray-600 text-xl font-semibold flex justify-between items-center">
    //       Program Type:
    //       <div className="text-lg uppercase text-blue-800 font-semibold ">
    //         {CollegeEnrollment.program_type}
    //       </div>
    //     </div>
    //   );
    // }
  };
  
  
  const generateDegreeCoursesInputFields = (faculty) => (
   <div key={faculty} className="border-4 p-3 mt-4 mb-4">
      {/* <h2 className="text-xl font-semibold mb-2">{`${faculty.faculty} Enrollment Details`}</h2> */}
      <table className="w-full border-collapse border">
        <thead>
          <tr className="border">
            <th className="w-1/5 text-center border p-2">Class</th>
            <th className="w-1.5/5 text-center border p-2">Boys</th>
            <th className="w-1.5/5 text-center border p-2">Girls</th>
            <th className="w-2.5/5 text-center border p-2">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border">
            <td className="w-1/5 text-center border p-2">1st year</td>
            <td className="w-1.5/5 text-center border p-2">{faculty[`${faculty.faculty}_XI_Boys`] || 0}</td>
            <td className="w-1.5/5 text-center border p-2">{faculty[`${faculty.faculty}_XI_Girls`] || 0}</td>
            <td className="w-2.5/5 text-center border p-2">{faculty[`${faculty.faculty}_Total_XI`] || 0}</td>
          </tr>
          <tr className="border">
            <td className="w-1/5 text-center border p-2">2nd year</td>
            <td className="w-1.5/5 text-center border p-2">{faculty[`${faculty.faculty}_XII_Boys`] || 0}</td>
            <td className="w-1.5/5 text-center border p-2">{faculty[`${faculty.faculty}_XII_Girls`] || 0}</td>
            <td className="w-2.5/5 text-center border p-2">{faculty[`${faculty.faculty}Total_XII`] || 0}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
  
  
  

  const generateFourYearsProgramInputFields = () => {
    return (
      <div>
        <div>
          <div className="text-right px-3 mb-3">
            {/* <span className="font-bold">
                Total: {calculateTotalForFourYearsProgram()}
              </span> */}
          </div>
  
          <div className="border-4 p-3 mt-4 mb-4">
            <table className="w-full border-collapse border">
              <thead>
                <tr className="border">
                  <th className="w-1/5 text-center border p-2">Semester</th>
                  <th className="w-1/5 text-center border p-2">Boys</th>
                  <th className="w-1/5 text-center border p-2">Girls</th>
                  <th className="w-1/5 text-center border p-2">Total</th>
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3, 4, 5, 6, 7, 8].map((semester) => (
                  <tr key={semester} className="border">
                    <td className="w-1/5 text-center border p-2">{`Semester ${semester}`}</td>
                    <td className="w-1/5 text-center border p-2">
                      {CollegeEnrollment?.college_enrollment?.inputValues[`${semester}_boys`] || 0}
                    </td>
                    <td className="w-1/5 text-center border p-2">
                      {CollegeEnrollment?.college_enrollment?.inputValues[`${semester}_girls`] || 0}
                    </td>
                    <td className="w-1/5 text-center border p-2">
                    {CollegeEnrollment?.college_enrollment?.semesterTotal[`${semester}`] || 0}
                      {/* You may need to calculate the total here based on your data */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };
  
  
  

  const renderDegreeProgramOptions = () => {
    if (
      CollegeEnrollment?.college_level === "Degree" &&
      CollegeEnrollment?.program_type === "2 Years"
    ) {
      return (
        <>
        <div className=" justify-center ">
          <div className="md:flex p-4 ">
        <div className="w-1/3">
        <h2 className=" text-sm text-gray-500 font-semibold">
          Program Type:
          </h2>
        </div>
        <div className='w-2/3'>

        <p className="text-sm text-blue-800 font-semibold">
            {CollegeEnrollment.program_type}
          </p>
        </div>
        </div>

        <div className="md:flex p-4 ">
        <div className="w-1/3">
        <h2 className=" text-sm text-gray-500 font-semibold">
          College Category:
          </h2>
        </div>
        <div className='w-2/3'>

        <p className="text-sm text-blue-800 font-semibold">
            {CollegeEnrollment.college_category}
          </p>
        </div>
        </div>
        <div className="md:flex p-2">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Academic Session
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
              {formatAcademicYear(CollegeEnrollment?.acadYear)}
                {/* {CollegeEnrollment?.acadYear
                  ? CollegeEnrollment?.acadYear
                  : "N/A"} */}
              </p>
            </div>
            
            </div>
          {/* {CollegeEnrollment.degree_courses.map((course) => (
            <div
              key={course.value}
              className="p-3 mt-4 border-2 uppercase font-semibold space-x-4"
            >
              <span className="underline text-xl">{course.label}</span>
              {/* <div className=" font-semibold text-right px-5">
                Total Enrollment in {course.label}:{" "}
                {calculateFacultyTotal(course.value, "1st") +
                  calculateFacultyTotal(course.value, "2nd")}
              </div> */}
              {/* {generateDegreeCoursesInputFields(course)} */}
            {/* </div> */}
          {/* ))}  */}
        </div>

        <div className="border-4 flex justify-between p-4 m-4 font-bold text-xl">
        <div>
          Total Enrollment :
      </div>
      <div>
        {CollegeEnrollment.college_enrollment.overallTotal}
          </div>
        </div>
        
        <div>
        <div className=" justify-between underline p-2 font-bold text-xl">
         Faculty / Gender / Class Wise Enrollment :
        </div>

        {CollegeEnrollment.college_enrollment.facultyData.map((faculty, index) => (
            <div
              key={faculty.index}
              className="p-3 m-4 border-2 uppercase font-semibold space-x-4"
            >
              <span className="underline text-xl">{faculty.faculty}</span>
              
              {/* <div className="font-semibold text-right px-5">
                Total Enrollment in {course.label}:{" "}
                {calculateFacultyTotal(course.value, "xi") +
                  calculateFacultyTotal(course.value, "xii")}
              </div> */}
              {generateDegreeCoursesInputFields(faculty, index)} {/* Display data instead of input fields */}
            </div>
          ))}
        </div>
        </>
      );
    } else if (
      CollegeEnrollment.college_level === "Degree" &&
      CollegeEnrollment.program_type === "4 Years"
    ) {
      return (
        <>
        <div className="md:flex p-4 ">
        <div className="w-1/3">
        <h2 className=" text-sm text-gray-500 font-semibold">
          Program Type:
          </h2>
        </div>
        <div className='w-2/3'>

        <p className="text-sm text-blue-800 font-semibold">
            {CollegeEnrollment.program_type}
          </p>
        </div>
        </div>
        <div className="md:flex p-4 ">
        <div className="w-1/3">
        <h2 className=" text-sm text-gray-500 font-semibold">
          Program Offered:
          </h2>
        </div>
        <div className='w-2/3'>

        <p className="text-sm text-blue-800 font-semibold">
            {CollegeEnrollment.program_offered}
          </p>
        </div>
        </div>
        <div className="md:flex p-2">
          
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                College Category
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {CollegeEnrollment?.college_category
                  ? CollegeEnrollment?.college_category
                  : "N/A"}
              </p>
            </div>
            
            </div>
            <div className="md:flex p-2">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Academic Session
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
              {formatAcademicYear(CollegeEnrollment?.acadYear)}
                {/* {CollegeEnrollment?.acadYear
                  ? CollegeEnrollment?.acadYear
                  : "N/A"} */}
              </p>
            </div>
            
            </div>
            <div className="border-4 flex justify-between p-4 m-4 font-bold text-xl">
        <div>
          Total Enrollment :
      </div>
      <div>
        {CollegeEnrollment.college_enrollment.overallTotal}
          </div>
        </div>

       
          
          {/* <div className="p-4 text-gray-600 text-xl font-semibold flex justify-between items-center">
            Program Offered: */}
          {/* <div className="text-lg uppercase underline text-blue-800 font-semibold ">
            {CollegeEnrollment.program_offered}
          </div> */}
          {/* </div> */}

         
            <div className=" justify-between underline p-2 font-bold text-xl">
         Semester / Gender Wise Enrollment :
        </div>
        
        <div className="p-3 m-4 border-2 uppercase font-semibold space-x-4">
          {generateFourYearsProgramInputFields()}
        </div>
            </>
      );
    }

    return null;
  };

  // const getMyCollegeEnrollment = async () => {
  //   const res = await getCollegeEnrollment();
  //   if (res?.data?.data) {
  //     setCollegeEnrollment(res?.data?.data);
  //   }
  // };

  // useEffect(() => {
  //   getMyCollegeEnrollment();
  // }, []);
  return (
    <div className="md:py-6 p-1">
      <div className="w-full border rounded-md">
        <div className="p-3 text-gray-400 border-b bg-gray-100 flex justify-between items-center">
          <h1 className="text-lg font-semibold">Enrollment Details</h1>
          {/* <div className="text-lg border border-blue-800 rounded-full cursor-pointer w-9 h-9 shadow-md bg-white flex justify-center items-center">
            <HiPencil
              className="text-blue-800 text-2xl"
              // onClick={() => setEdit(true)}
            />
          </div> */}
        </div>
        
        <div className="md:flex p-2">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                College Level
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {CollegeEnrollment?.college_level
                  ? CollegeEnrollment?.college_level
                  : "N/A"}
              </p>
            </div>
          </div>

          
          
  
        {renderProgramOptions()}
          {renderDegreeProgramOptions()}
      </div>
    </div>
  );
};

export default CollegeEnrollment;
