import React, { useState, useEffect } from "react";
// import { getCollegeEnrollment } from "../../../../../services/collegeService";
import AuthUser from "../../../../../api/Api";

import { HiPencil } from "react-icons/hi";

const CollegeEnrollment = ({ setEdit, collegeId }) => {
  const { http } = AuthUser();
  const GET_PASSPERCENTAGE_DETAILS = "passpercentage";
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSemesterDetails, setselectedSemesterDetails] = useState({});
  const [CollegeEnrollment, setCollegeEnrollment] = useState({});
  const [degreeCourses, setDegreeCourses] = useState([]);

  const formatAcademicYear = (year) => {
    if (!year) return "N/A";
    const nextYear = parseInt(year) + 1;
    return `${year}-${nextYear.toString().slice(-2)}`;
  };

  // const handleChange = (e) => {
  //   const { name, value, type, checked } = e.target;

  //   if (name === "college_level") {
  //     setCollegeEnrollment((prevData) => ({
  //       ...prevData,
  //       college_level: value,
  //       intermediate_courses: "",
  //       degree_courses: "",
  //       program_type: "",
  //       program_offered: "",
  //     }));
  //   }
  
  //   if (name === "program_type") {
  //     setCollegeEnrollment((prevData) => ({
  //       ...prevData,
  //       academic_session: "",
  //       college_level: prevData.college_level,
  //       program_type: value,
  //       intermediate_courses: "",
  //       degree_courses: value === "2 Years" ? [] : "", // Set degree_courses to an empty array only when program_type is 2 Years
  //       program_offered: "",
  //     }));
  
  //     if (name === "program_offered") {
  //       setCollegeEnrollment((prevData) => ({
  //         ...prevData,
  //         program_offered: value,
  //       }));
  //     }
  //     setselectedSemesterDetails({});
  //   }


  //   // setselectedSemesterDetails((prevDetails) => ({
  //   //   ...prevDetails,
  //   //   [name]: value,
  //   // }));

  //   if (type === "checkbox") {
  //     const updatedValue = checked
  //       ? [...CollegeEnrollment.intermediate_courses, value]
  //       : CollegeEnrollment.intermediate_courses.filter(
  //           (course) => course !== value
  //         );

  //     setCollegeEnrollment((prevData) => ({
  //       ...prevData,
  //       [name]: updatedValue,
  //     }));
  //   } else {
  //     if (type === "number" && isNaN(Number(value))) {
  //       setCollegeProfileErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [name]: "Please enter a valid number.",
  //       }));
  //     } else {
  //       setCollegeProfileErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [name]: undefined,
  //       }));

  //       if (
  //         name === "college_level" &&
  //         CollegeEnrollment.college_level === "Degree" &&
  //         value === "Intermediate"
  //       ) {
  //         setCollegeEnrollment((prevData) => ({
  //           ...prevData,
  //           program_type: "",
  //           program_offered: "",
  //           intermediate_courses: [],
  //           degree_courses: [],
  //         }));
  //       } else {
  //         const updatedValue = type === "checkbox" ? checked : value;
  //         setCollegeEnrollment((prevData) => ({
  //           ...prevData,
  //           [name]: updatedValue,
  //         }));
  //       }
  //     }
  //   }
  // };



  const fetchData = async () => {
    try {
      const res = await http.get(`${GET_PASSPERCENTAGE_DETAILS}/${collegeId}`);
      setCollegeEnrollment(res?.data?.data[0] || {});
      
      // Extract the semester details from the fetched data and update the state
      // const semesterDetails = extractSemesterDetails(res?.data?.data);
      // setselectedSemesterDetails(semesterDetails);
  
      // console.log('API Response:', res);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data');
    } finally {
      setLoading(false);
    }
  };
  
  // Add a helper function to extract semester details from the fetched data
  // const extractSemesterDetails = (data) => {
  //   // Implement the logic to extract semester details from 'data'
  //   // For example, if semester details are nested under 'semesters', you might use:
  //   // return data?.semesters || {};
  //   // Adjust this based on your actual data structure.
  //   return data?.semester_details || {}; // Return an empty object for now, update according to your data structure.
  // };
  

  useEffect(() => {
    fetchData(collegeId); // Pass collegeId as an argument to fetchData
  }, [collegeId]);

  const generateInputFields = (faculty) => (
    <div>
      <div key={faculty} className="border-4 p-3 mt-4 mb-4">
      {/* <h2 className="text-xl font-semibold mb-2">{`${faculty.faculty} Enrollment Details`}</h2> */}
      <table className="w-full border-collapse border">
        <thead>
          <tr className="border">
            
            <th className="w-1.5/5 text-center border p-2">Appeared</th>
            <th className="w-1.5/5 text-center border p-2">Passed</th>
            <th className="w-2.5/5 text-center border p-2">Pass Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border">
            
            <td className="w-1.5/5 text-center border p-2">{faculty[`${faculty.faculty}_appeared`] || 0}</td>
            <td className="w-1.5/5 text-center border p-2">{faculty[`${faculty.faculty}_passed`] || 0}</td>
            <td className="w-2.5/5 text-center border p-2">{faculty[`${faculty.faculty}_passpercentage`] || 0}</td>
          </tr>
          {/* <tr className="border">
            
            <td className="w-1.5/5 text-center border p-2">{faculty[`${faculty.faculty}_XII_Boys`] || 0}</td>
            <td className="w-1.5/5 text-center border p-2">{faculty[`${faculty.faculty}_XII_Girls`] || 0}</td>
            <td className="w-2.5/5 text-center border p-2">{faculty[`${faculty.faculty}Total_XII`] || 0}</td>
          </tr> */}
        </tbody>
      </table>
    </div>
      
    
    </div>
  );
  
  const renderProgramOptions = () => {
    if (CollegeEnrollment?.college_level === "Intermediate") {
      return (
        <>
        <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                College Category
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {CollegeEnrollment?.college_category
                  ? CollegeEnrollment?.college_category
                  : "N/A"}
              </p>
            </div>
            </div>
            <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Academic Session
              </h2>
            </div>
            <div className="w-2/3">
            <p className="text-sm text-blue-800 font-semibold">
      {formatAcademicYear(CollegeEnrollment?.acadYear)}
    </p>
            </div>
            </div>
            
        <div className="border-4 flex justify-between p-4 m-4 font-bold text-xl">
        <div>
          Pass Percentage
      </div>
      {/* <div>
        {CollegeEnrollment.college_enrollment.overallTotal}
          </div> */}
        </div>
        
        <div>
        <div className=" justify-between underline p-2 font-bold text-xl">
         Faculty Wise :
        </div>

          {CollegeEnrollment.college_passpercentage.passPercentageData.map((faculty, index) => (
            <div
              key={faculty.index}
              className="p-3 m-4 border-2 uppercase font-semibold space-x-4"
            >
              <span className="underline text-xl">{faculty.faculty}</span>
              
              {/* <div className="font-semibold text-right px-5">
                Total Enrollment in {course.label}:{" "}
                {calculateFacultyTotal(course.value, "xi") +
                  calculateFacultyTotal(course.value, "xii")}
              </div> */}
              {generateInputFields(faculty, index)} {/* Display data instead of input fields */}
            </div>
          ))}
        </div>
        
        </>
      );
    } 
    // else if (CollegeEnrollment.college_level === "Degree") {
    //   return (
    //     <div className="p-4 text-gray-600 text-xl font-semibold flex justify-between items-center">
    //       Program Type:
    //       <div className="text-lg uppercase text-blue-800 font-semibold ">
    //         {CollegeEnrollment.program_type}
    //       </div>
    //     </div>
    //   );
    // }
  };
  
  
  const generateDegreeCoursesInputFields = (faculty) => (
   <div key={faculty} className="border-4 p-3 mt-4 mb-4">
      {/* <h2 className="text-xl font-semibold mb-2">{`${faculty.faculty} Enrollment Details`}</h2> */}
      <table className="w-full border-collapse border">
        <thead>
          <tr className="border">
            
            <th className="w-1.5/5 text-center border p-2">Appeared</th>
            <th className="w-1.5/5 text-center border p-2">Passed</th>
            <th className="w-2.5/5 text-center border p-2">Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border">
            
            <td className="w-1.5/5 text-center border p-2">{faculty[`${faculty.faculty}_XI_Boys`] || 0}</td>
            <td className="w-1.5/5 text-center border p-2">{faculty[`${faculty.faculty}_XI_Girls`] || 0}</td>
            <td className="w-2.5/5 text-center border p-2">{faculty[`${faculty.faculty}_Total_XI`] || 0}</td>
          </tr>
          {/* <tr className="border">
            <td className="w-1/5 text-center border p-2">2nd year</td>
            <td className="w-1.5/5 text-center border p-2">{faculty[`${faculty.faculty}_XII_Boys`] || 0}</td>
            <td className="w-1.5/5 text-center border p-2">{faculty[`${faculty.faculty}_XII_Girls`] || 0}</td>
            <td className="w-2.5/5 text-center border p-2">{faculty[`${faculty.faculty}Total_XII`] || 0}</td>
          </tr> */}
        </tbody>
      </table>
    </div>
  );
  
  
  

  const generateFourYearsProgramInputFields = () => {
    return (
      <div>
        <div>
          <div className="text-right px-3 mb-3">
            {/* <span className="font-bold">
                Total: {calculateTotalForFourYearsProgram()}
              </span> */}
          </div>
  
          <div className="border-4 p-3 mt-4 mb-4">
            <table className="w-full border-collapse border">
              <thead>
                <tr className="border">
                  
                  <th className="w-1/5 text-center border p-2">Appeared</th>
                  <th className="w-1/5 text-center border p-2">Passed</th>
                  <th className="w-1/5 text-center border p-2">Percentage</th>
                </tr>
              </thead>
              <tbody>
                {[1].map((semester) => (
                  <tr key={semester} className="border">
                   
                    <td className="w-1/5 text-center border p-2">
                      {CollegeEnrollment?.college_passpercentage.degreePassPercentage[0].appeared || 0}
                    </td>
                    <td className="w-1/5 text-center border p-2">
                      {CollegeEnrollment?.college_passpercentage.degreePassPercentage[0].passed || 0}
                    </td>
                    <td className="w-1/5 text-center border p-2">
                    {CollegeEnrollment?.college_passpercentage.degreePassPercentage[0].passPercentage || 0}
                      {/* You may need to calculate the total here based on your data */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };
  
  
  

  const renderDegreeProgramOptions = () => {
    if (
      CollegeEnrollment.college_level === "Degree" &&
      CollegeEnrollment.program_type === "2 Years"
    ) {
      return (
        <>
        <div className=" justify-center ">
          <div className="md:flex p-4 ">
        <div className="w-1/3">
        <h2 className=" text-sm text-gray-500 font-semibold">
          Program Type:
          </h2>
        </div>
        <div className='w-2/3'>

        <p className="text-sm text-blue-800 font-semibold">
            {CollegeEnrollment.program_type}
          </p>
        </div>
        </div>

        <div className="md:flex p-4 ">
        <div className="w-1/3">
        <h2 className=" text-sm text-gray-500 font-semibold">
          College Category:
          </h2>
        </div>
        <div className='w-2/3'>

        <p className="text-sm text-blue-800 font-semibold">
            {CollegeEnrollment.college_category}
          </p>
        </div>
        </div>
       
          {/* {CollegeEnrollment.degree_courses.map((course) => (
            <div
              key={course.value}
              className="p-3 mt-4 border-2 uppercase font-semibold space-x-4"
            >
              <span className="underline text-xl">{course.label}</span>
              {/* <div className=" font-semibold text-right px-5">
                Total Enrollment in {course.label}:{" "}
                {calculateFacultyTotal(course.value, "1st") +
                  calculateFacultyTotal(course.value, "2nd")}
              </div> */}
              {/* {generateDegreeCoursesInputFields(course)} */}
            {/* </div> */}
          {/* ))}  */}
        </div>

        {/* <div className="border-4 flex justify-between p-4 m-4 font-bold text-xl">
        <div>
          Pass percentage
      </div>
      <div>
        {CollegeEnrollment.college_enrollment.overallTotal}
          </div>
        </div> */}
        
        <div>
        <div className=" justify-between underline p-2 font-bold text-xl">
         Faculty / Gender / Class Wise Enrollment :
        </div>

        {CollegeEnrollment.college_enrollment.facultyData.map((faculty, index) => (
            <div
              key={faculty.index}
              className="p-3 m-4 border-2 uppercase font-semibold space-x-4"
            >
              <span className="underline text-xl">{faculty.faculty}</span>
              
              {/* <div className="font-semibold text-right px-5">
                Total Enrollment in {course.label}:{" "}
                {calculateFacultyTotal(course.value, "xi") +
                  calculateFacultyTotal(course.value, "xii")}
              </div> */}
              {generateDegreeCoursesInputFields(faculty, index)} {/* Display data instead of input fields */}
            </div>
          ))}
        </div>
        </>
      );
    } else if (
      CollegeEnrollment.college_level === "Degree" &&
      CollegeEnrollment.program_type === "4 Years"
    ) {
      return (
        <>
        <div className="md:flex p-4 ">
        <div className="w-1/3">
        <h2 className=" text-sm text-gray-500 font-semibold">
          Program Type:
          </h2>
        </div>
        <div className='w-2/3'>

        <p className="text-sm text-blue-800 font-semibold">
            {CollegeEnrollment.program_type}
          </p>
        </div>
        </div>
        <div className="md:flex p-4 ">
        <div className="w-1/3">
        <h2 className=" text-sm text-gray-500 font-semibold">
          Program Offered:
          </h2>
        </div>
        <div className='w-2/3'>

        <p className="text-sm text-blue-800 font-semibold">
            {CollegeEnrollment.program_offered}
          </p>
        </div>
        </div>
        <div className="md:flex p-4">
          
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                College Category
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {CollegeEnrollment?.college_category
                  ? CollegeEnrollment?.college_category
                  : "N/A"}
              </p>
            </div>
            </div>
            <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Result for Academic Year:
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {CollegeEnrollment?.acadYear
                  ? CollegeEnrollment?.acadYear
                  : "N/A"}
              </p>
            </div>
            
            </div>
            {/* <div className="border-4 flex justify-between p-4 m-4 font-bold text-xl">
        <div>
          Total Enrollment :
      </div>
      <div>
        {CollegeEnrollment.college_enrollment?.overallTotal}
          </div>
        </div> */}

       
          
          {/* <div className="p-4 text-gray-600 text-xl font-semibold flex justify-between items-center">
            Program Offered: */}
          {/* <div className="text-lg uppercase underline text-blue-800 font-semibold ">
            {CollegeEnrollment.program_offered}
          </div> */}
          {/* </div> */}

         
            <div className=" justify-between underline p-2 font-bold text-xl">
         Semester / Gender Wise Enrollment :
        </div>
        
        <div className="p-3 m-4 border-2 uppercase font-semibold space-x-4">
          {generateFourYearsProgramInputFields()}
        </div>
            </>
      );
    }

    return null;
  };

  // const getMyCollegeEnrollment = async () => {
  //   const res = await getCollegeEnrollment();
  //   if (res?.data?.data) {
  //     setCollegeEnrollment(res?.data?.data);
  //   }
  // };

  // useEffect(() => {
  //   getMyCollegeEnrollment();
  // }, []);
  return (
    <div className="p-6">
      <div className="w-full border rounded-md">
        <div className="p-4 text-gray-400 border-b bg-gray-100 flex justify-between items-center">
          <h1 className="text-lg font-semibold">Current Year Result</h1>
          <div className="text-lg border border-blue-800 rounded-full cursor-pointer w-9 h-9 shadow-md bg-white flex justify-center items-center">
            <HiPencil
              className="text-blue-800 text-2xl"
              onClick={() => setEdit(true)}
            />
          </div>
        </div>
        
        <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                College Level
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {CollegeEnrollment?.college_level
                  ? CollegeEnrollment?.college_level
                  : "N/A"}
              </p>
            </div>
          </div>

          
          
  
        {renderProgramOptions()}
          {renderDegreeProgramOptions()}
      </div>
    </div>
  );
};

export default CollegeEnrollment;
