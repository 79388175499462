import React, { useEffect, useState } from 'react';
import AuthUser from "../../../../../api/Api";
import { HiPencil } from "react-icons/hi";
// import Ownership from '../OwnerShip/Owner-Information'
const CollegeGeneralInfo = ( { setEdit ,  collegeId }) => {
  const { http } = AuthUser();
  const POST_COLLEGES_GENERAL = "colleges-general";
  const [collegeGeneralInfo, setCollegeGeneralInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
// console.log("Set Data",collegeGeneralInfo)
  const fetchData = async () => {
    try {
      const res = await http.get(`${POST_COLLEGES_GENERAL}/${collegeId}`);
      // console.log('API Response:', res);
      if (res?.data?.data) {
        let location = res.data?.data[0]["college_location"];
        // console.log("Location", res.data.data)
        if (location === "1") {
            location = "Urban";
        } else if (location === "2") {
            location = "Rural";
        } else {
            location = null;
        }
        res.data.data["college_location"] = location;
        setCollegeGeneralInfo(res.data.data);
    }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(collegeId); // Pass collegeId as an argument to fetchData
  }, [collegeId]);

  return (
    <div className="p-6">
      <div className="w-full border rounded-md">
        <div className="p-4 text-gray-400 border-b bg-gray-100 flex justify-between items-center">
          <h1 className="text-lg font-semibold">General Information</h1>
          <div className="text-lg border border-blue-800 rounded-full cursor-pointer w-9 h-9 shadow-md bg-white flex justify-center items-center">
            <HiPencil
              className="text-blue-800 text-2xl"
              onClick={() => setEdit(true)}
            />
          </div>
        </div>
        <div className=' border-4 m-4 p-4'>

        
        <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Region
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeGeneralInfo[0]?.college_region
                  ? collegeGeneralInfo[0]?.college_region
                  : "N/A"}
              </p>
            </div>
          </div>

          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                District
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeGeneralInfo[0]?.college_district
                  ? collegeGeneralInfo[0]?.college_district
                  : "N/A"}
              </p>
            </div>
          </div>

          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Subdivision
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeGeneralInfo[0]?.college_town
                  ? collegeGeneralInfo[0]?.college_town
                  : "N/A"}
              </p>
            </div>
          </div>

          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Rural / Urban
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeGeneralInfo?.college_location
                  ? collegeGeneralInfo?.college_location
                  : "N/A"}
              </p>
            </div>
          </div>
          
          </div>




          <div className=' border-4 m-4 p-4'>
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                College Name
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeGeneralInfo[0]?.college_name
                  ? collegeGeneralInfo[0]?.college_name
                  : "N/A"}
              </p>
            </div>
          </div>
          
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Address
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeGeneralInfo[0]?.college_address
                  ? collegeGeneralInfo[0]?.college_address
                  : "N/A"}
              </p>
            </div>

            
            
          </div>
          
</div>


<div className=' border-4 m-4 p-4'>
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Year of Establishment
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeGeneralInfo[0]?.college_year_of_establishment
                  ? collegeGeneralInfo[0]?.college_year_of_establishment
                  : "N/A"}
              </p>
            </div>
          </div>

          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                College Shift
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeGeneralInfo[0]?.college_shift
                  ? collegeGeneralInfo[0]?.college_shift
                  : "N/A"}
              </p>
            </div>
          </div>

          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Category
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeGeneralInfo[0]?.college_category
                  ? collegeGeneralInfo[0]?.college_category
                  : "N/A"}
              </p>
            </div>
          </div>
          </div>
          <div className=' border-4 m-4 p-4'>
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                College Level
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeGeneralInfo[0]?.college_level
                  ? collegeGeneralInfo[0]?.college_level
                  : "N/A"}
              </p>
            </div>
          </div>
          
  {collegeGeneralInfo[0]?.college_level === "Intermediate" ? (
    
    <div className="md:flex p-4">
            <div className="w-1/3">
    <h2 className=" flex text-sm text-gray-500 font-semibold">
      Faculty Offered:
      </h2>
      </div>
      <div className="w-2/3">
      <p className="text-sm text-blue-800 font-semibold">
      {collegeGeneralInfo[0]?.college_level === "Intermediate" && collegeGeneralInfo[0]?.college_intermediate_courses
  ? collegeGeneralInfo[0].college_intermediate_courses.join(', ')
          : 'N/A'}
      </p>
    
    </div>
    </div>
  ) : collegeGeneralInfo[0]?.college_level === "Degree" ? (
    <>
      {collegeGeneralInfo[0]?.college_program_type && (
        <div className="md:flex p-4 ">
        <div className="w-1/3">
        <h2 className=" text-sm text-gray-500 font-semibold">
          Program Type:
          </h2>
        </div>
        <div className='w-2/3'>

        <p className="text-sm text-blue-800 font-semibold">
            {collegeGeneralInfo[0]?.college_program_type}
          </p>
        </div>
        </div>
      )}
      {collegeGeneralInfo[0]?.college_program_type === "2 Years" ? (
        <div className="md:flex p-4">
        <div className="w-1/3">
        <h2 className=" md:flex text-sm text-gray-500 font-semibold">
          Faculty Offered: </h2>
          </div>
          <div className="w-2/3">
          <p className=" text-sm text-blue-800 font-semibold">
          {collegeGeneralInfo[0]?.college_degree_courses && collegeGeneralInfo[0].college_degree_courses.length > 0
  ? collegeGeneralInfo[0].college_degree_courses.join(', ')
  : 'N/A'}

          </p>
              </div>
        
              </div>
      ) : collegeGeneralInfo[0]?.college_program_type === "4 Years" ? (
        <div className="md:flex p-4">
        <div className="w-1/3">
        <h2 className=" md:flex text-sm text-gray-500 font-semibold">
          Program Offered:
            </h2>
            </div>
            <div className='w-2/3'>

          <p className=" text-sm text-blue-800 font-semibold">
            {collegeGeneralInfo[0]?.college_program_offered
              ? collegeGeneralInfo[0].college_program_offered
              : 'N/A'}
          </p>
              </div>
          </div>
      ) : (
        <p className="text-sm text-blue-800 font-semibold">N/A</p>
      )}
    </>
  ) : (
    <p className="text-sm text-blue-800 font-semibold"></p>
  )}

   </div>       
          
          
          {/* <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Functional
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeGeneralInfo?.functional
                  ? collegeGeneralInfo?.functional == 1
                    ? "Yes"
                    : collegeGeneralInfo?.functional == 2
                    ? "No"
                    : "N/A"
                  : "N/A"}
              </p>
            </div>
          </div> */}
          {/* <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">UC Name</h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeGeneralInfo?.uc_name
                  ? collegeGeneralInfo?.uc_name
                  : "N/A"}
              </p>
            </div>
          </div> */}
          <div className=' border-4 m-4 p-4'>
          
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                College Phone No
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeGeneralInfo[0]?.college_phone_no
                  ? collegeGeneralInfo[0]?.college_phone_no
                  : "N/A"}
              </p>
            </div>
          </div>

          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                College Email
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeGeneralInfo[0]?.college_email
                  ? collegeGeneralInfo[0]?.college_email
                  : "N/A"}
              </p>
            </div>
          </div>
          
          {/* <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">Latitude</h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeGeneralInfo?.latitude
                  ? collegeGeneralInfo?.latitude
                  : "N/A"}
              </p>
            </div>
          </div>
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">Longitude</h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeGeneralInfo?.longitude
                  ? collegeGeneralInfo?.longitude
                  : "N/A"}
              </p>
            </div>
          </div> */}
          {/* <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Bus Routes Info
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeGeneralInfo?.bus_routes_info
                  ? collegeGeneralInfo?.bus_routes_info
                  : "N/A"}
              </p>
            </div>
          </div> */}
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                College Principal
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeGeneralInfo[0]?.user.principalName
                  ? collegeGeneralInfo[0]?.user.principalName
                  : "N/A"}
              </p>
            </div>
          </div>
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                CNIC Principal
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeGeneralInfo[0]?.user.principalCnic
                  ? collegeGeneralInfo[0]?.user.principalCnic
                  : "N/A"}
              </p>
            </div>
          </div>
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Principal Contact No.
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeGeneralInfo[0]?.user.principalMobile
                  ? collegeGeneralInfo[0]?.user.principalMobile
                  : "N/A"}
              </p>
            </div>
          </div>
          </div>
          
          {/* <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Principal Notified or Charged
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {parseInt(
                  collegeGeneralInfo?.college_principal_notified_or_charged
                ) === 1
                  ? "NOTIFIED"
                  : parseInt(
                      collegeGeneralInfo?.college_principal_notified_or_charged
                    ) === 2
                  ? "CHARGED"
                  : "N/A"}
              </p>
            </div>
          </div> */}
          {/* <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                College DDO
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeGeneralInfo?.college_ddo
                  ? collegeGeneralInfo?.college_ddo
                  : "N/A"}
              </p>
            </div>
          </div> */}
          {/* <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                IT Focal Person
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeGeneralInfo?.college_it_focal_person
                  ? collegeGeneralInfo?.college_it_focal_person
                  : "N/A"}
              </p>
            </div>
          </div> */}
        </div>
      </div>
    
  );
};

export default CollegeGeneralInfo;
