import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx'; // Import XLSX library for Excel export
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Container, Typography } from '@mui/material';

const columns = [
  { field: 'id', headerName: 'Regd. ID', flex: 1 },
  { field: 'Region', headerName: 'Region', flex: 2 },
  { field: 'name', headerName: 'College Name', flex: 2 },
  { field: 'level', headerName: 'Level of Study', flex: 2 },
  { field: 'Managed', headerName: 'Managed By', flex: 2 },
  { field: 'Principal', headerName: 'Principal Name', flex: 2 },
  { field: 'Contact', headerName: 'Contact No.', flex: 2 },
  { field: 'Registration_type', headerName: 'Registration Type.', flex: 2 },
  { field: 'Registration_attempt', headerName: 'Registration Attempt.', flex: 2 },
  { field: 'Applied_Online', headerName: 'Applied Online', flex: 2 },
  { field: 'Submitted_to_Directorate', headerName: 'Submitted to Directorate', flex: 2 },
  { field: 'Inspected_on', headerName: 'Inspected On', flex: 2 },
  { field: 'Observation_Querries', headerName: 'Observation / Querries', flex: 2 },
  { field: 'Replies_Submitted', headerName: 'Replies Submitted', flex: 2 },
  { field: 'Generate_Outward_Letter', headerName: 'Generate Outward Letter', flex: 2 },
  { field: 'Submitted_to_Secretariate_on', headerName: 'Submitted to Secretariate On', flex: 2 },
  { field: 'Approval', headerName: 'Approved', flex: 2 },
  // Add more columns as needed
];

function createData(id, Region, name, level, Expiring, Applied_Online, Submitted_to_Directorate, Inspected_on, Observation_Querries, Replies_Submitted, Generate_Outward_Letter, Submitted_to_Secretariate_on) {
  const today = new Date();
  const expirationDate = new Date(Expiring);
  const daysRemaining = Math.floor((expirationDate - today) / (1000 * 60 * 60 * 24));
  let status = 'Active';
  let backgroundColor = '';

  // if (daysRemaining < 0) {
  //   status = 'Expired';
  //   backgroundColor = '#FF5252'; // Red for expired
  // } else if (daysRemaining <= 15) {
  //   status = 'Expiring Soon';
  //   backgroundColor = '#FF8833'; // Yellow for expiring soon
  // }

  return {
    id,
    Region,
    name,
    level,
    Expiring,
    Status: status,
    RowColor: backgroundColor,
    Applied_Online,
    Submitted_to_Directorate,
    Inspected_on,
    Observation_Querries,
    Replies_Submitted,
    Generate_Outward_Letter,
    Submitted_to_Secretariate_on,
  };
}

const rows = [
  createData('01010001', 'KARACHI', 'ADLER COLLEGE', 'INTERMEDIATE', '2023-09-20', '2023-09-01', '2023-09-05', '2023-09-10', '2023-09-12', '2023-09-15', '2023-09-18', '2023-09-20'),
  createData('02010002', 'HYDERABAD', 'HIAST COLLEGE', 'DEGREE', '2023-10-15', '2023-09-02', '2023-09-06', '2023-09-11', '2023-09-13', '2023-09-16', '2023-09-19', '2023-09-22'),
  createData('03010003', 'SUKKUR', 'BAHRIA COLLEGE KHAIRPUR', 'INTERMEDIATE', '2023-09-27', '2023-09-03', '2023-09-07', '2023-09-12', '2023-09-14', '2023-09-17', '2023-09-20', '2023-09-23'),
  // Add more data rows as needed
];

export default function CollegeDetailsTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [filteredRows, setFilteredRows] = useState(rows);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = () => {
    const filteredData = rows.filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === 'string' &&
          value.toLowerCase().includes(searchText.toLowerCase())
      )
    );
    setFilteredRows(filteredData);
  };

  const handleExport = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredRows);
    XLSX.utils.book_append_sheet(wb, ws, 'CollegeData');
    XLSX.writeFile(wb, 'CollegeData.xlsx');
  };

  return (
    <>
      <Typography variant="h4">Status of File for Renewal / Registration</Typography>
      <Paper elevation={5} >
        <Container style={{ paddingTop: '10px', marginTop: '20px', Width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '8%', marginTop: '10px', marginBottom: '10px', padding: '2px' }}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />

            <Button variant="contained" onClick={handleSearch} style={{ marginLeft: '8px' }}>
              Search
            </Button>
            <Button variant="contained" onClick={handleExport} style={{ marginLeft: '8px' }}>
              Download
            </Button>
          </div>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <TableContainer component={Paper} elevation={3} style={{ height: 470, Width: '85%', marginBottom: '16px' }}>
              <Table Header aria-label="table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.field}
                        align="center"
                        style={{ flex: column.flex }}
                      >
                        {column.headerName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const rowStyle = {
                        backgroundColor: row.RowColor,
                      };

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          style={rowStyle}
                        >
                          {columns.map((column) => {
                            const value = row[column.field];
                            return (
                              <TableCell key={column.field} align="center">
                                {column.field === 'Inspected_on' && value ? (
                                  <Button variant="outlined" color="primary">
                                    {value}
                                  </Button>
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100, { value: filteredRows.length, label: 'All' }]}
              component="div"
              count={filteredRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Container>
      </Paper>
    </>
  );
}
