import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CustomInputBox from "../../common/CustomInputBox/CustomInputBox";
import AuthUser from "../../../../../api/Api";

const FileUpload = ({ collegeId, https }) => {
  const { http } = AuthUser();
  const POST_FRESH_REG = "fresh-registration";
  const GET_FRESH_REG = "fresh-registration";
  const [file, setFile] = useState(null);
  const [freshRegistrationNo, setFreshRegistrationNo] = useState("");
  const [filePreview, setFilePreview] = useState(null);
  const [issuanceDate, setIssuanceDate] = useState("");
  const [validRegistrationPeriod, setValidRegistrationPeriod] = useState("");
  const [validityDateWef, setValidityDateWef] = useState("");
  const [validityDateEnds, setValidityDateEnds] = useState("");
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showInputFields, setShowInputFields] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await http.get(`${GET_FRESH_REG}/${collegeId}`);
      setFiles(response.data.data);
      setLoading(false);
      if (response.data.data.length === 0) {
        setShowInputFields(true);
      }
    } catch (error) {
      console.error("Error fetching files:", error);
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
  
    // Check file type
    const allowedTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];
    if (selectedFile && !allowedTypes.includes(selectedFile.type)) {
      setMessage('Invalid file type. Please select a JPG, JPEG, PNG, or SVG file.');
      setFile(null);
      setFilePreview(null);
      return;
    }
  
    setFile(selectedFile);
    const reader = new FileReader();
    reader.onload = (event) => {
      setFilePreview(event.target.result);
    };
    reader.readAsDataURL(selectedFile);
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file || !freshRegistrationNo || !issuanceDate || !validRegistrationPeriod || !validityDateWef || !validityDateEnds) {
      setMessage("Please fill out all fields.");
      return;
    }
    setButtonDisabled(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fresh_registration_no", freshRegistrationNo);
    formData.append("issuance_date", issuanceDate);
    formData.append("valid_registration_period", validRegistrationPeriod);
    formData.append("validity_date_wef", validityDateWef);
    formData.append("validity_date_ends", validityDateEnds);
    try {
      let response;
      if (editIndex !== null) {
        response = await http.put(POST_FRESH_REG.id, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } else {
        response = await http.post(POST_FRESH_REG, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }
      if (response.status === 200) {
        setMessage(response.data.message);
        setFile(null);
        setFreshRegistrationNo("");
        setIssuanceDate("");
        setValidRegistrationPeriod("");
        setValidityDateWef("");
        setValidityDateEnds("");
        setEditIndex(null);
        setFilePreview(null);
        fetchData();
      } else {
        setMessage("An error occurred while uploading the file.");
      }
    } catch (error) {
      setMessage("An error occurred while uploading the file.");
      console.error(error);
    } finally {
      setButtonDisabled(false);
      setLoading(false);
    }
  };

  const handleEdit = (index) => {
    const selectedFile = files[index];
    setFile(null);
    setFreshRegistrationNo(selectedFile.fresh_registration_no);
    setIssuanceDate(selectedFile.issuance_date);
    setValidRegistrationPeriod(selectedFile.valid_registration_period);
    setValidityDateWef(selectedFile.validity_date_wef);
    setValidityDateEnds(selectedFile.validity_date_ends);
    setEditIndex(index);
  };

  const handleDelete = async (id) => {
    try {
      const response = await http.delete(`${POST_FRESH_REG}/${id}`);
      if (response.status === 200) {
        setMessage(response.data.message);
        fetchData();
      } else {
        setMessage("An error occurred while deleting the file.");
      }
    } catch (error) {
      setMessage("An error occurred while deleting the file.");
      console.error(error);
    }
  };

  const handleView = (filePath) => {
    window.open(`${https}${filePath}`);
  };

  return (
    <div className="md:p-6 mt-3">
      <div className="w-full border rounded-md">
        <div className="px-4 mt-4 underline text-gray-600">
          <h1 className="text-lg font-semibold">Fresh (First Time) Registration Details</h1>
        </div>
        <div className="px-4">
          {loading ? (
            <CircularProgress />
          ) : files.length > 0 ? (
            <table className="min-w-full divide-y mt-10 border divide-gray-200">
              {/* Table headers */}
              <thead className="bg-blue-100 ">
                <tr>
                  <th scope="col" className=" text-center px-6 py-3 text-sm font-medium text-gray-800 uppercase tracking-wider">Registration Certificate No.</th>
                  <th scope="col" className="px-6                   py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider">Date of Issuance</th>
                  <th scope="col" className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider">Registration Valid Period</th>
                  <th scope="col" className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider">Validity Date W.E.F</th>
                  <th scope="col" className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider">Validity Date Ends On</th>
                  <th scope="col" className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider">Action</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {files.map((item, index) => (
                  <tr key={item.id}>
                    <td className="px-6 py-4 whitespace-nowrap">{item.fresh_registration_no}</td>
                    <td className="px-6 py-4 text-center whitespace-nowrap">{item.issuance_date}</td>
                    <td className="px-6 py-4 text-center whitespace-nowrap">{item.valid_registration_period}</td>
                    <td className="px-6 py-4 text-center whitespace-nowrap">{item.validity_date_wef}</td>
                    <td className="px-6 py-4 text-center whitespace-nowrap">{item.validity_date_ends}</td>
                    <td className="px-6 py-4 text-center whitespace-nowrap">
                      {item.file_path && (
                        <button
                          onClick={() => handleView(item.file_path)}
                          className="px-3 py-1 bg-blue-500 text-white rounded mr-2 hover:bg-blue-600"
                        >
                          View
                        </button>
                      )}
                      <button
                        onClick={() => handleDelete(item.id)}
                        className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="mt-4">No records found.</p>
          )}

          {/* Conditionally rendering input fields */}
          {showInputFields && (
            <form onSubmit={handleSubmit}>
              <div className="flex space-x-36">
                <CustomInputBox
                  label="Fresh Registration Certificate No."
                  name="freshRegistrationNo"
                  type="text"
                  value={freshRegistrationNo}
                  onChange={(e) => setFreshRegistrationNo(e.target.value)}
                />
                <CustomInputBox
                  label="Date of Issuance"
                  name="issuanceDate"
                  type="date"
                  value={issuanceDate}
                  onChange={(e) => setIssuanceDate(e.target.value)}
                />
              </div>
              <div className="flex space-x-36">
                <CustomInputBox
                  label="Validity Date W.E.F"
                  name="validityDateWef"
                  type="date"
                  value={validityDateWef}
                  onChange={(e) => setValidityDateWef(e.target.value)}
                />
                <CustomInputBox
                  label="Validity Date Ends On"
                  name="validityDateEnds"
                  type="date"
                  value={validityDateEnds}
                  onChange={(e) => setValidityDateEnds(e.target.value)}
                />
              </div>
              <div className="flex space-x-36">
                <CustomInputBox
                  label="Registration Valid Period"
                  placeholder="Enter number of years"
                  name="validRegistrationPeriod"
                  value={validRegistrationPeriod}
                  onChange={(e) => {
                    const input = e.target.value;
                    const regex = /^[0-9]*$/;
                    if (regex.test(input) && input.length <= 2) {
                      setValidRegistrationPeriod(input);
                    }
                  }}
                />
                <div>
                  <label htmlFor="file" className="font-bold text-gray-700">Choose File:</label>
                  <input
  type="file"
  id="file"
  accept=".jpg, .jpeg, .png, .svg" // Specify allowed file types
  onChange={handleFileChange}
  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
/>
                </div>
                {/* File preview */}
                {filePreview && (
                  <div className="mb-4">
                    <img src={filePreview} alt="File Preview" className="max-w-xs h-auto" />
                  </div>
                )}
              </div>
              <button
                type="submit"
                className="py-2 px-4 bg-indigo-600 text-white rounded hover:bg-indigo-700"
                disabled={buttonDisabled}
              >
                {buttonDisabled ? (
                  <div className="flex items-center">
                    <CircularProgress size={20} color="inherit" className="mr-2" />
                    Please wait...
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
              {message && <p className="mt-4 text-green-500">{message}</p>}
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileUpload;