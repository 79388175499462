import React from "react";
import { BsFacebook } from "react-icons/bs";
import { RiTwitterXFill } from "react-icons/ri";
import { BsInstagram } from "react-icons/bs";
import { Typography, Paper } from "@mui/material";

const Footer = () => {
  return (
    <div className=" bg-sky-700 text-white">
      <div className="flex flex-col md:flex-row justify-between p-8 md:px-32 px-5">
        <div className="w-full md:w-1/4 mb-5 md:mb-0">
          <Typography variant="h5" className="font-semibold">
            D.I.R.P.C
          </Typography>
          <Typography variant="body2" className="text-sm mt-2">
            Explore the latest information and services we provide to our community.
          </Typography>
        </div>
        <div className="w-full md:w-1/4">
          <Typography variant="h6" className="font-medium mb-2">
            Useful Links
          </Typography>
          <nav className="flex flex-col md:flex-row gap-2">
            <a href="/" className="hover:text-brightColor transition-all cursor-pointer">
              Contact Us
            </a>
            <a href="/" className="hover:text-brightColor transition-all cursor-pointer">
              About Us
            </a>
            <a href="/" className="hover:text-brightColor transition-all cursor-pointer">
              Visionary Leaders
            </a>
          </nav>
        </div>
        <div className="w-full md:w-1/4 mt-2">
          <Typography variant="h6" className="font-medium mb-2">
            Connect with Us
          </Typography>
          <nav className="flex justify-center gap-8 mt-6">
            <BsFacebook size={25} className="hover:text-brightColor transition-all cursor-pointer" />
            <RiTwitterXFill size={25} className="hover:text-brightColor transition-all cursor-pointer" />
            <BsInstagram size={25} className="hover:text-brightColor transition-all cursor-pointer" />
          </nav>
        </div>
      </div>
      <div className="mb-12">
        <Typography variant="body2" className="text-center py-4 underline underline-offset-4"  >
           Developed By : <span className="text-brightColor uppercase">Rana Rashid</span> | All Rights Reserved By D.I.R.P.C &copy;
        </Typography>
      </div>
    </div>
  );
};

export default Footer;
