// import * as React from "react";
// import { styled, useTheme } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import MuiDrawer from "@mui/material/Drawer";
// import MuiAppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
// import List from "@mui/material/List";
// import CssBaseline from "@mui/material/CssBaseline";
// import Typography from "@mui/material/Typography";
// import Divider from "@mui/material/Divider";
// import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
// import InboxIcon from "@mui/icons-material/MoveToInbox";
// import MailIcon from "@mui/icons-material/Mail";
// import LogoutIcon from '@mui/icons-material/Logout';
// import SpaceDashboardRoundedIcon from "@mui/icons-material/SpaceDashboardRounded";
// import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
// import { ContentCopyRounded } from "@mui/icons-material";
// import AuthUser from "../../api/Api";
// import {
//   BrowserRouter as Router,
//   Link,
//   Route,
//   Routes,
//   Outlet,
// } from "react-router-dom";



// import DefaultComponent from "./Pages/Welcome";
// import CollegeComponent from "./Pages/Colleges";
// import Dashboard from "./Pages/Dashboard";
// // import DefaultComponent from "./Pages/Welcome";
// // import Stafflist from "./Pages/";
// import Process from "./Pages/Process";
// import Schedule from "./Pages/InsSchedule";
// import Report from "./Pages/InsReport";
// import Queries from "./Pages/Queries";
// import Registration from "./Pages/Registration";
// import Location from "./Pages/Location";
// import Logout from '../../../components/logout/Logout'; // Make sure to adjust the path if necessary


// const drawerWidth = 240;

// const openedMixin = (theme) => ({
//   width: drawerWidth,
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.enteringScreen,
//   }),
//   overflowX: "hidden",
// });

// const closedMixin = (theme) => ({
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   overflowX: "hidden",
//   width: `calc(${theme.spacing(7)} + 1px)`,
//   [theme.breakpoints.up("sm")]: {
//     width: `calc(${theme.spacing(8)} + 1px)`,
//   },
// });

// const DrawerHeader = styled("div")(({ theme }) => ({
//   display: "flex",
//   alignItems: "left",
//   justifyContent: "flex-end",
//   padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar,
// }));

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   zIndex: theme.zIndex.drawer + 1,
//   transition: theme.transitions.create(["width", "margin"], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(["width", "margin"], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

// const Drawer = styled(MuiDrawer, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   width: drawerWidth,
//   flexShrink: 0,
//   whiteSpace: "nowrap",
//   boxSizing: "border-box",
//   ...(open && {
//     ...openedMixin(theme),
//     "& .MuiDrawer-paper": openedMixin(theme),
//   }),
//   ...(!open && {
//     ...closedMixin(theme),
//     "& .MuiDrawer-paper": closedMixin(theme),
//   }),
// }));

// export default function MiniDrawer() {
//   const theme = useTheme();
//   const [logoutDialogOpen, setLogoutDialogOpen] = React.useState(false);
//   const handleOpenLogoutDialog = () => {
//     setLogoutDialogOpen(true);
//   };
  
//   const handleCloseLogoutDialog = () => {
//     setLogoutDialogOpen(false);
//   };
  
//   const {token, logout} = AuthUser()
//   const handleLogout = () => {
//     if(token != undefined) {
//       logout();
//     }
   
//     // Your logout logic here
//   };


//   const [open, setOpen] = React.useState(true);

//   const handleDrawerOpen = () => {
//     setOpen(true);
//   };

//   const handleDrawerClose = () => {
//     setOpen(false);
//   };

//   React.useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth < theme.breakpoints.values.sm) {
//         setOpen(false);
//       } else if (window.innerWidth >= theme.breakpoints.values.sm) {
//         setOpen(true);
//       }
//     };

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, [theme.breakpoints.values.sm]);

  
//   return (
//     <Box sx={{ display: "flex" }}>
//       <CssBaseline />

//       <AppBar
//         position="fixed"
//         open={open}
//         className="justify-center items-start px-0.5 h-16"
//       >
//         <Toolbar>
//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             onClick={handleDrawerOpen}
//             edge="start"
//             sx={{
//               marginRight: 5,
//               display: { sm: "none" },
//             }}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h5" noWrap component="div" sx={{ flexGrow: 1 }}>
//              Directorate of Inspsection and Registration of Private Colleges,
//              Sindh @ Karachi
//            </Typography>
//         </Toolbar>
//       </AppBar>
//       <Drawer variant="permanent" open={open}>
//         <DrawerHeader>
//           <IconButton onClick={handleDrawerClose}>
//             {theme.direction === "rtl" ? (
//               <ChevronRightIcon />
//             ) : (
//               <ChevronLeftIcon />
//             )}
//           </IconButton>
//         </DrawerHeader>
//         <Divider />
       

//         <List >
//            {/* dashboard */}
//            <ListItem key="Dashboard" disablePadding sx={{ display: "block" }}>
//              <Link
//               to="dashboard"
//               style={{ textDecoration: "none", color: "inherit" }}
//             >
//               <ListItemButton
//                 sx={{
//                   minHeight: 48,
//                   justifyContent: open ? "initial" : "center",
//                   px: 2.5,
//                 }}
//               >
//                 <ListItemIcon
//                   sx={{
//                     minWidth: 0,
//                     mr: open ? 3 : "auto",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <SpaceDashboardRoundedIcon />
//                 </ListItemIcon>
//                 <ListItemText
//                   primary="Dashboard"
//                   sx={{ opacity: open ? 1 : 0 }}
//                 />
//               </ListItemButton>
//             </Link>
//           </ListItem>

//           {/* Colleges */}
//           <ListItem key="College" disablePadding sx={{ display: "block" }}>
//             <Link
//               to="college"
//               style={{ textDecoration: "none", color: "inherit" }}
//             >
//               <ListItemButton
//                 sx={{
//                   minHeight: 48,
//                   justifyContent: open ? "initial" : "center",
//                   px: 2.5,
//                 }}
//               >
//                 <ListItemIcon
//                   sx={{
//                     minWidth: 0,
//                     mr: open ? 3 : "auto",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <SchoolRoundedIcon />
//                 </ListItemIcon>
//                 <ListItemText
//                   primary="Colleges"
//                   sx={{ opacity: open ? 1 : 0 }}
//                 />
//               </ListItemButton>
//             </Link>
//           </ListItem>
// <Divider />
//  {/* Staff List */}
//  <ListItem key="Staff" disablePadding sx={{ display: "block" }}>
//             <Link
//               to="dashboard"
//               style={{ textDecoration: "none", color: "inherit" }}
//             >
//               <ListItemButton
//                 sx={{
//                   minHeight: 48,
//                   justifyContent: open ? "initial" : "center",
//                   px: 2.5,
//                 }}
//               >
//                 <ListItemIcon
//                   sx={{
//                     minWidth: 0,
//                     mr: open ? 3 : "auto",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <SpaceDashboardRoundedIcon />
//                 </ListItemIcon>
//                 <ListItemText
//                   primary="Staff List"
//                   sx={{ opacity: open ? 1 : 0 }}
//                 />
//               </ListItemButton>
//             </Link>
//           </ListItem>
          
//           <ListItem key="StudentList" disablePadding sx={{ display: "block" }}>
//             <Link
//               to="dashboard"
//               style={{ textDecoration: "none", color: "inherit" }}
//             >
//               <ListItemButton
//                 sx={{
//                   minHeight: 48,
//                   justifyContent: open ? "initial" : "center",
//                   px: 2.5,
//                 }}
//               >
//                 <ListItemIcon
//                   sx={{
//                     minWidth: 0,
//                     mr: open ? 3 : "auto",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <SpaceDashboardRoundedIcon />
//                 </ListItemIcon>
//                 <ListItemText
//                   primary="Student List"
//                   sx={{ opacity: open ? 1 : 0 }}
//                 />
//               </ListItemButton>
//             </Link>
//           </ListItem>

         

//           <Divider />
//           <Typography
//             variant="h6"
//             noWrap
//             component="div"
//             textAlign={"Center"}
//             color={"grey"}
//             marginTop={"5px"}
//           >
//             Registration Details
//           </Typography>
//           {/* Renewal / Registration */}
//           <ListItem
//             key="Status of File"
//             disablePadding
//             sx={{ display: "block" }}
//           >
//             <Link
//               to="/process"
//               style={{ textDecoration: "none", color: "inherit" }}
//             >
//               <ListItemButton
//                 sx={{
//                   minHeight: 48,
//                   justifyContent: open ? "initial" : "center",
//                   px: 2.5,
//                 }}
//               >
//                 <ListItemIcon
//                   sx={{
//                     minWidth: 0,
//                     mr: open ? 3 : "auto",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <ContentCopyRounded />
//                 </ListItemIcon>
//                 <ListItemText sx={{ opacity: open ? 1 : 0 }}>
//                   <Typography className=" text-left">
//                     Processing of File for <br /> Registration / Renewal <br />{" "}
//                     of Registration
//                   </Typography>
//                 </ListItemText>
//               </ListItemButton>
//             </Link>
//           </ListItem>
//           <ListItem
//             key="Status of File"
//             disablePadding
//             sx={{ display: "block" }}
//           >
//             <Link
//               to="registration"
//               style={{ textDecoration: "none", color: "inherit" }}
//             >
//               <ListItemButton
//                 sx={{
//                   minHeight: 48,
//                   justifyContent: open ? "initial" : "center",
//                   px: 2.5,
//                 }}
//               >
//                 <ListItemIcon
//                   sx={{
//                     minWidth: 0,
//                     mr: open ? 3 : "auto",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <ContentCopyRounded />
//                 </ListItemIcon>
//                 <ListItemText sx={{ opacity: open ? 1 : 0 }}>
//                   <Typography className=" text-left">
//                     Registration <br /> Verification
//                   </Typography>
//                 </ListItemText>
//               </ListItemButton>
//             </Link>
//           </ListItem>

//           <ListItem
//             key="Status of File"
//             disablePadding
//             sx={{ display: "block" }}
//           >
//             <Link
//               to="location"
//               style={{ textDecoration: "none", color: "inherit" }}
//             >
//               <ListItemButton
//                 sx={{
//                   minHeight: 48,
//                   justifyContent: open ? "initial" : "center",
//                   px: 2.5,
//                 }}
//               >
//                 <ListItemIcon
//                   sx={{
//                     minWidth: 0,
//                     mr: open ? 3 : "auto",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <ContentCopyRounded />
//                 </ListItemIcon>
//                 <ListItemText sx={{ opacity: open ? 1 : 0 }}>
//                   <Typography className=" text-left">
//                     Regions & Districts
//                   </Typography>
//                 </ListItemText>
//               </ListItemButton>
//             </Link>
//           </ListItem>
//           {/* Additional sidebar items go here */}
//         </List>

//         <Divider />
//         <List>
//           {["All mail", "Trash", "Spam", "Logout"].map((text, index) => (
//             <ListItem key={text} disablePadding sx={{ display: "block" }}>
//               <ListItemButton
//                 sx={{
//                   minHeight: 48,
//                   justifyContent: open ? "initial" : "center",
//                   px: 2.5,
//                 }}

//                 onClick={text === "Logout" ? handleOpenLogoutDialog : undefined}
//               >
//                 <ListItemIcon
//                   sx={{
//                     minWidth: 0,
//                     mr: open ? 3 : "auto",
//                     justifyContent: "center",
//                   }}
//                 >
//                   {index % 4 === 0 ? <InboxIcon /> : index % 4 === 1 ? <MailIcon />  : index % 4 === 2 ? <MailIcon /> : index % 4 === 3 ? <LogoutIcon /> : <LogoutIcon /> }
//                 </ListItemIcon>
//                 <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
//               </ListItemButton>
//             </ListItem>
//           ))}
//         </List>
//         <Logout 
//   open={logoutDialogOpen} 
//   onClose={handleCloseLogoutDialog} 
//   onConfirm={handleLogout} 
// />
//       </Drawer>
      
//       <Box component="main" sx={{ flexGrow: 1, p: 3,  right: 0 }}>
//       <div className="mt-10 border border-red-700">
//         <DrawerHeader />
//                   <Routes>
//        <Route path="/dashboard" element={<Dashboard />} />
        
//              <Route path="/college" element={<CollegeComponent />} />
//              <Route path="/process" element={<Process />} />
//              <Route path="/schedule" element={<Schedule />} />
//              <Route path="/queries" element={<Queries />} />
//              <Route path="/report" element={<Report />} />
//              <Route path="/registration" element={<Registration />} />
//              <Route path="/location" element={<Location />} />
//              {/* <Route path="college-profile" element={<CollegeProfile />} /> */}
//              {/* <Route path="staff-list" element={<Stafflist />} /> */}
//              <Route index element={<DefaultComponent />} />
//            </Routes>

//       </div>
//       </Box>
//     </Box>
//   );
// }

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import DrawerHeader from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Paper } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import SpaceDashboardRoundedIcon from "@mui/icons-material/SpaceDashboardRounded";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import { ContentCopyRounded } from "@mui/icons-material";
import AuthUser from "../../api/Api";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";

// import DefaultComponent from "./Pages/Welcome";
import DefaultComponent from "./Pages/Dashboard";
import CollegeComponent from "./Pages/Colleges";
import StaffComponent from "./Pages/stafflist/StaffList";
import StudentComponent from "./Pages/studentlist/StudentComponent";
import Dashboard from "./Pages/Dashboard";
import FormC from "./Pages/FormC";
// import DefaultComponent from "./Pages/Welcome";
import Process from "./Pages/Process";
import Schedule from "./Pages/InsSchedule";
import Report from "./Pages/InsReport";
import Queries from "./Pages/Queries";
import Registration from "./Pages/Registration";
import Location from "./Pages/Location";
import Logout from '../../../components/logout/Logout'; // Make sure to adjust the path if necessary
import CollegeProfile from './Pages/collegeprofile/CollegeProfile';


const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
  {[
    { text: 'Dashboard', icon: <SpaceDashboardRoundedIcon />, link: 'dashboard' },
    { text: 'Colleges', icon: <AccountBalanceIcon />, link: 'college' },
    { text: 'Staff List', icon: <CastForEducationIcon />, link: 'staff' },
    { text: 'Student List', icon: <SchoolRoundedIcon />, link: 'student' }
  ].map((item, index) => (
    <ListItem key={item.text} disablePadding>
      <ListItemButton component={Link} to={item.link}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.text} />
      </ListItemButton>
    </ListItem>
  ))}
</List>


      <Divider />
      <List>
  {[
    { text: 'Inward', icon: <SpaceDashboardRoundedIcon />, link: 'formC' },
    { text: 'Inspection', icon: <SpaceDashboardRoundedIcon />, link: 'report' },
    { text: 'FormC', icon: <SpaceDashboardRoundedIcon />, link: 'formC' },
    // { text: 'Colleges', icon: <AccountBalanceIcon />, link: 'college' },
    // { text: 'Staff List', icon: <CastForEducationIcon />, link: '/staff-list' },
    // { text: 'Student List', icon: <SchoolRoundedIcon />, link: '/student-list' }
  ].map((item, index) => (
    <ListItem key={item.text} disablePadding>
      <ListItemButton component={Link} to={item.link}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.text} />
      </ListItemButton>
    </ListItem>
  ))}
</List>


      <Divider />

      <List>
  {[
    { text: 'Users', icon: <SpaceDashboardRoundedIcon />, link: 'registration' },
    { text: 'Logout', icon: <SpaceDashboardRoundedIcon />, link: 'logout' },
    // { text: 'Colleges', icon: <AccountBalanceIcon />, link: 'college' },
    // { text: 'Staff List', icon: <CastForEducationIcon />, link: '/staff-list' },
    // { text: 'Student List', icon: <SchoolRoundedIcon />, link: '/student-list' }
  ].map((item, index) => (
    <ListItem key={item.text} disablePadding>
      <ListItemButton component={Link} to={item.link}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.text} />
      </ListItemButton>
    </ListItem>
  ))}
</List>
      {/* <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          
        }}
        className="justify-center items-start px-0.5 h-16"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Responsive drawer
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        width: '100%',
        height: '100vh', // Set a fixed height for the main content area
        overflow: 'auto', // Enable vertical scrolling if needed
        '@media (min-width: 600px)': {
          width: `calc(100% - ${drawerWidth}px)`, // Adjust width for larger screens
        },
      }}
    >
      <Toolbar />
      <Paper>
        <div className="border border-red-700">
          {/* Place your content here */}
          <DrawerHeader />
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/college" element={<CollegeComponent />} />
            <Route path="/Staff" element={<StaffComponent />} />
            <Route path="/student" element={<StudentComponent />} />
            <Route path="/formC" element={<FormC />} />
            <Route path="college/profile/:college_id" element={<CollegeProfile />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/queries" element={<Queries />} />
            <Route path="/report" element={<Report />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/location" element={<Location />} />
            {/* <Route path="/" element={<DefaultComponent />} /> */}
            <Route index element={<DefaultComponent />} />
          </Routes>
        </div>
      </Paper>
    </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
