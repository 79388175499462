import React, { useState, useEffect, useRef } from 'react';
import { Paper, Typography, Grid, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import AuthUser from '../../../api/Api';


const Dashboard = () => {
  const { http } = AuthUser();
  const Get_DASHBOARD = 'dashboard';
  const [dashboardData, setStudentData] = useState('');
  const [teachingstaffData, setTeachingstafData] = useState('');
  const [registeredCollegeData, setRegisteredCollegeData] = useState('');
  const [nonteachingstaffData, setNonTeachingstafData] = useState('');
  const [showAllStudentData, setShowAllStudentData] = useState(false);
  const [showAllTeachingData, setShowAllTeachingData] = useState(false);
  const [showAllNonTeachingData, setShowAllNonTeachingData] = useState(false);
  const [showAllRegistrationData, setShowAllRegistrationData] = useState(false);
  // const [data, setData] =useState('')
  const ref = useRef(null); // Ref to store the Paper component reference
//  console.log("dashboard", dashboardData);

useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await http.get(Get_DASHBOARD);
      // console.log("apidata", response);
      const data = response.data.data;

       const totalEnrollment = data.students.overall_total;
       const acadYear = data.students.acadYear;
       const totalBoys = data.students.total_boys;
       const totalGirls = data.students.total_girls;
       const totalTeaching = data.staff.teaching_count
       const totalMaleTeaching = data.staff.teaching_male_count
       const totalFemaleTeaching = data.staff.teaching_female_count
       const totalFullTimeTeaching = data.staff.teaching_full_time_count
       const totalPartTimeTeaching = data.staff.teaching_part_time_count
       const totalNonTeaching = data.staff.non_teaching_count
       const totalMaleNonTeaching = data.staff.non_teaching_male_count
       const totalFemaleNonTeaching = data.staff.non_teaching_female_count
       const totalFullTimeNonTeaching = data.staff.non_teaching_full_time_count
       const totalPartTimeNonTeaching = data.staff.non_teaching_part_time_count
       const totalColleges = data.registered_colleges.total_registered_colleges_count
       const intermediate = data.registered_colleges.college_level_counts_only.Intermediate
       const degree = data.registered_colleges.college_level_counts_only.Degree
       const boys = data.registered_colleges.college_category_counts_only.Boys
       const girls = data.registered_colleges.college_category_counts_only.Girls
       const coEducation = data.registered_colleges.college_category_counts_only['Co-Education'];
       const Karachi = data.registered_colleges.college_region_counts_only.Karachi;
       const Hyderabad = data.registered_colleges.college_region_counts_only.Hyderabad;
       const Sukkur = data.registered_colleges.college_region_counts_only.Sukkur;
       const Morning = data.registered_colleges.college_shift_counts_only.Morning;
       const Evening = data.registered_colleges.college_shift_counts_only.Evening;
       // Setting data in state
       setStudentData({totalEnrollment, totalBoys, totalGirls, acadYear});
       // Assuming the API returns the necessary data
      setTeachingstafData({totalTeaching, totalMaleTeaching, totalFemaleTeaching, totalFullTimeTeaching, totalPartTimeTeaching })
      setNonTeachingstafData({totalNonTeaching, totalMaleNonTeaching, totalFemaleNonTeaching, totalFullTimeNonTeaching, totalPartTimeNonTeaching })
      setRegisteredCollegeData({ totalColleges, intermediate, degree, boys, girls, coEducation, Karachi, Hyderabad, Sukkur, Morning, Evening })
      console.log("Colleges", registeredCollegeData)
      // setData(response.data.data); // Assuming the API returns the necessary data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, [Get_DASHBOARD]);



  const AdminData = {
    totalColleges: registeredCollegeData.totalColleges,
    totalEnrollment: dashboardData.totalEnrollment,
    acadYear: dashboardData.acadYear,
    totalTeachingStaff: teachingstaffData.totalTeaching,
    totalNonTeachingStaff: nonteachingstaffData.totalNonTeaching,

    regionWise: [
      { region: 'Karachi', count: registeredCollegeData['Karachi'] || 0 },
      { region: 'Hyderabad', count: registeredCollegeData['Hyderabad'] || 0 },
      { region: 'Sukkur', count: registeredCollegeData['Sukkur'] || 0 },
    ],
    genderWise: [
      { gender: 'Boys', count: registeredCollegeData['boys'] || 0 },
  { gender: 'Girls', count: registeredCollegeData['girls'] || 0 },
  { gender: 'Co-Education', count: registeredCollegeData['coEducation'] || 0 },
    ],
    levelOfStudy: [
      { level: 'Intermediate', count: registeredCollegeData['intermediate'] || 0 },
      { level: 'Degree', count: registeredCollegeData['degree'] || 0 },
    ], 
    shift: [
      { Shift: 'Morning', count: registeredCollegeData['Morning'] || 0 },
      { Shift: 'Evening', count: registeredCollegeData['Evening'] || 0 },
    ],
    enrollmentgenderWise: [
      { gender: 'Boys', count: dashboardData.totalBoys },
      { gender: 'Girls', count: dashboardData.totalGirls },
    ],
    teachinggenderWise: [
      { gender: 'Male', count: teachingstaffData.totalMaleTeaching },
      { gender: 'Female', count: teachingstaffData.totalFemaleTeaching },
    ],
    teachingcategoryWise: [
      { category: 'Full time', count: teachingstaffData.totalFullTimeTeaching },
      { category: 'Part Time', count: teachingstaffData.totalPartTimeTeaching },
    ],
    nonteachinggenderWise: [
      { gender: 'Male', count: nonteachingstaffData.totalMaleNonTeaching },
      { gender: 'Female', count: nonteachingstaffData.totalFemaleNonTeaching },
    ],
    nonteachingcategoryWise: [
      { category: 'Full time', count: nonteachingstaffData.totalFullTimeNonTeaching },
      { category: 'Part Time', count: nonteachingstaffData.totalPartTimeNonTeaching },
    ],
  };

// Event listener to handle clicks outside Paper components
useEffect(() => {
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowAllStudentData(false);
      setShowAllTeachingData(false);
      setShowAllNonTeachingData(false);
      setShowAllRegistrationData(false);
    }
  };
  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, [ref]);


const handleToggle = (setter) => {
  setShowAllStudentData(false);
  setShowAllTeachingData(false);
  setShowAllNonTeachingData(false);
  setShowAllRegistrationData(false);
  setter((prevState) => !prevState);
};



  return (
    <div className='p-2'>
      <Typography variant="h4" style={{textDecoration: 'underline'}}>Dashboard</Typography>
     
      <Grid container spacing={2} style={{ marginTop: '10px', justifyContent: 'center' }}  >
     

 {/* Total Enrollment */}
 <Grid item xs={12} sm={10} md={3}>
  <Paper
    ref={ref}
    elevation={6}
    style={{
      padding: '20px',
      textAlign: 'center',
      marginTop: '16px',
      cursor: 'pointer',
      height: '150px', // Set a fixed height for all Paper components
      overflowY: 'auto', // Add scrollbar if content overflows
      transition: 'height 0.5s ease', // Add transition for height change
      height: showAllRegistrationData ? '400px' : '190px', // Initial height based on showAll state
    }}
    onClick={() => handleToggle(setShowAllRegistrationData)}
    className={showAllRegistrationData ? 'hovered' : ''}
  >
    <Typography variant="h6">Total Private Colleges</Typography>
    <Typography variant="h4">{registeredCollegeData.totalColleges}</Typography> 
   

   
    {showAllRegistrationData && (
      <>

<div className='mt-10'>
        <Typography variant="h6">Region Wise</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Region</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {AdminData.regionWise.map((item) => (
              <TableRow key={item.region}>
                <TableCell>{item.region}</TableCell>
                <TableCell>{item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      
      
      <div className='mt-10'>
        <Typography variant="h6">Level of Study Wise</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Level of Study</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {AdminData.levelOfStudy.map((item) => (
              <TableRow key={item.level}>
                <TableCell>{item.level}</TableCell>
                <TableCell>{item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>


      <div className='mt-10'>
        <Typography variant="h6">Gender Wise</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Gender</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {AdminData.genderWise.map((item) => (
              <TableRow key={item.gender}>
                <TableCell>{item.gender}</TableCell>
                <TableCell>{item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className='mt-10'>
        <Typography variant="h6">Shift Wise</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Shift</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {AdminData.shift.map((item) => (
              <TableRow key={item.Shift}>
                <TableCell>{item.Shift}</TableCell>
                <TableCell>{item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      </>
    )}
  </Paper>
</Grid>


     {/* Total Enrollment */}
     <Grid item xs={12} sm={10} md={3}>
  <Paper
    ref={ref}
    elevation={6}
    style={{
      padding: '20px',
      textAlign: 'center',
      marginTop: '16px',
      cursor: 'pointer',
      height: '150px', // Set a fixed height for all Paper components
      overflowY: 'auto', // Add scrollbar if content overflows
      transition: 'height 0.5s ease', // Add transition for height change
      height: showAllStudentData ? '400px' : '190px', // Initial height based on showAll state
    }}
    onClick={() => handleToggle(setShowAllStudentData)}
    className={showAllStudentData ? 'hovered' : ''}
  >
    <Typography variant="h6">Total Enrollment</Typography>
    <Typography variant="h4">{dashboardData?.totalEnrollment}</Typography>

    <Typography variant="h6">Academic Session:</Typography>
<Typography variant="h4">
  {dashboardData?.acadYear && (
    `${dashboardData?.acadYear}-${(dashboardData?.acadYear % 100) + 1}`
  )}
</Typography>
    {showAllStudentData && (
      <div className='mt-10'>
        <Typography variant="h6">Gender Wise Enrollment</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Gender</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {AdminData.enrollmentgenderWise.map((item) => (
              <TableRow key={item.gender}>
                <TableCell>{item.gender}</TableCell>
                <TableCell>{item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    )}
  </Paper>
</Grid>
 {/* Teaching Staff */}
 <Grid item xs={12} sm={10} md={3}>
  <Paper
    ref={ref}
    elevation={6}
    style={{
      padding: '20px',
      textAlign: 'center',
      marginTop: '16px',
      cursor: 'pointer',
      height: '150px', // Set a fixed height for all Paper components
      overflowY: 'auto', // Add scrollbar if content overflows
      transition: 'height 0.5s ease', // Add transition for height change
      height: showAllTeachingData ? '600px' : '190px', // Initial height based on showAll state
    }}
    onClick={() => handleToggle(setShowAllTeachingData)}
    className={showAllTeachingData ? 'hovered' : ''}
  >
    <Typography variant="h6">Total Teaching Staff</Typography>
    <Typography variant="h4">{AdminData?.totalTeachingStaff}</Typography>
    {showAllTeachingData && (
      <>
      <div className='mt-10'>
        <Typography variant="h6">Gender Wise</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Gender</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {AdminData.teachinggenderWise.map((item) => (
              <TableRow key={item.gender}>
                <TableCell>{item.gender}</TableCell>
                <TableCell>{item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className='mt-10'>
        <Typography variant="h6">Category Wise</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {AdminData.teachingcategoryWise.map((item) => (
              <TableRow key={item.category}>
                <TableCell>{item.category}</TableCell>
                <TableCell>{item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      </>
    )}
  </Paper>
</Grid>

        {/* Non-Teaching Staff */}
        <Grid item xs={12} sm={10} md={3}>
  <Paper
    ref={ref}
    elevation={6}
    style={{
      padding: '20px',
      textAlign: 'center',
      marginTop: '16px',
      cursor: 'pointer',
      height: '150px', // Set a fixed height for all Paper components
      overflowY: 'auto', // Add scrollbar if content overflows
      transition: 'height 0.5s ease', // Add transition for height change
      height: showAllNonTeachingData ? '600px' : '190px', // Initial height based on showAll state
    }}
    onClick={() => handleToggle(setShowAllNonTeachingData)}
    className={showAllNonTeachingData ? 'hovered' : ''}
  >
    <Typography variant="h6">Total Non Teaching Staff</Typography>
    <Typography variant="h4">{AdminData?.totalNonTeachingStaff}</Typography>
    {showAllNonTeachingData && (
      <>
      <div className='mt-10'>
        <Typography variant="h6">Gender Wise</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Gender</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {AdminData.nonteachinggenderWise.map((item) => (
              <TableRow key={item.gender}>
                <TableCell>{item.gender}</TableCell>
                <TableCell>{item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className='mt-10'>
        <Typography variant="h6">Category Wise</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {AdminData.nonteachingcategoryWise.map((item) => (
              <TableRow key={item.category}>
                <TableCell>{item.category}</TableCell>
                <TableCell>{item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      </>
    )}
  </Paper>
</Grid>

      </Grid>
    </div>
  );
};

export default Dashboard;
