import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbar, GridToolbarExport } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import AuthUser from '../../api/Api';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Pagination from '@mui/material/Pagination';
import { useParams } from 'react-router-dom';

const GET_districts_URL = '/towns';  // Adjust the API endpoint

const TownData = ({townsData}) => {
  const { http } = AuthUser();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [towns, setTowns] = useState([]);
  const [selectedRegionId, setSelectedRegionId] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [newName, setNewName] = useState('');
  const [name, setName] = useState('');
  const { id } = useParams();
  useEffect(() => {
    if (townsData && townsData.length > 0) {
      const formattedData = townsData.map((town, index) => ({
        ...town,
        serialNumber: index + 1,
      }));
      setTowns(formattedData);
      setLoading(false);
    } else {
      fetchData();
    }
  }, [townsData]);

  const fetchData = async () => {
    try {
      // Simulate loading state
      setLoading(true);

      // Simulate fetching data from API
      // const response = await http.get(GET_REGIONS_URL);
      // const formattedData = response.data.regions.map((item, index) => ({
      //   ...item,
      //   serialNumber: index + 1,
      // }));
      // setRegions(formattedData);

      // Simulate successful data fetching
      // setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data');
      setLoading(false);
    }
  };


  const columns = [
    { field: 'serialNumber', headerName: 'ID', width: 70 },
    { field: 'town_name', headerName: 'Town Name', width: 150 },
    {
      field: 'action',
      headerName: 'Action',
      
      width: 200,
      renderCell: (params) => (
        <Select
          value=""
          onChange={(event) => handleActionChange(event, params)}
          displayEmpty
          inputProps={{ 'aria-label': 'Select Action' }}
        >
          <MenuItem value="" disabled>Select Action</MenuItem>
          <MenuItem value="edit">Edit</MenuItem>
          <MenuItem value="delete">Delete</MenuItem>
          {/* Add other action options as needed */}
        </Select>
      ),
    },
  ];

  const handleActionChange = (event, params) => {
    const selectedAction = event.target.value;

    if (selectedAction === 'edit') {
      setSelectedRegionId(params.id);
      setName(params.row.name);
      setOpenEditDialog(true);
    } else if (selectedAction === 'delete') {
      handleDelete(params);
    }
  };

  const handleCloseEditDialog = () => {
    setSelectedRegionId(null);
    setName('');
    setOpenEditDialog(false);
  };

  const handleSaveEdit = async () => {
    try {
      if (selectedRegionId) {
        // Make API call to update data in the backend
        await http.put(`/towns/${selectedRegionId}`, { name });
      } else {
        // Make API call to save new data to the backend
        await http.post('/towns', { name });
      }

      // Refresh the data
      fetchData();

      handleCloseEditDialog();
    } catch (error) {
      console.error('Error saving entry:', error);
    }
  };

  const handleDelete = async (params) => {
    try {
      // Make API call to delete data from the backend
      await http.delete(`/towns/${params.id}`);

      // Refresh the data
      fetchData();
    } catch (error) {
      console.error('Error deleting entry:', error);
    }
  };

  const handleCloseAddDialog = () => {
    setNewName('');
    setOpenAddDialog(false);
  };

  const handleSaveAdd = async () => {
    try {
      // Make API call to save data to the backend
      await http.post('/towns', { name: newName });

      // Refresh the data
      fetchData();

      handleCloseAddDialog();
    } catch (error) {
      console.error('Error saving entry:', error);
    }
  };

  const CustomToolbar = () => (
    <div className='justify-between p-4 border bg-gray-200'>
      <GridToolbar />
      <div className='text-right'>

      <Button onClick={() => setOpenAddDialog(true)} variant="contained" color="primary" className="mb-4">
        Add Town
      </Button>
      </div>
    </div>
  );

  if (loading) {
    return null;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <>
    <div>

      <div className='font-bold text-center text-4xl p-4 uppercase underline'>Talukas / Tehsils /Towns</div>
    
      <div className='px-8 py-2' style={{ height: '70vh' }}>
          <DataGrid
            rows={towns}
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
              // ToolbarExport: GridToolbarExport,
            }}
            rowClassName={(params) => `table-row-${params.row.id % 2 === 0 ? 'even' : 'odd'}`}
            />
        </div>
      
          </div>

      {/* Edit Dialog */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit Town</DialogTitle>
        <DialogContent>
          <TextField
            label="Town Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Cancel</Button>
          <Button onClick={handleSaveEdit} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Dialog */}
      <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
        <DialogTitle>Add New Town</DialogTitle>
        <DialogContent>
          <TextField
            label="Town Name"
            fullWidth
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog}>Cancel</Button>
          <Button onClick={handleSaveAdd} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TownData;
