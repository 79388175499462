import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import FreshReg from './FreshReg';
import Renewal from './Renewal';
import Affiliation from './Affiliation';

const AddCollegeOwnerInfo = ({ setEdit, collegeId }) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const https = "http://backend.dirpc.form-seef.com";
  const handleSubmit = async () => {
    setButtonDisabled(true);
    try {
      // Your submission logic here
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    } finally {
      setButtonDisabled(false);
    }
  };

  return (
    <div className="md:p-6 mt-3">
      <div className="mb-4">
        <button
          className="mt-5 shadow-sm border bg-white text-gray-500 rounded-lg w-28 h-8 flex justify-center items-center mb-10"
          onClick={() => setEdit(false)}
        >
          <IoIosArrowBack /> Back
        </button>
      </div>
      <div className="w-full border rounded-md">
        <div className="p-4 text-gray-400 border-b bg-gray-100">
          <h1 className="text-lg font-semibold">Registration and Affiliation Details</h1>
        </div>

        <div className="p-4">
          {/* Fresh Registration Details */}
          <FreshReg
          collegeId ={collegeId}
          https ={https} />

          <Divider className="p-6" />
          {/* Renewal Certificates */}
          <Renewal
          collegeId ={collegeId} https ={https} />

          <Divider className="p-2" />
          {/* Affiliation Section */}
          <Affiliation collegeId ={collegeId} https ={https} />

          <Divider className="p-2" />

          {/* Save Button */}
          {/* <div>
            <Button
              variant="contained"
              disabled={buttonDisabled}
              sx={{
                mt: 1,
                mr: 1,
                color: "white !important",
                backgroundColor: "#059669 !important",
              }}
              onClick={handleSubmit}
            >
              {buttonDisabled ? <CircularProgress /> : "Back"}
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AddCollegeOwnerInfo;
