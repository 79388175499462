import React from "react";

const CustomInputBox = ({
  name,
  label,
  error,
  className,
  type = "text",
  
  ...rest
  
}) => {
  return (
    <div className={className ? className : "my-4 w-full"}>
      <label
        htmlFor={name}
        style={{ color: "#AAA9A9" }}
        className="block text-sm font-bold mb-1"
      >
        {label}
      </label>
      <input
        {...rest}
        type={type}
        name={name}
        id={name}
        autoComplete={name}
        className={`appearance-none relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm ${
          error ? "border-red-500" : "border-gray-300"
        }`}
      />
      {error && <p className="text-red-500 text-xs italic">{error}</p>}
    </div>
  );
};

export default CustomInputBox;
