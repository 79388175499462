import React, { useState, useEffect } from "react";
import { HiPencil } from "react-icons/hi";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";

const RegDetails = ({ registrationdata }) => {
  const [registrations, setRegistrations] = useState([]);
  const [renewalRegistrations, setRenewalRegistrations] = useState([]);
  const [affiliations, setAffiliations] = useState([]);
  const [loading, setLoading] = useState(true);
  const https = "http://backend.dirpc.form-seef.com";
  
  useEffect(() => {
    if (registrationdata) {
      setRegistrations(registrationdata.college_registrations);
      setRenewalRegistrations(registrationdata.renewal_registrations);
      setAffiliations(registrationdata.affiliation_details);
      setLoading(false);
    }
  }, [registrationdata]);

  const handleViewCertificate = (filePath) => {
    window.open(`${https}${filePath}`);
  };

  return (
    <div className="md:py-6 p-1">
    <div className="w-full border rounded-md">
      <div className="p-3 text-gray-400 border-b bg-gray-100 flex justify-between items-center">
          <h1 className="text-lg font-semibold">
            Registration and Affiliation Details
          </h1>
          {/* <div className="text-lg border border-blue-800 rounded-full cursor-pointer w-9 h-9 shadow-md bg-white flex justify-center items-center">
            <HiPencil className="text-blue-800 text-2xl" />
          </div> */}
        </div>
        <div className="border-4 mt-1 p-1">
          {loading ? (
            <div className="flex justify-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="p-2 text-gray-600">
                <h1 className="text font-semibold underline">Fresh (First Time) Registration Details</h1>
              </div>
              {registrations.length > 0 ? (
               <div className="overflow-x-auto">
               <table className="min-w-full divide-y border divide-gray-200">
                 <thead className="bg-blue-100">
                   <tr>
                     <th className="text-center px-4 sm:px-6 py-3 text-sm sm:text-base font-medium text-gray-800 uppercase tracking-wider">Registration Certificate No.</th>
                     <th className="px-4 sm:px-6 py-3 text-center text-sm sm:text-base font-medium text-gray-500 uppercase tracking-wider">Date of Issuance</th>
                     <th className="px-4 sm:px-6 py-3 text-center text-sm sm:text-base font-medium text-gray-500 uppercase tracking-wider">Registration Valid Period</th>
                     <th className="px-4 sm:px-6 py-3 text-center text-sm sm:text-base font-medium text-gray-500 uppercase tracking-wider">Validity Date W.E.F</th>
                     <th className="px-4 sm:px-6 py-3 text-center text-sm sm:text-base font-medium text-gray-500 uppercase tracking-wider">Validity Date Ends On</th>
                     <th className="px-4 sm:px-6 py-3 text-center text-sm sm:text-base font-medium text-gray-500 uppercase tracking-wider">Action</th>
                   </tr>
                 </thead>
                 <tbody className="bg-white divide-y divide-gray-200">
                   {registrations.map((registration) => (
                     <tr key={registration.id}>
                       <td className="px-4 sm:px-6 py-4 whitespace-nowrap">{registration.fresh_registration_no || "N/A"}</td>
                       <td className="px-4 sm:px-6 text-center py-4 whitespace-nowrap">{registration.issuance_date || "N/A"}</td>
                       <td className="px-4 sm:px-6 py-4 text-center whitespace-nowrap">{registration.valid_registration_period || "N/A"}</td>
                       <td className="px-4 sm:px-6 py-4 text-center whitespace-nowrap">{registration.validity_date_wef || "N/A"}</td>
                       <td className="px-4 sm:px-6 py-4 text-center whitespace-nowrap">{registration.validity_date_ends || "N/A"}</td>
                       <td className="px-4 sm:px-6 py-4 whitespace-nowrap">
                         {registration.file_path && (
                           <button onClick={() => handleViewCertificate(registration.file_path)} className="text-indigo-600 hover:text-indigo-900">
                             View Certificate
                           </button>
                         )}
                       </td>
                     </tr>
                   ))}
                 </tbody>
               </table>
             </div>
             
              ) : (
                <div className="text-center py-4">No Fresh Registration Data Available</div>
              )}

              <Divider className="my-4" />

              <div className="p-4 text-gray-600">
                <h1 className="text-lg font-semibold underline">Renewal of Registration Details</h1>
              </div>
              {renewalRegistrations.length > 0 ? (
                <div className="overflow-x-auto">
                <table className="min-w-full divide-y border divide-gray-200">
                  <thead className="bg-blue-100">
                    <tr>
                      <th className="text-center px-6 py-3 text-sm font-medium text-gray-800 uppercase tracking-wider">Registration Certificate No.</th>
                      <th className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider">Date of Issuance</th>
                      <th className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider">Registration Valid Period</th>
                      <th className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider">Validity Date W.E.F</th>
                      <th className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider">Validity Date Ends On</th>
                      <th className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider">Action</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {renewalRegistrations.map((registration) => (
                      <tr key={registration.id}>
                        <td className="px-6 py-4 whitespace-nowrap">{registration.registration_no || "N/A"}</td>
                        <td className="px-6 text-center py-4 whitespace-nowrap">{registration.renewal_date || "N/A"}</td>
                        <td className="px-6 py-4 text-center whitespace-nowrap">{registration.valid_registration_period || "N/A"}</td>
                        <td className="px-6 py-4 text-center whitespace-nowrap">{registration.validity_date_wef || "N/A"}</td>
                        <td className="px-6 py-4 text-center whitespace-nowrap">{registration.validity_date_ends || "N/A"}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {registration.file_path && (
                            <button onClick={() => handleViewCertificate(registration.file_path)} className="text-indigo-600 hover:text-indigo-900">
                              View Certificate
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>
              ) : (
                <div className="text-center py-4">No Renewal Registration Data Available</div>
              )}

              <Divider className="my-4" />

              <div className="p-4 text-gray-600">
                <h1 className="text-lg font-semibold underline">Details of Affiliation with Board / University</h1>
              </div>
              {affiliations.length > 0 ? (
                <div className="overflow-x-auto">
                <table className="min-w-full divide-y border divide-gray-200">
                  <thead className="bg-blue-100">
                    <tr>
                      <th className="text-center px-6 py-3 text-sm font-medium text-gray-800 uppercase tracking-wider">Affiliated Board / University Name</th>
                      <th className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider">Action</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {affiliations.map((affiliation) => (
                      <tr key={affiliation.id}>
                        <td className="px-6 py-4 whitespace-nowrap">{affiliation.affiliation || "N/A"}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {affiliation.file_path && (
                            <button onClick={() => handleViewCertificate(affiliation.file_path)} className="text-indigo-600 hover:text-indigo-900">
                              View Certificate
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>
              ) : (
                <div className="text-center py-4">No Affiliation Data Available</div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegDetails;
