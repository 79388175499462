import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbar, GridToolbarExport } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import AuthUser from '../../../api/Api';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Paper from "@mui/material/Paper"
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';

const GET_REGISTER_URL = '/registered';

const RegistrationData = () => {
  const { http } = AuthUser();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [openInactiveStatusDialog, setOpenInactiveStatusDialog] = useState(false);
  const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const {  } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await http.get(GET_REGISTER_URL);
      const formattedData = response.data.data.map((item, index) => ({
        ...item,
        serialNumber: index + 1,
      }));
      setData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { field: 'serialNumber', headerName: 'S. No', width: 70 },
    { field: 'collegeName', headerName: 'College Name', width: 200 },
    { field: 'collegeRegion', headerName: 'Region', width: 200 },
    { field: 'collegeManagedBy', headerName: 'College Managed By', width: 150 },
    { field: 'principalName', headerName: 'Principal Name', width: 150 },
    { field: 'principalCnic', headerName: 'Principal CNIC', width: 150 },
    { field: 'principalMobile', headerName: 'Principal Mobile', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      renderCell: (params) => (
        <Button
          style={{
            backgroundColor: params.value === 'active' ? 'green' : 'red',
            color: 'white',
          }}
          onClick={() => handleStatusChange(params)}
        >
          {params.value}
        </Button>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      renderCell: (params) => (
        <Select
          value=""
          onChange={(event) => handleActionChange(event, params)}
          displayEmpty
          inputProps={{ 'aria-label': 'Select Action' }}
        >
          <MenuItem value="" disabled>Select Action</MenuItem>
          <MenuItem value="changePassword">Change Password</MenuItem>
          <MenuItem value="changeStatus">Change Status</MenuItem>
        </Select>
      ),
    },
  ];

  const handleStatusChange = (params) => {
    const userStatus = params.value;
    setSelectedUserId(params.id);

    if (userStatus === 'inactive') {
     setOpenStatusDialog(true);
    } else if (userStatus === 'active') {
      setOpenInactiveStatusDialog(true);
    }
  };

  const handleActionChange = (event, params) => {
    const selectedAction = event.target.value;

    if (selectedAction === 'changePassword') {
      setSelectedUserId(params.id);
      setOpenChangePasswordDialog(true);
    } else if (selectedAction === 'changeStatus') {
      setSelectedUserId(params.id);

      if (params.value === 'inactive') {
        setOpenStatusDialog(true);
      } else if (params.value === 'active') {
        setOpenInactiveStatusDialog(true);
      }
    }
  };

  const handleCloseChangePasswordDialog = () => {
    setNewPassword('');
    setOpenChangePasswordDialog(false);
  };

  const handleChangePassword = async () => {
    try {
      const response = await http.put(`/users/${selectedUserId}/reset-password`, {
        new_password: newPassword,
      });
  
      if (response.status === 200) {
        console.log('Password reset successfully');
      }
  
      setOpenChangePasswordDialog(false);
    } catch (error) {
      console.error('Error changing password:', error);
    }
  };

  const handleCloseStatusDialog = () => {
    setOpenStatusDialog(false);
  };

  const handleCloseInactiveStatusDialog = () => {
    setOpenInactiveStatusDialog(false);
  };

  const handleActivateUser = async () => {
    try {
      const response = await http.put(`/users/${selectedUserId}/reset-status`, { status: 'active' });

      if (response.status === 200) {
        console.log('User activated successfully');
      }

      const fetchDataResponse = await http.get(GET_REGISTER_URL);
      setData(fetchDataResponse.data.data);

      setOpenStatusDialog(false);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleDeactivateUser = async () => {
    try {
      const response = await http.put(`/users/${selectedUserId}/reset-status`, { status: 'inactive' });

      if (response.status === 200) {
        console.log('User deactivated successfully');
      }

      const fetchDataResponse = await http.get(GET_REGISTER_URL);
      setData(fetchDataResponse.data.data);

      setOpenInactiveStatusDialog(false);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <>
      <Box sx={{ width: '100%', padding: '4px', marginTop: '5px' }}>
        <Paper elevation={5}>
          <div className='p-6 underline'>
            <Typography variant="h4">Users / Ids</Typography>
          </div>
          <div className='px-6 py-2' style={{height:"70vh"}}>
            <DataGrid
              rows={data}
              columns={columns}
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </div>
        </Paper>
      </Box>

      <Dialog open={openStatusDialog} onClose={handleCloseStatusDialog}>
        <DialogTitle>Activate User</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to activate this user?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseStatusDialog}>Cancel</Button>
          <Button onClick={handleActivateUser} variant="contained" color="primary">
            Activate
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openInactiveStatusDialog} onClose={handleCloseInactiveStatusDialog}>
        <DialogTitle>Deactivate User</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to deactivate this user?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInactiveStatusDialog}>Cancel</Button>
          <Button onClick={handleDeactivateUser} variant="contained" color="primary">
            Deactivate
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openChangePasswordDialog} onClose={handleCloseChangePasswordDialog}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to change the password for this user?
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            id="newPassword"
            label="New Password"
            type="password"
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseChangePasswordDialog}>Cancel</Button>
          <Button onClick={handleChangePassword} variant="contained" color="primary">
            Change Password
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RegistrationData;
