import React, { useState, useEffect } from 'react';
import CustomInputBox from '../../common/CustomInputBox/CustomInputBox';

const DegreeTwoYears = ({ degreeCourses, collegeCategory, onUpdateDegreeTwoYears }) => {
  const [selectedFaculties, setSelectedFaculties] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(''); // Default to boys
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    if (degreeCourses && Array.isArray(degreeCourses)) {
      setSelectedFaculties(degreeCourses);
    } else {
      console.error("degreeCourses is not in the expected format:", degreeCourses);
    }

    if (collegeCategory) {
      setSelectedCategory(collegeCategory);
    } else {
      console.error("collegeCategory is not defined or empty:", collegeCategory);
    }

  }, [degreeCourses, collegeCategory]);

  useEffect(() => {
    const passPercentageData = selectedFaculties.reduce((acc, faculty) => {
      const appeared = inputValues[`${faculty}_appeared`] || 0;
      const passed = inputValues[`${faculty}_passed`] || 0;
      const passPercentage = ((passed / appeared) * 100 || 0).toFixed(1);

      const facultyPassPercentage = {
        faculty,
        [`${faculty}_appeared`]: appeared,
        [`${faculty}_passed`]: passed,
        [`${faculty}_passpercentage`]: passPercentage,
      };

      return {
        passPercentageData: [...acc.passPercentageData, facultyPassPercentage],
      };
    }, {
      passPercentageData: [],
    });

    onUpdateDegreeTwoYears(passPercentageData);
  }, [selectedFaculties, selectedCategory, inputValues]);

  const handleInputChange = (faculty, type, value) => {
    setInputValues({
      ...inputValues,
      [`${faculty}_${type}`]: value,
    });
  };

  const generateInputFields = (faculty) => {
    const appeared = inputValues[`${faculty}_appeared`] || 0;
    const passed = inputValues[`${faculty}_passed`] || 0;
    const passPercentage = ((passed / appeared) * 100 || 0).toFixed(1);

    return (
      <div key={faculty} className="m-4 border-8 p-4">
        {/* Faculty Name */}
        <div className="border p-4">
          <div className="border-4 rounded-lg flex text-xl font-bold items-center justify-between p-4">
            <div className="text-xl font-bold">{faculty}</div>
          </div>
        </div>

        {/* XI and XII input fields */}
        <div className="grid grid-cols-3 gap-4">
          <>
            <CustomInputBox
              label="Appeared"
              value={appeared}
              onChange={(e) => handleInputChange(faculty, 'appeared', parseInt(e.target.value, 10))}
            />
            <CustomInputBox
              label="Passed"
              value={passed}
              onChange={(e) => handleInputChange(faculty, 'passed', parseInt(e.target.value, 10))}
            />
            <CustomInputBox
              label="Passed Percentage"
              value={passPercentage}
              disabled
            />
          </>
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-4">
      {/* Display input fields for selected faculties */}
      {selectedFaculties.map((faculty) => generateInputFields(faculty))}
    </div>
  );
};

export default DegreeTwoYears;
