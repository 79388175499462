import Auth from "./Pages/Auth"




function App() {
  return (
    <>
    <Auth />
    
</>
  )
}

export default App
