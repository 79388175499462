import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Paper,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AuthUser from "../api/Api";
import PrimaryNavbar from "../PrimaryNavbar";
import { useNavigate} from 'react-router-dom';

const ChangePassword = () => {
  const [cnicPrincipal, setCnicPrincipal] = useState("");
  const [email, setEmail] = useState("");
  const [mobilePrincipal, setMobilePrincipal] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorField, setErrorField] = useState(null);
  const { http } = AuthUser(); // Removed unnecessary setToken function
// React Router hook for navigation
const navigate = useNavigate();

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    // Validate CNIC, mobilePrincipal, and email
    const cnicRegex = /^[0-9]{5}-[0-9]{7}-[0-9]$/;
    const mobileRegex = /^03[0-9]{2}-[0-9]{7}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!cnicRegex.test(cnicPrincipal)) {
      setErrorField("cnicPrincipal");
      return;
    } else if (!mobileRegex.test(mobilePrincipal)) {
      setErrorField("mobilePrincipal");
      return;
    } else if (!emailRegex.test(email)) {
      setErrorField("email");
      return;
    }

    try {
      // Send password change data to the backend using http.post
      const response = await http.post('/change-password', {
        cnicPrincipal,
        email,
        mobilePrincipal,
        new_password: newPassword,
        new_password_confirmation: confirmPassword, // Add this line
      });

      if (response.status === 200) {
        navigate('/login');
        console.log("Password change successful!");
        
        // Handle success scenario, e.g., display a success message
      } else {
        console.error("Password change failed.");
        // Handle the error scenario, e.g., display an error message
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error scenario, e.g., display an error message
    }
  };

  return (
    <>
      <PrimaryNavbar />
      <Container maxWidth="sm" style={{ marginTop: "40px" }}>
        <Paper elevation={20} style={{ padding: "20px" }}>
          <Typography variant="h4" align="center" gutterBottom>
            Change Password
          </Typography>
          <form onSubmit={handlePasswordChange}>
            <TextField
              label="Principal CNIC Number"
              fullWidth
              value={cnicPrincipal}
              onChange={(e) => {
                setCnicPrincipal(e.target.value);
                setErrorField(null);
              }}
              margin="normal"
              error={errorField === "cnicPrincipal"}
              helperText={
                errorField === "cnicPrincipal" ? "Invalid CNIC format" : ""
              }
            />
            <TextField
              label="Contact Number of Principal"
              fullWidth
              value={mobilePrincipal}
              onChange={(e) => {
                setMobilePrincipal(e.target.value);
                setErrorField(null);
              }}
              margin="normal"
              error={errorField === "mobilePrincipal"}
              helperText={
                errorField === "mobilePrincipal"
                  ? "Invalid mobile number format"
                  : ""
              }
            />
            <TextField
              label="Registered Email ID"
              fullWidth
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrorField(null);
              }}
              margin="normal"
              error={errorField === "email"}
              helperText={errorField === "email" ? "Invalid email format" : ""}
            />
            <TextField
              label="New Password"
              fullWidth
              type={showPassword ? "text" : "password"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Confirm Password"
              fullWidth
              type={showPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              margin="normal"
            />
            <Button type="submit" variant="contained" color="primary">
              Change Password
            </Button>
          </form>
        </Paper>
      </Container>
    </>
  );
};

export default ChangePassword;
