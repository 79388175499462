// InspectionReport.js
import React from 'react';

const InspectionReport = () => {
  return (
    <div className="p-4">
      <header className="text-center mb-6">
        <h1 className="text-2xl font-bold">DIRECTORATE OF INSPECTION & REGISTRATION OF PRIVATE COLLEGES, SINDH</h1>
        <p className="text-xl font-semibold">COLLEGE EDUCATION DEPARTMENT, GOVERNMENT OF SINDH</p>
        <p className="text-lg">DATED: 18th May, 2024</p>
      </header>

      <section className="mb-6">
        <h2 className="text-xl font-bold mb-4">INSPECTION REPORT</h2>
        
        {/* College Information */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold">1. Name of College</h3>
          <p>ARMY PUBLIC COLLEGE (INTERMEDIATE)</p>
          
          <h3 className="text-lg font-semibold mt-4">2. Address</h3>
          <p>150, IQBAL SHAHEED ROAD, KARACHI</p>
          
          <h3 className="text-lg font-semibold mt-4">3. Level of Study (Intermediate / Degree)</h3>
          <p>INTERMEDIATE</p>
          
          <h3 className="text-lg font-semibold mt-4">4. Faculty / Degree</h3>
          <p>PRE-ENGINEERING, PRE-MEDICAL, SCIENCE GENERAL, COMMERCE & HUMANITIES.</p>
          
          <h3 className="text-lg font-semibold mt-4">5. Name of Principal</h3>
          <p>ABDULLAH PATOLI</p>
          
          <h3 className="text-lg font-semibold mt-4">6. Name of Society / Trust / Individual</h3>
          <p>HQ 5 CORPS, KARACHI</p>
          
          <h3 className="text-lg font-semibold mt-4">7. Year of Establishment</h3>
          <p>1998</p>
          
          <h3 className="text-lg font-semibold mt-4">8. Applied for (Fresh / Renewal) Registration</h3>
          <p>RENEWAL</p>
          <p>In Case of Renewal, Last Renewal Year: 15-11-2021 TO 14-11-2023</p>
        </div>

        {/* Building Information */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold">BUILDING</h3>
          
          <h4 className="text-md font-semibold mt-4">1. Owned / Rented</h4>
          <p>OWNED</p>
          
          <h4 className="text-md font-semibold mt-4">2. Area of Plot</h4>
          <p>556215 Sq Yds</p>
          
          <h4 className="text-md font-semibold mt-4">3. Total No. of Rooms</h4>
          <p>27</p>
          
          <h4 className="text-md font-semibold mt-4">4. Total No. of Class Rooms</h4>
          <p>15</p>
          
          <h4 className="text-md font-semibold mt-4">5. Number of Books in Library</h4>
          <p>4264</p>
          
          <h4 className="text-md font-semibold mt-4">6. Laboratories</h4>
          <p>YES</p>
          <p>Physics: YES</p>
          <p>Chemistry: YES</p>
          <p>Biology: YES</p>
          <p>Computer: YES</p>
          
          <h4 className="text-md font-semibold mt-4">7. Staff Room</h4>
          <p>YES</p>
          
          <h4 className="text-md font-semibold mt-4">8. Toilet (Boys)</h4>
          <p>08</p>
          
          <h4 className="text-md font-semibold mt-4">9. Toilet (Girls)</h4>
          <p>04</p>
          
          <h4 className="text-md font-semibold mt-4">10. Drinking Water</h4>
          <p>DISPENSER WITH FILTER</p>
          
          <h4 className="text-md font-semibold mt-4">11. Play Ground</h4>
          <p>YES</p>
        </div>

        {/* Faculty Information */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold">FACULTY</h3>
          
          <h4 className="text-md font-semibold mt-4">1. Total No. of Teaching Staff</h4>
          <p>Total No.: 40</p>
          <p>Minimum Salary: Rs. 48,566/-</p>
          <p>Maximum Salary: Rs. 76,433/-</p>
          
          <h4 className="text-md font-semibold mt-4">2. Total No. of Non-Teaching Staff</h4>
          <p>Total No.: 21</p>
          <p>Minimum Salary: Rs. 31,153/-</p>
          <p>Maximum Salary: Rs. 2,10,000/-</p>
          
          <h4 className="text-md font-semibold mt-4">3. Salary Drawn</h4>
          <p>YES</p>
          <p>Through Cheque: -</p>
          <p>Cash in Hand: -</p>
        </div>

        {/* No. of Students Class Wise */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold">NO. OF STUDENTS CLASS WISE:</h3>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2">Class</th>
                <th className="px-4 py-2">PRE-ENGINEERING</th>
                <th className="px-4 py-2">PRE-MEDICAL</th>
                <th className="px-4 py-2">SCIENCE GENERAL</th>
                <th className="px-4 py-2">COMMERCE</th>
                <th className="px-4 py-2">HUMANITIES</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-4 py-2">XI</td>
                <td className="px-4 py-2">34</td>
                <td className="px-4 py-2">42</td>
                <td className="px-4 py-2">98</td>
                <td className="px-4 py-2">67</td>
                <td className="px-4 py-2">41</td>
              </tr>
              <tr>
                <td className="px-4 py-2">XII</td>
                <td className="px-4 py-2">31</td>
                <td className="px-4 py-2">21</td>
                <td className="px-4 py-2">-</td>
                <td className="px-4 py-2">-</td>
                <td className="px-4 py-2">-</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Fee Structure */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold">FEE STRUCTURE CLASS WISE:</h3>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2">Class</th>
                <th className="px-4 py-2">Category</th>
                <th className="px-4 py-2">Tuition fee for Academic Year 2021-2022</th>
                <th className="px-4 py-2">Tuition fee for Academic Year 2022-2023</th>
                <th className="px-4 py-2">Tuition fee for Academic Year 2023-2024</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-4 py-2">XI, XII (PRE ENG, PRE MED, SCIENCE GENERAL)</td>
                <td className="px-4 py-2">DEF (ARMY NAVY, PAF, CAF)</td>
                <td className="px-4 py-2">2034</td>
                <td className="px-4 py-2">2136</td>
                <td className="px-4 py-2">2242</td>
              </tr>
              {/* Add other fee structure rows as needed */}
            </tbody>
          </table>
        </div>

        {/* Bank Account Details */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold">BANK ACCOUNT DETAILS:</h3>
          <p>Name of Bank: ASKARI BANK LTD</p>
          <p>Title of Account: ARMY PUBLIC SCHOOL & COLLEGE BOYS SADDAR, KARACHI</p>
          <p>Account No.: 1350000031</p>
          <p>Total No. of Students Awarded Freeship: 10% FREESHIP OF TOTAL ENROLLMENT</p>
        </div>

        {/* Office Record */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold">OFFICE RECORD</h3>
          <p>1. Salary Register: YES</p>
          <p>2. Fee Collection Register: YES</p>
          <p>3. Cash Book: YES</p>
          <p>4. Attendance Register: YES</p>
          <p>5. Stock Register: YES</p>
          <p>6. Examination Record Result Register: YES</p>
          <p>7. Co-Curricular Activities: STUDENTS SPORTS WEEK ORGANIZED EVERY YEAR, INDOOR AND OUTDOOR SPORTS, DECLAMATION AND EVERY NATIONAL DAY CELEBRATED AND ANNUAL MAGAZINE</p>
        </div>

        {/* Recommendations */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold">RECOMMENDATIONS:</h3>
          <p>The Inspection committee visited the college and checked all the parameters mentioned in the application form. The college premises were very neat and clean, the classrooms were well-ventilated. The teaching faculty is well-trained. The teaching and learning process was going on smoothly.</p>
          <p>The college has applied for renewal of registration.</p>
          <p>In light of the inspection report, it is recommended that renewal of registration may be granted for the period of three years w.e.f. 15-11-2023 TO 14-11-2026.</p>
          <p>Submitted for kind perusal and necessary approval on the above.</p>
        </div>

        {/* Inspection Committee Members */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold">INSPECTION COMMITTEE MEMBERS:</h3>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2">SR. NO.</th>
                <th className="px-4 py-2">NAME OF MEMBERS OF INSPECTION COMMITTEE</th>
                <th className="px-4 py-2">DESIGNATION</th>
                <th className="px-4 py-2">SIGNATURE</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-4 py-2">01.</td>
                <td className="px-4 py-2">Deputy Director (M&E) Directorate of M&E of College Sindh, Karachi.</td>
                <td className="px-4 py-2">Member</td>
                <td className="px-4 py-2"></td>
              </tr>
              <tr>
                <td className="px-4 py-2">02.</td>
                <td className="px-4 py-2">Deputy Director (Inspection), Director General Colleges Sindh, Karachi.</td>
                <td className="px-4 py-2">Member</td>
                <td className="px-4 py-2"></td>
              </tr>
              <tr>
                <td className="px-4 py-2">03.</td>
                <td className="px-4 py-2">Pro. Dr. Sakina Samoo, Retired Additional Director Colleges, Karachi.</td>
                <td className="px-4 py-2">Member</td>
                <td className="px-4 py-2"></td>
              </tr>
              <tr>
                <td className="px-4 py-2">04.</td>
                <td className="px-4 py-2">Professor Majid Ali, Retired Additional Director Colleges, Karachi.</td>
                <td className="px-4 py-2">Member</td>
                <td className="px-4 py-2"></td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Chairman/Director Signature */}
        <footer className="text-center">
          <p className="font-semibold">PROF. DR. ABDUL QADIR SHAH</p>
          <p>CHAIRMAN / DIRECTOR</p>
          <p>Directorate of Inspection & Registration of Private Colleges Sindh, Karachi</p>
        </footer>
      </section>
    </div>
  );
};

export default InspectionReport;
