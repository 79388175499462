import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import CustomInputBox from "../../common/CustomInputBox/CustomInputBox";
import AuthUser from "../../../../../api/Api";

const FileUpload = ({ collegeId, https }) => {
  const { http } = AuthUser();
  const POST_RENEWAL_REG = "renewal-registration";
  const GET_RENEWAL_REG = "renewal-registration";
  const [file, setFile] = useState(null);
  const [registrationNo, setRegistrationNo] = useState("");
  const [filePreview, setFilePreview] = useState(null);
  const [renewalDate, setRenewalDate] = useState("");
  const [validityDateWef, setValidityDateWef] = useState("");
  const [validityDateEnds, setValidityDateEnds] = useState("");
  const [validRegistrationPeriod, setValidRegistrationPeriod] = useState("");
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false); // State for button disabled
  const [showInputFields, setShowInputFields] = useState(false); // State for showing input fields

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await http.get(`${GET_RENEWAL_REG}/${collegeId}`);
      console.log("Response", response);
      setFiles(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching files:", error);
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
  
    // Check file type
    const allowedTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];
    if (selectedFile && !allowedTypes.includes(selectedFile.type)) {
      setMessage('Invalid file type. Please select a JPG, JPEG, PNG, or SVG file.');
      setFile(null);
      setFilePreview(null);
      return;
    }
  
    setFile(selectedFile);
    const reader = new FileReader();
    reader.onload = (event) => {
      setFilePreview(event.target.result);
    };
    reader.readAsDataURL(selectedFile);
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !file ||
      !registrationNo ||
      !renewalDate ||
      !validityDateWef ||
      !validityDateEnds ||
      !validRegistrationPeriod
    ) {
      setMessage("Please fill out all fields.");
      return;
    }
    setButtonDisabled(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("registration_no", registrationNo);
    formData.append("renewal_date", renewalDate);
    formData.append("validity_date_wef", validityDateWef);
    formData.append("validity_date_ends", validityDateEnds);
    formData.append("valid_registration_period", validRegistrationPeriod);

    try {
      let response;
      if (editIndex !== null) {
        response = await http.put(POST_RENEWAL_REG.id, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } else {
        response = await http.post(POST_RENEWAL_REG, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      if (response.status === 201) {
        setMessage(response.data.message);
        setFile(null);
        setRegistrationNo("");
        setRenewalDate("");
        setValidityDateWef("");
        setValidityDateEnds("");
        setEditIndex(null);
        setFilePreview(null);
        fetchData();
        setShowInputFields(false); // Reset the state to hide input fields after submission
      } else {
        setMessage("An error occurred while processing the request.");
      }
    } catch (error) {
      setMessage("An error occurred while processing the request.");
      console.error(error);
    } finally {
      setButtonDisabled(false);
      setLoading(false); // Set loading to false after successful submission
    }
  };

  const handleEdit = (index) => {
    const selectedFile = files[index];
    setFile(null);
    setRegistrationNo(selectedFile.registration_no);
    setRenewalDate(selectedFile.renewal_date);
    setValidRegistrationPeriod(selectedFile.valid_registration_period);
    setValidityDateWef(selectedFile.validity_date_wef);
    setValidityDateEnds(selectedFile.validity_date_ends);
    setEditIndex(index);
  };

  const handleDelete = async (id) => {
    try {
      const response = await http.delete(`${POST_RENEWAL_REG}/${id}`);
      if (response.status === 200) {
        setMessage(response.data.message);
        fetchData();
      } else {
        setMessage("An error occurred while deleting the file.");
      }
    } catch (error) {
      setMessage("An error occurred while deleting the file.");
      console.error(error);
    }
  };

  const handleView = (filePath) => {
    window.open(`${https}${filePath}`);
  };

  return (
    <div className="md:p-6 mt-3">
      <div className="w-full border rounded-md">
        <div className="p-4">
          <div className="p-4 text-gray-600">
            <h1 className="text-lg font-semibold">
              Renewal of Registration Certificate Details
            </h1>
          </div>

          {loading ? (
            <CircularProgress />
          ) : files.length > 0 ? (
            <table className="min-w-full divide-y mt-10 border divide-gray-200">
              <thead className="bg-blue-100">
                <tr>
                  <th
                    scope="col"
                    className="text-center px-6 py-3 text-sm font-medium text-gray-800 uppercase tracking-wider"
                  >
                    Registration Certificate No.
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Renewal Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Validity Date W.E.F
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Validity Date Ends On
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {files.map((item, index) => (
                  <tr key={item.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item.registration_no}
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap">
                      {item.renewal_date}
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap">
                      {item.validity_date_wef}
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap">
                      {item.validity_date_ends}
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap">
                      {item.file_path && (
                        <button
                          onClick={() => handleView(item.file_path)}
                          className="px-3 py-1 bg-blue-500 text-white rounded mr-2 hover:bg-blue-600"
                        >
                          View
                        </button>
                      )}
                      <button
                        onClick={() => handleDelete(item.id)}
                        className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="mt-4">No records found.</p>
          )}

          {/* Conditional rendering to show input fields */}
          {showInputFields && (
            <form onSubmit={handleSubmit}>
              <div className="flex space-x-36">
                <CustomInputBox
                  label="Registration Certificate No."
                  name="registrationNo"
                  type="text"
                  value={registrationNo}
                  onChange={(e) => setRegistrationNo(e.target.value)}
                />
                <CustomInputBox
                  label="Renewal Date"
                  name="renewalDate"
                  type="date"
                  value={renewalDate}
                  onChange={(e) => setRenewalDate(e.target.value)}
                />
              </div>
              <div className="flex space-x-36">
                <CustomInputBox
                  label="Validity Date W.E.F"
                  name="validityDateWef"
                  type="date"
                  value={validityDateWef}
                  onChange={(e) => setValidityDateWef(e.target.value)}
                />
                <CustomInputBox
                  label="Validity Date Ends On"
                  name="validityDateEnds"
                  type="date"
                  value={validityDateEnds}
                  onChange={(e) => setValidityDateEnds(e.target.value)}
                />
              </div>

              <div className="flex space-x-36">
                <CustomInputBox
                  label="Registration Valid Period"
                  placeholder="Enter number of years"
                  name="validRegistrationPeriod"
                  value={validRegistrationPeriod}
                  onChange={(e) => {
                    const input = e.target.value;
                    const regex = /^[0-9]*$/;
                    if (regex.test(input) && input.length <= 2) {
                      setValidRegistrationPeriod(input);
                    }
                  }}
                />
                <div>
                  <label htmlFor="file" className="font-bold text-gray-700">
                    Choose File:
                  </label>
                  <input
                    type="file"
                    id="file"
                    accept=".jpg, .jpeg, .png, .svg" // Specify allowed file types
                    onChange={handleFileChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
                {/* File preview */}
                {filePreview && (
                  <div className="mb-4">
                    <img
                      src={filePreview}
                      alt="File Preview"
                      className="max-w-xs h-auto"
                    />
                  </div>
                )}
              </div>
              <button
                type="submit"
                className="py-2 px-4 bg-indigo-600 text-white rounded hover:bg-indigo-700"
                disabled={buttonDisabled} // Disable the button when loading
              >
                {buttonDisabled ? (
                  <div className="flex items-center">
                    <CircularProgress
                      size={20}
                      color="inherit"
                      className="mr-2"
                    />
                    Please wait...
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          )}

          {/* Conditional rendering to show different button text */}
          {!showInputFields && files.length > 0 ? (
  <button
    className="py-2 px-4 bg-indigo-600 text-white rounded hover:bg-indigo-700"
    onClick={() => setShowInputFields(true)}
  >
    Add Another Record
  </button>
) : (
  <button
    className="py-2 mt-10 ml-96 px-4 bg-indigo-600 text-white rounded hover:bg-indigo-700"
    onClick={() => setShowInputFields(true)}
  >
    Add Another Renewal of Registration Certifcate Detail
  </button>
)}


          {message && <p className="mt-4 text-green-500">{message}</p>}
        </div>
      </div>
    </div>
  );
};

export default FileUpload;

