import React, { useState, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CustomInputBox from "../../common/CustomInputBox/CustomInputBox";
import CustomSelectBox from "../../common/CustomSelectBox/CustomSelectBox";
import Divider from '@mui/material/Divider';
import AuthUser from "../../../../../api/Api";
import UploadMou from "./UploadMOU";

const BuildingTypes = [
  { label: "Residential", value: "Residential" },
  { label: "Residential but Modified as Educational Institution", value: "ResidentialModified" },
  { label: "Commercial", value: "Commercial" },
  { label: "Amenity", value: "Amenity" }
];
const ClassRoomTypes = [
  { label: "Ventilated", value: "Ventilate" },
  { label: "Air Conditioned", value: "AirConditioned" },
  { label: "Centrally Air Conditioned", value: "CentrallyAirConditioned" },
  // { label: "Amenity", value: "Amenity" }
  
];

const AddCollegeBuildingData = ({ setEdit, collegeId, nextStep }) => {
  const { http } = AuthUser();
  const GET_COLLEGES_BUILDING_DETAILS = "college-building";
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [collegeBuildingData, setCollegeBuildingData] = useState({
    building_type: "", // Add this line
    // classRoom_type: "", // Add this line
    // seating_Capacity: 0, // Add this line
    // total_seating_Capacity: 0, // Add this line
    total_rooms: 0,
    total_classrooms: 0,
    total_staffrooms: 0,
    total_principalrooms: 0,
    total_adminoffice: 0,
    total_miscrooms: 0,
    availability_of_laboratories: "",
    physics_lab_count: 0,
    chemistry_lab_count: 0,
    biology_lab_count: 0,
    computer_lab_count: 0,
    availability_of_library: "",
    total_books: 0,
    availability_of_washrooms: 0,
    washroom_boys_count: 0,
    washroom_girls_count: 0,
    washroom_staff_count: 0,
    availability_of_playground: "",
    playground_area: 0,
    mou_upload: [], // Store the MOU file
    mou_upload_name: "", // Store the MOU file name
    mou_upload_url: ""
  });

  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    const {
      total_classrooms,
      total_staffrooms,
      total_principalrooms,
      total_adminoffice,
      total_miscrooms
    } = collegeBuildingData;

    // Calculate total rooms
    const totalRooms =
      parseFloat(total_classrooms || 0) +
      parseFloat(total_staffrooms || 0) +
      parseFloat(total_principalrooms || 0) +
      parseFloat(total_adminoffice || 0) +
      parseFloat(total_miscrooms || 0);

    setCollegeBuildingData((prevData) => ({
      ...prevData,
      total_rooms: totalRooms,
    }));
  }, [
    collegeBuildingData.total_classrooms,
    collegeBuildingData.total_staffrooms,
    collegeBuildingData.total_principalrooms,
    collegeBuildingData.total_adminoffice,
    collegeBuildingData.total_miscrooms,
  ]);

  useEffect(() => {
    // Calculate total_washrooms whenever any washroom type changes
    const {
      washroom_boys_count,
      washroom_girls_count,
      washroom_staff_count
    } = collegeBuildingData;

    const totalWashrooms =
      parseFloat(washroom_boys_count || 0) +
      parseFloat(washroom_girls_count || 0) +
      parseFloat(washroom_staff_count || 0);

    setCollegeBuildingData((prevData) => ({
      ...prevData,
      availability_of_washrooms: totalWashrooms,
    }));
  }, [
    collegeBuildingData.washroom_boys_count,
    collegeBuildingData.washroom_girls_count,
    collegeBuildingData.washroom_staff_count,
  ]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      // Handle file uploads separately
      const file = files[0]; // Assuming only a single file is allowed

      // Read the file and set the preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setCollegeBuildingData((prevData) => ({
          ...prevData,
          mou_upload: file,
          mou_upload_name: file.name, // Store the file name
          mou_preview: reader.result, // Store the data URL for preview
        }));
      };
      reader.readAsDataURL(file);
    } else {
      // Handle other input changes
      setCollegeBuildingData((prevData) => ({
        ...prevData,
        [name]: name.includes("count") ? parseFloat(value) : value,
      }));

      // If availability_of_playground is "Yes," set mou_upload to null
      if (name === "availability_of_playground" && value === "Yes") {
        setCollegeBuildingData((prevData) => ({
          ...prevData,
          mou_upload: null,
          mou_upload_name: null, // Clear the file name as well
        }));
      }

      // If any of the washroom counts change, update the availability_of_washrooms
      if (name.includes("washroom_count")) {
        const totalWashrooms =
          parseFloat(collegeBuildingData.washroom_boys_count || 0) +
          parseFloat(collegeBuildingData.washroom_girls_count || 0) +
          parseFloat(collegeBuildingData.washroom_staff_count || 0);
        setCollegeBuildingData((prevData) => ({
          ...prevData,
          availability_of_washrooms: totalWashrooms,
        }));
      }

      // If any of the room counts change, update the total_rooms
      if (name.includes("total_rooms")) {
        const totalRooms =
          parseFloat(collegeBuildingData.total_classrooms || 0) +
          parseFloat(collegeBuildingData.total_staffrooms || 0) +
          parseFloat(collegeBuildingData.total_principalrooms || 0) +
          parseFloat(collegeBuildingData.total_adminoffice || 0) +
          parseFloat(collegeBuildingData.total_miscrooms || 0);
        setCollegeBuildingData((prevData) => ({
          ...prevData,
          total_rooms: totalRooms,
        }));
      }
    }
  };

  const fetchDatabyID = async () => {
    try {
      const res = await http.get(`${GET_COLLEGES_BUILDING_DETAILS}/${collegeId}`);
      if (res?.data?.data) {
        delete res.data.data["id"];
        delete res.data.data["created_at"];
        delete res.data.data["deleted_at"];
        delete res.data.data["updated_at"];
        setCollegeBuildingData({
          ...res.data.data[0],
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDatabyID(collegeId); // Pass collegeId as an argument to fetchData
  }, [collegeId]);

  const handleSubmit = async () => {
    if (setEdit) {
      setButtonDisabled(true);
      try {
        const formData = new FormData();

        // Append other data properties to formData
        Object.entries(collegeBuildingData).forEach(([key, value]) => {
          if (key === "mou_upload" && value instanceof File) {
            formData.append("mou_upload", value);
          } else {
            formData.append(key, value);
          }
        });

        // Make the POST request to submit the form data
        const response = await http.post(
          `${GET_COLLEGES_BUILDING_DETAILS}`,
          formData
        );

        // Show alert based on response message
        alert(response.data.message);
      } catch (error) {
        console.error("Error submitting data:", error);
        setError("Error submitting data");
      } finally {
        nextStep();
        setButtonDisabled(false);
        setLoading(false);
      };
    };
  };

  return (
    <div className="md:p-6 mt-3">
      <div className="mb-4">
        <button
          className="mt-5 shadow-sm border bg-white text-gray-500 rounded-lg w-28 h-8 flex justify-center items-center mb-10"
          onClick={() => setEdit(false)}
        >
          <IoIosArrowBack /> Back
        </button>
      </div>
      <div className="w-full border rounded-md">
        <div className="p-4 text-gray-400 border-b bg-gray-100">
          <h1 className="text-lg font-semibold">Building Structure</h1>
        </div>
        <div className="p-4">
          <div className="flex space-x-4">
            <CustomSelectBox
              label="Building Type"
              name="building_type"
              value={collegeBuildingData.building_type} // Update to match state structure
              options={BuildingTypes}
              onChange={handleChange}
            />
          </div>
          <CustomInputBox
            label="Total No. of Rooms"
            name="total_rooms"
            value={collegeBuildingData.total_rooms}
            disabled
          />
          <CustomInputBox
            label="Total No. of Class Rooms"
            name="total_classrooms"
            value={collegeBuildingData.total_classrooms}
            onChange={handleChange}
          />

          <CustomInputBox
            label="Total No. of Staff Rooms"
            name="total_staffrooms"
            value={collegeBuildingData.total_staffrooms}
            onChange={handleChange}
          />
          <CustomInputBox
            label="Total No. of Principal Rooms"
            name="total_principalrooms"
            value={collegeBuildingData.total_principalrooms}
            onChange={handleChange}
          />
          <CustomInputBox
            label="Total No. of Admin Office"
            name="total_adminoffice"
            value={collegeBuildingData.total_adminoffice}
            onChange={handleChange}
          />
          <CustomInputBox
            label="Total No. of Miscellanous Rooms (Store Room etc.)"
            name="total_miscrooms"
            value={collegeBuildingData.total_miscrooms}
            onChange={handleChange}
          />
 {/* <Divider className="p-2" />
<CustomSelectBox
              label="Class Room Type"
              name="classRoom_type"
              value={collegeBuildingData.classRoom_type} // Update to match state structure
              options={ClassRoomTypes}
              onChange={handleChange}
            />
<CustomInputBox
            label="Seating Capacity (For Lecture) Per Class Room"
            name="seating_Capacity"
            value={collegeBuildingData.seating_Capacity}
            onChange={handleChange}
          />
          <CustomInputBox
            label="Total Seating Capacity in The College (Examination Point view)"
            name="total_seating_Capacity"
            value={collegeBuildingData.total_seating_Capacity}
            onChange={handleChange}
          /> */}
          <Divider className="p-2" />
          <CustomSelectBox
            label="Availability of Laboratories"
            name="availability_of_laboratories"
            value={collegeBuildingData.availability_of_laboratories}
            onChange={handleChange}
            options={[
              { label: "Yes", value: "Yes" },
              { label: "No", value: "No" },
            ]}
          />
          {collegeBuildingData.availability_of_laboratories === "Yes" && (
            <div>
              <CustomInputBox
                label="Physics Lab Count"
                name="physics_lab_count"
                value={collegeBuildingData.physics_lab_count}
                onChange={handleChange}
              />
              <CustomInputBox
                label="Chemistry Lab Count"
                name="chemistry_lab_count"
                value={collegeBuildingData.chemistry_lab_count}
                onChange={handleChange}
              />
              <CustomInputBox
                label="Biology Lab Count"
                name="biology_lab_count"
                value={collegeBuildingData.biology_lab_count}
                onChange={handleChange}
              />
              <CustomInputBox
                label="Computer Lab Count"
                name="computer_lab_count"
                value={collegeBuildingData.computer_lab_count}
                onChange={handleChange}
              />
            </div>
          )}
          <Divider className="p-2" />
          <CustomSelectBox
            label="Availability of Library"
            name="availability_of_library"
            value={collegeBuildingData.availability_of_library}
            onChange={handleChange}
            options={[
              { label: "Yes", value: "Yes" },
              { label: "No", value: "No" },
            ]}
          />
          {collegeBuildingData.availability_of_library === "Yes" && (
            <div>
              <CustomInputBox
                label="Total Books"
                name="total_books"
                value={collegeBuildingData.total_books}
                onChange={handleChange}
              />
            </div>
          )}
          <Divider className="p-2" />
          <CustomInputBox
            label="Total No. of Washrooms (Boys + Girls + Staff)"
            name="availability_of_washrooms"
            value={collegeBuildingData.availability_of_washrooms}
            disabled
          />
          <CustomInputBox
            label="Washroom Boys Count"
            name="washroom_boys_count"
            value={collegeBuildingData.washroom_boys_count}
            onChange={handleChange}
          />
          <CustomInputBox
            label="Washroom Girls Count"
            name="washroom_girls_count"
            value={collegeBuildingData.washroom_girls_count}
            onChange={handleChange}
          />
          <CustomInputBox
            label="Washroom Staff Count"
            name="washroom_staff_count"
            value={collegeBuildingData.washroom_staff_count}
            onChange={handleChange}
          />
          <Divider className="p-2" />
          <CustomSelectBox
        label="Availability of Play Ground"
        name="availability_of_playground"
        value={collegeBuildingData.availability_of_playground}
        onChange={handleChange}
        options={[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ]}
      />
      {collegeBuildingData.availability_of_playground === "Yes" && (
        <CustomInputBox
          label="Play Ground Area (in sq. meters)"
          name="playground_area"
          value={collegeBuildingData.playground_area}
          onChange={handleChange}
        />
      )}
      {collegeBuildingData.availability_of_playground === "No" && (
        <div>
          <label htmlFor="mou_upload">Upload MOU:</label>
          <input
            type="file"
            name="mou_upload"
            onChange={handleChange}
            accept=".pdf,.doc,.docx"
          />
          {collegeBuildingData.mou_preview && (
            <div>
              <p>Preview:</p>
              <iframe
                src={collegeBuildingData.mou_preview}
                title="MOU Preview"
                width="100%"
                height="500px"
              />
            </div>
          )}
        </div>
      )}
        </div>
      </div>
      <div className="flex justify-end mt-4">
        <Button
          variant="contained"
          color="primary"
          disabled={buttonDisabled}
          onClick={handleSubmit}
        >
          {loading ? <CircularProgress size={24} /> : "Save & Next"}
        </Button>
      </div>
    </div>
  );
};

export default AddCollegeBuildingData;
