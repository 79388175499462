import React, { useState } from "react";
import PrimaryNavbar from '../PrimaryNavbar';
import { useNavigate } from "react-router-dom";
import { TextField, Button, Box, Container, Typography, Link, Paper, CircularProgress } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import AuthUser from "../api/Api";

const Login = () => {
  const navigate = useNavigate();
  const { http, setToken } = AuthUser();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [disableAdminNavigation, setDisableAdminNavigation] = useState(false);
  const [disableCollegeNavigation, setDisableCollegeNavigation] = useState(false);

  const handleLogin = () => {
    setLoading(true);
    http.post('/login', { email, password })
      .then((res) => {
        if (res.data && res.data.user && res.data.access_token) {
          setToken(res.data.user, res.data.access_token);
          // console.log(res);
          alert("Login successful!");
  
          const userRole = res.data.user.role;
          if (userRole === 'admin' || userRole === 'director' || userRole === 'deputydirector') {
            navigate("/admin");
          } else {
            navigate("/college");
          }
        } else {
          console.error("Invalid response data received");
          setLoading(false);
          alert("Invalid response received from the server. Please try again later.");
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        if (error.response && error.response.data && error.response.data.error) {
          setErrorMessage(error.response.data.error);
        } else {
          setErrorMessage("An unexpected error occurred. Please try again later.");
        }
      });
  };
  
  const theme = createTheme({
    palette: {
      primary: {
        main: "#2196F3",
      },
    },
  });

  return (
    <>
      <PrimaryNavbar />
      <ThemeProvider theme={theme}>
        <Container maxWidth="xs" style={{ marginTop: "5rem" }}>
          <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
            <Typography variant="h4" align="center" gutterBottom>
              Login
            </Typography>
            <form>
              <Box mt={2}>
                <TextField
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="outlined"
                  fullWidth
                />
              </Box>
              <Box mt={2}>
                <TextField
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  variant="outlined"
                  fullWidth
                />
              </Box>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleLogin}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <CircularProgress size={20} thickness={5} style={{ marginRight: '10px' }} />
                      Please wait...
                    </>
                  ) : (
                    "Login"
                  )}
                </Button>
              </Box>
              <Box mt={2}>
                <Typography align="center">
                  <Link component={RouterLink} to="/changepassword" color="inherit">
                    Forgot Password?
                  </Link>
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography align="center">
                  Don't have an account?{" "}
                  <Link component={RouterLink} to="/register" color="inherit">
                    Register
                  </Link>
                </Typography>
              </Box>
              {errorMessage && (
                <Box mt={2}>
                  <Typography color="error" align="center">
                    {errorMessage}
                  </Typography>
                </Box>
              )}
            </form>
          </Paper>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default Login;
