import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CollegeGeneralInfo from "./Profile/CollegeGeneralInfo/General-Information";
import AddCollegeGeneralInfo from "./Profile/CollegeGeneralInfo/AddCollegeGeneralInfo";
import OwnershipInfo from "./Profile/OwnerShip/Owner-Information";
import AddOwnershipInfo from "./Profile/OwnerShip/AddCollegeOwnerInfo";
import CollegeBuildingInfo from "./Profile/BuildingStructure/Building-Information";
import AddCollegeBuildingInfo from "./Profile/BuildingStructure/AddCollegeBuildingInfo";
import BasicFacilities from "./Profile/BasicFacilities/BasicFacilites";
import AddBasicFacilities from "./Profile/BasicFacilities/AddBasicFacilites";
import EnrollmentResult from "./Profile/EnrollmentResult/EnrollmentResult";
import AddEnrollmentResult from "./Profile/EnrollmentResult/AddEnrollmentResult";
import Result from "./Profile/PrevResult/Result";
import RegDet from "./Profile/Reg_Aff/RegDet";
import AddRegDet from "./Profile/Reg_Aff/AddRegDet";
import AddResult from "./Profile/PrevResult/AddResult";
import AuthUser from "../../../api/Api";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function TabsContainer() {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [edit, setEdit] = useState(false);

  const { collegeId } = AuthUser();

  const GeneralInfoTabsData = [
    {
      index: 0,
      label: "General Information",
      component: <CollegeGeneralInfo collegeId={collegeId} setEdit={setEdit} />,
      editedComponent: <AddCollegeGeneralInfo collegeId={collegeId} setEdit={setEdit} setValue={setValue} nextStep={() => setValue(value + 1)} />,
    },
    {
      index: 1,
      label: "Ownership",
      component: <OwnershipInfo collegeId={collegeId} setEdit={setEdit} />,
      editedComponent: <AddOwnershipInfo collegeId={collegeId} setEdit={setEdit} setValue={setValue} nextStep={() => setValue(value + 1)} />,
    },
    {
      index: 2,
      label: "Building Details",
      component: <CollegeBuildingInfo collegeId={collegeId} setEdit={setEdit} />,
      editedComponent: <AddCollegeBuildingInfo collegeId={collegeId} setEdit={setEdit} nextStep={() => setValue(value + 1)} />,
    },
    {
      index: 3,
      label: "Basic Facilities",
      component: <BasicFacilities collegeId={collegeId} setEdit={setEdit} />,
      editedComponent: <AddBasicFacilities collegeId={collegeId} setEdit={setEdit} nextStep={() => setValue(value + 1)} />,
    },
    {
      index: 4,
      label: "Enrollment",
      component: <EnrollmentResult collegeId={collegeId} setEdit={setEdit} />,
      editedComponent: <AddEnrollmentResult collegeId={collegeId} setEdit={setEdit} nextStep={() => setValue(value + 1)} />,
    },
    {
      index: 5,
      label: "Past Years Result",
      component: <Result collegeId={collegeId} setEdit={setEdit} />,
      editedComponent: <AddResult collegeId={collegeId} setEdit={setEdit} nextStep={() => setValue(value + 1)} />,
    },
    {
      index: 6,
      label: "Registration & Affiliation",
      component: <RegDet collegeId={collegeId} setEdit={setEdit} />,
      editedComponent: <AddRegDet collegeId={collegeId} setEdit={setEdit} nextStep={() => setValue(value + 1)} />,
    },
  ];

  const handleChange = (event, newValue) => {
    setEdit(false);
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Paper elevation={5} className="h-full">
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          padding: "30px",
        }}
      >
        <div className="shadow-xl sticky h-20 bg-orange-100">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            aria-label="visible arrows tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 0.3 },
              },
              [`& .MuiTabs-indicator`]: {
                color: "red",
              },
            }}
          >
            {GeneralInfoTabsData?.map((item) => (
              <Tab
                key={item.index}
                label={item?.label}
                {...a11yProps(item.index)}
              />
            ))}
          </Tabs>
        </div>

        <div
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          {GeneralInfoTabsData?.map((item, index) => (
            <TabPanel key={item.label} value={value} index={index} dir={theme.direction}>
              {edit ? (
                <React.Fragment>{item.editedComponent}</React.Fragment>
              ) : (
                <React.Fragment>{item.component}</React.Fragment>
              )}
            </TabPanel>
          ))}
        </div>
      </Box>
    </Paper>
  );
}
