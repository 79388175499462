import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthUser = () => {
  const navigate = useNavigate();

  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    return tokenString ? JSON.parse(tokenString) : null;
  };

  const getUser = () => {
    const userString = sessionStorage.getItem('user');
    return userString ? JSON.parse(userString) : null;
  };

  const getCollegeName = () => {
    const user = getUser();
    return user ? user.collegeName : '';
  };

  const getCollegeId = () => {
    const user = getUser();
    return user ? user.id : '';
  };

  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState(getUser());

  const saveToken = (user, token) => {
    sessionStorage.setItem('token', JSON.stringify(token));
    sessionStorage.setItem('user', JSON.stringify(user));

    setToken(token);
    setUser(user);

    if (user && user.role === 'college') {
      // Set access token for /college navigation
      navigate('/college');
    } else {
      // For all other roles, navigate to /admin
      navigate('/admin');
    }
  };

  const logout = () => {
    sessionStorage.clear();
    navigate('/');
  };

  const http = axios.create({
    // baseURL: "http://localhost:8000/api",
    baseURL: "https://backend.dirpc.form-seef.com/public/api",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  });

  
  return {
    setToken: saveToken,
    token,
    user,
    collegeName: getCollegeName(),
    collegeId: getCollegeId(),
    getToken,
    http,
    logout,
  };
};

export default AuthUser;
