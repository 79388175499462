import React, { useState, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSelectBox from "../../common/CustomSelectBox/CustomSelectBox";
import CustomInputBox from "../../common/CustomInputBox/CustomInputBox";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AuthUser from "../../../../../api/Api";

const locale = ['en-gb'];

const AddCollegeOwnerInfo = ({ setEdit, collegeId, nextStep }) => {
  
  
  
  const { http } = AuthUser();
  const COLLEGE_OWNERSHIP = "college-ownership";
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state
  const [collegeOwnerShip, setCollegeOwnerShip] = useState({
    college_area:"",
    college_bank_name:"",
    college_bank_title:"",
    college_bank_iban:"",
    college_owner: "",
    individual_owner_name: "",
    individual_owner_cnic: "",
    contact_no: "",
    organisation_name: "",
    organisation_managed_by: "",
    building: "",
    building_owner_name:"",
    building_owner_cnic:"",
    building_contact_no:"",
    monthly_rent: "",
    duration_of_agreement: "",
    agreement_start_date: "",
    agreement_end_date: "",
  });
  const [selectedOwnerType, setSelectedOwnerType] = useState(""); // Add state for selected owner type
  const [selectedBuildingType, setSelectedBuildingType] = useState(""); // Add state for selected building type
  const calculateFilledPercentage = () => {
    // Add your implementation to calculate the filled percentage
    // For example, count the number of filled fields and calculate the percentage
    const filledFieldsCount = Object.values(collegeOwnerShip).filter(value => value !== "").length;
    const totalFieldsCount = Object.keys(collegeOwnerShip).length;
    const filledPercentage = (filledFieldsCount / totalFieldsCount) * 100;
  
    return filledPercentage;
  };
  

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const collegeOwners = [
    { value: "individual", label: "Individual" },
    { value: "organisation", label: "Organisation" },
  ];

  const buildingTypes = [
    { value: "owned", label: "Owned" },
    { value: "rented", label: "Rented" },
  ];

  const ownerTypes = [
    { value: "individual", label: "Individual" },
    { value: "organisation", label: "Organisation" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Set values to null based on the selected owner and building type
    let updatedValues = {
      ...collegeOwnerShip,
      [name]: value,
    };
  
    // Handle owner type
    if (name === 'college_owner') {
      setSelectedOwnerType(value);
  
      // Reset owner-related fields when owner type changes
      if (value === 'individual') {
        updatedValues = {
          ...updatedValues,
          organisation_name: null,
          organisation_managed_by: null,
        };
      } else if (value === 'organisation') {
        updatedValues = {
          ...updatedValues,
          individual_owner_name: "",
          individual_owner_cnic: "",
          contact_no: "",
        };
      }
    }
  
    // Handle building type
    if (name === 'building') {
      setSelectedBuildingType(value);
  
      // Reset owner type when building type changes
      setSelectedOwnerType("");
  
      // Reset owner-related fields when building type changes
      if (value === 'owned') {
        updatedValues = {
          ...updatedValues,
          monthly_rent: null,
          duration_of_agreement: null,
          agreement_start_date: null,
          agreement_end_date: null,
        };
      } else if (value === 'rented') {
        updatedValues = {
          ...updatedValues,
          building_owner_name: null,
          building_owner_cnic: null,
          building_contact_no: null,
        };
      }
    }
  
    setCollegeOwnerShip(updatedValues);
  };
  

  const handleDateChange = (name, date) => {
    // Check if the date is null (when cleared)
    const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
  
    setCollegeOwnerShip((prevData) => ({
      ...prevData,
      [name]: formattedDate,
    }));
  };
  
  

// Correct function name
const fetchData = async () => {
  try {
    const res = await http.get(`${COLLEGE_OWNERSHIP}/${collegeId}`);
    // console.log("API Response:", res);
    if (res?.data?.data) {
      // res.data.data["college_location"] =
        // res.data.data["college_location"] === "Urban" ? "1" : "2";

      delete res.data.data["id"];
      delete res.data.data["created_at"];
      delete res.data.data["deleted_at"];
      delete res.data.data["updated_at"];
      // setCollegeGeneralInfo(res.data.data);
      setCollegeOwnerShip({
        ...res.data.data[0],
      });
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    setError("Error fetching data");
  } finally {
    setLoading(false);
  }
};

useEffect(() => {
  fetchData(collegeId); // Include collegeId in
}, [collegeId]);






const handleSubmit = async () => {
  try {
    // Set buttonDisabled to true to disable the button during submission
    setButtonDisabled(true);
    setLoading(true); // Set loading state to true

    // Make a POST request to submit the form data
    const res = await http.post(`${COLLEGE_OWNERSHIP}`, collegeOwnerShip);

    // Check if the submission was successful
    if (res.data.success) {
      // Show alert for successful submission
      const confirmed = window.confirm(res.data.message);
      if (confirmed) {
        // Proceed to the next step if user confirms
      }
    } else {
      // Show alert for unsuccessful submission with the error message from the backend
      alert(res.data.message);
    }
  } catch (error) {
    // Handle network or server errors
    console.error("Error submitting data:", error);
    // Show alert for error
    if (error.response && error.response.data && error.response.data.message && error.response.data.errors) {
      // If there are errors from the backend, extract and display them
      let errorMessage = error.response.data.message;
      if (error.response.data.errors) {
        const errors = error.response.data.errors;
        const errorMessages = Object.values(errors).flatMap(errorArray => errorArray.join('\n'));
        errorMessage += '\n\n' + errorMessages.join('\n');
      }
      alert(errorMessage);
    } else if (error.response && error.response.data && error.response.data.message) {
      // If there's a general error message from the backend, display it
      alert(error.response.data.message);
    } else {
      // If there's no specific error message from the backend, display a generic message
      alert("Error submitting data. Please try again later.");
    }
  } finally {
    // Reset buttonDisabled to false after submission is completed
    nextStep();
    setButtonDisabled(false);
    // Reset loading state only if submission was successful
    setLoading(false);
  }
};







  return (
    <div className="md:p-6 mt-3">
      <div className="mb-4">
        <button
          className="mt-5 shadow-sm border bg-white text-gray-500 rounded-lg w-28 h-8 flex justify-center items-center mb-10"
          onClick={() => setEdit(false)}
        >
          <IoIosArrowBack /> Back
        </button>
      </div>
      <div className="w-full border rounded-md">
        <div className="p-4 text-gray-400 border-b bg-gray-100">
          <h1 className="text-lg font-semibold">Ownership of College</h1>
        </div>

        <div className="p-4">
          <CustomInputBox
            label="Total Area of College (In Sq.Yds)"
            name="college_area"
            value={collegeOwnerShip.college_area}
            onChange={handleChange}
          />

          {/* <Divider className="p-2" /> */}

          <CustomSelectBox
            className="mt-3"
            label="College Owned By"
            name="college_owner"
            value={collegeOwnerShip.college_owner}
            options={collegeOwners}
            onChange={handleChange}
          />

          {/* Render input boxes based on the selected owner type */}
          {collegeOwnerShip.college_owner === "individual" && (
            <>
              <CustomInputBox
                label="Name of Owner"
                name="individual_owner_name"
                value={collegeOwnerShip.individual_owner_name}
                onChange={handleChange}
              />
              <CustomInputBox
                label="CNIC No."
                name="individual_owner_cnic"
                value={collegeOwnerShip.individual_owner_cnic}
                onChange={handleChange}
              />
              <CustomInputBox
                label="Contact No."
                name="contact_no"
                value={collegeOwnerShip.contact_no}
                onChange={handleChange}
              />
            </>
          )}

          {collegeOwnerShip.college_owner === "organisation" && (
            <>
              <CustomInputBox
                label="Organisation Name"
                name="organisation_name"
                value={collegeOwnerShip.organisation_name}
                onChange={handleChange}
              />
              <CustomInputBox
                label="Organisation Managed by"
                name="organisation_managed_by"
                value={collegeOwnerShip.organisation_managed_by}
                onChange={handleChange}
              />
            </>
          )}

          

          {/* <Divider className="p-2" /> */}

          <CustomSelectBox
            label="Building  (Owned / Rented)"
            name="building"
            value={collegeOwnerShip.building}
            options={buildingTypes}
            onChange={handleChange}
          />

          {collegeOwnerShip.building === "owned" && (
            <>
              <CustomInputBox
                label="Name of Owner"
                name="building_owner_name"
                value={collegeOwnerShip.building_owner_name}
                onChange={handleChange}
              />
              <CustomInputBox
                label="CNIC No."
                name="building_owner_cnic"
                value={collegeOwnerShip.building_owner_cnic}
                onChange={handleChange}
              />
              <CustomInputBox
                label="Contact No."
                name="building_contact_no"
                value={collegeOwnerShip.building_contact_no}
                onChange={handleChange}
              />
            </>
          )}

          {collegeOwnerShip.building === "rented" && (
            <>
              {/* Additional fields for Rented building */}
              <CustomInputBox
                label="Monthly Rent"
                name="monthly_rent"
                value={collegeOwnerShip.monthly_rent}
                onChange={handleChange}
              />
              <CustomInputBox
                label="Duration of Agreement"
                name="duration_of_agreement"
                value={collegeOwnerShip.duration_of_agreement}
                onChange={handleChange}
              />

              <div className="flex gap-8">
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                  <DemoContainer components={['DatePicker']}>
                    <DemoItem label="Agreement Start Date">
                    <DatePicker
  label="Start Date"
  name="agreement_start_date"
  value={collegeOwnerShip.agreement_start_date ? dayjs(collegeOwnerShip.agreement_start_date) : null}
  onChange={(date) => handleDateChange("agreement_start_date", date)}
/>
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                  <DemoContainer components={['DatePicker']}>
                    <DemoItem label="Agreement End Date">
                    <DatePicker
  label="End Date"
  name="agreement_end_date"
  value={collegeOwnerShip.agreement_end_date ? dayjs(collegeOwnerShip.agreement_end_date) : null}
  onChange={(date) => handleDateChange("agreement_end_date", date)}
/>
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </>
          )}

<div className="p-4 text-gray-400 border-b bg-gray-100">
          <h1 className="text-lg font-semibold">Bank Account Details of College</h1>
        </div>
        <CustomInputBox
            label="Name of Bank"
            name="college_bank_name"
            value={collegeOwnerShip.college_bank_name}
            onChange={handleChange}
          />
 <CustomInputBox
            label="Bank Account Title"
            name="college_bank_title"
            value={collegeOwnerShip.college_bank_title}
            onChange={handleChange}
          />
<CustomInputBox
            label="Bank Account No. (IBAN: PKXXUBLXXXXXXXXXXXXXXXXX)"
            name="college_bank_iban"
            value={collegeOwnerShip.college_bank_iban}
            onChange={handleChange}
          />

          <div>
          <Button
  variant="contained"
  disabled={buttonDisabled}
  sx={{
    mt: 1,
    mr: 1,
    color: "white !important",
    backgroundColor: "#059669 !important",
  }}
  onClick={handleSubmit}
>
  {buttonDisabled ? (
    <div style={{ display: "flex", alignItems: "center" }}>
      <CircularProgress size={24} sx={{ marginRight: 1 }} />
      <span>Please wait...</span>
    </div>
  ) : (
    "Save & Next"
  )}
</Button>

          </div>
        </div>
      </div>
      
    </div>
  );
};

export default AddCollegeOwnerInfo;
