// import React, { useState } from "react";
// // import AppBar from './directorateadmin/components/Layout/AppBar'
// import Sidebar from './Sidebar/Sidebar';
// import { ThemeProvider, createTheme } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";
// import { Container, FormControlLabel, Switch, Box } from "@mui/material";
// import {Routes, Route, Outlet} from 'react-router-dom'
// import Dashboard from "./Role";
// const CollegePortal = () => {
//   const [darkMode, setDarkMode] = useState(false);

//   // Create theme objects for dark and light modes
//   const lightTheme = createTheme();
//   const darkTheme = createTheme({
//     palette: {
//       mode: "dark",
//     },
//   });

//   // Toggle between dark and light modes
//   const toggleDarkMode = () => {
//     setDarkMode(!darkMode);
//   };

//   return (
//     <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
//       <CssBaseline />
//       {/* <AppBar/> */}
//       <Sidebar  />

//       {/* Dark Mode / Light Mode Switch */}
//       <Box
//         position="fixed"
//         bottom="30px"
//         right="30px"
//         border="2px solid #2c82c9"
//         p={1}
//         borderRadius={10}
//         display="flex"
//         alignItems="center"
//         justifyContent="flex-end"
//         bgcolor="background.paper"
//       >
//         <FormControlLabel
//           control={<Switch checked={darkMode} onChange={toggleDarkMode} />}
//           label={darkMode ? "Dark Mode" : "Light Mode"}
//         />
//       </Box>
//     </ThemeProvider>
//   );
// };

// export default CollegePortal;

import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from '@mui/icons-material/Logout';
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
// import LogoutIcon from '@mui/icons-material/Logout';
import SpaceDashboardRoundedIcon from "@mui/icons-material/SpaceDashboardRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import { ContentCopyRounded } from "@mui/icons-material";
import AuthUser from "../../api/Api";
import Logout from '../../../components/logout/Logout'; // Make sure to adjust the path if necessary

import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";

// import CollegeComponent from "../../Pages/Colleges";
// import Dashboard1 from "../../Pages/Dashboard1";
// import DefaultComponent from "./Pages/Welcome";
// import Process from "../../Pages/Process";
// import Schedule from "../../Pages/InsSchedule";
// import Report from "../../Pages/InsReport";
// import Queries from "../../Pages/Queries";
// import Registration from "../../Pages/Registration";
import Dashboard from "./Pages/Dashboard1";
import CollegeProfile from "./Pages/College-Profile";
import DefaultComponent from "./Pages/College-Profile";
import Stafflist from "./Pages/staffList/Stafflist";
import Studentlist from "./Pages/studentlist/Student";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();

  const [logoutDialogOpen, setLogoutDialogOpen] = React.useState(false);
  const handleOpenLogoutDialog = () => {
    setLogoutDialogOpen(true);
  };
  
  const handleCloseLogoutDialog = () => {
    setLogoutDialogOpen(false);
  };
  
  const {token, collegeName, logout} = AuthUser()
  const handleLogout = () => {
    if(token != undefined) {
      logout();
    }
  };

  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar position="fixed" open={open} className="justify-center items-start px-0.5 h-16">
  <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
    <div sx={{ display: "flex",  }}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{
          marginRight: 2,
          ...(open && { display: "none" }),
        }}
      >
        <MenuIcon />
      </IconButton>
      <Typography variant="h6" noWrap component="div">
        {collegeName}
      </Typography>
    </div>
    <div className="flex justify-end">
  <IconButton
    size="large"
    aria-label="account of current user"
    aria-controls="menu-appbar"
    aria-haspopup="true"
    onClick={handleMenu}
    color="inherit"
  >
    <Avatar sx={{ width: 32, height: 32 }} >A</Avatar>
  </IconButton>
  <Menu
    id="menu-appbar"
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    keepMounted
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    open={openMenu}
    onClose={handleCloseMenu}
  >
    <MenuItem onClick={handleCloseMenu}>Profile</MenuItem>
    <MenuItem onClick={handleCloseMenu}>My account</MenuItem>
    <MenuItem onClick={handleOpenLogoutDialog}>Logout</MenuItem>
  </Menu>
</div>

  </Toolbar>
</AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
       

        <List>
          {/* dashboard */}
          <ListItem key="Dashboard" disablePadding sx={{ display: "block" }}>
            <Link
              to=""
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <SpaceDashboardRoundedIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Dashboard"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem>

          {/* Colleges */}
          <ListItem
            key="College Profile"
            disablePadding
            sx={{ display: "block" }}
          >
            <Link
              to="college-profile"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <SchoolRoundedIcon />
                </ListItemIcon>
                <ListItemText
                  primary="College Profile"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem>

           {/* Staff List */}
          <ListItem key="Staff" disablePadding sx={{ display: "block" }}>
            <Link
              to="staff-list"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <SpaceDashboardRoundedIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Staff List"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem>

           {/* Student List */}
           <ListItem key="Student" disablePadding sx={{ display: "block" }}>
            <Link
              to="student-list"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <SpaceDashboardRoundedIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Student List"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem>

          {/* <Divider />
          <Typography
            variant="h6"
            noWrap
            component="div"
            textAlign={"Center"}
            color={"grey"}
            marginTop={"5px"}
          >
            Registration Details
          </Typography> */}
          {/* Renewal / Registration */}
          {/* <ListItem
            key="Status of File"
            disablePadding
            sx={{ display: "block" }}
          >
            <Link
              to="/process"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ContentCopyRounded />
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  <Typography>
                    Processing of File for <br /> Registration / Renewal <br />{" "}
                    of Registration
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </Link>
          </ListItem> */}

          {/* Additional sidebar items go here */}
        </List>

        <Divider />
        <List>
          {["All mail", "Trash", "Spam", "Logout"].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}

                onClick={text === "Logout" ? handleOpenLogoutDialog : undefined}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {index % 4 === 0 ? <InboxIcon /> : index % 4 === 1 ? <MailIcon />  : index % 4 === 2 ? <MailIcon /> : index % 4 === 3 ? <LogoutIcon /> : <LogoutIcon /> }
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Logout 
  open={logoutDialogOpen} 
  onClose={handleCloseLogoutDialog} 
  onConfirm={handleLogout} 
/>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Routes>
          <Route index element={<Dashboard />} />
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          <Route path="/college-profile" element={<CollegeProfile />} />
          <Route path="/staff-list" element={<Stafflist />} />
          <Route path="/student-list" element={<Studentlist />} />
        </Routes>
      </Box>
    </Box>
  );
}
