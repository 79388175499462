import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import Paper from "@mui/material/Paper"
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import AuthUser from '../../../../api/Api';

const GET_STAFF = 'allstudents';

const StudentList = () => {
  const { http } = AuthUser();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [openVoucherDialog, setOpenVoucherDialog] = useState(false);
  const [voucherData, setVoucherData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await http.get(GET_STAFF);
      const formattedData = response.data.data.map((item, index) => ({
        ...item,
        serialNumber: index + 1,
        college_name: item.college ? item.college.college_name : 'N/A',
      }));
      setData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  const handleViewVoucher = (studentId) => {
    setSelectedStudentId(studentId);
    // Fetch voucher data or set dummy data for the selected student
    setVoucherData({ /* your voucher data here */ });
    setOpenVoucherDialog(true);
  };

  const handleCloseVoucherDialog = () => {
    setOpenVoucherDialog(false);
    setVoucherData(null);
  };

  const columns = [
    { field: 'serialNumber', headerName: 'S. No', width: 30 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'fatherName', headerName: 'Father Name', width: 150 },
    { field: 'gender', headerName: 'Gender', width: 70 },
    { field: 'dateOfBirth', headerName: 'Date of Birth', width: 100 },
    { field: 'Bform', headerName: 'CNIC / B Form No.', width: 150 },
    { field: 'admission_year', headerName: 'Year of Admission', width: 150 },
    { field: 'class', headerName: 'Class', width: 50 },
    { field: 'faculty', headerName: 'Faculty', width: 150 },
    { field: 'fee', headerName: 'Category', width: 150, renderCell: (params) => {
      if (params.value === 'FEEPAYING') return 'Full FEE';
      if (params.value === 'FREESHIP') return 'FREESHIP';
      return params.value;
    }},
    { field: 'college_name', headerName: 'College', width: 350 },
    { field: 'contact', headerName: 'Contact No.', width: 200 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <Button variant="contained" color="primary" onClick={() => handleViewVoucher(params.id)}>
          View Paid Fee Voucher
        </Button>
      ),
    },
  ];

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <>
      <Box sx={{ width: '100%', padding: '4px', marginTop: '5px' }}>
        <Paper elevation={5}>
          <div className='p-6 underline'>
            <Typography variant="h4">Student List</Typography>
          </div>
          <div className='px-6 py-2' style={{height:"70vh"}}>
            <DataGrid
              rows={data}
              columns={columns}
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </div>
        </Paper>
      </Box>

      <Dialog open={openVoucherDialog} onClose={handleCloseVoucherDialog}>
        <DialogTitle>Paid Fee Voucher</DialogTitle>
        <DialogContent>
          {/* Render the voucher data here */}
          <div>
            {voucherData ? (
              <div>
                {/* Display voucher details */}
                <p>Voucher Details for Student ID: {selectedStudentId}</p>
                {/* More voucher details... */}
              </div>
            ) : (
              <p>Loading voucher details...</p>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseVoucherDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StudentList;
