import React, { useState, useEffect } from 'react';
import CustomInputBox from '../../common/CustomInputBox/CustomInputBox';

const semesters = Array.from({ length: 8 }, (_, index) => index + 1);

const DegreeFourYears = ({ collegeCategory , onuUpdateDegreeFourYears }) => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [inputValues, setInputValues] = useState({});
  const [degreeFourYears, setDegreeFourYears] = useState({
    overallTotal: 0,
    semesterTotal:0,
    inputValues: 0,
  });

  useEffect(() => {
    if (collegeCategory) {
      setSelectedCategory(collegeCategory);
    } else {
      console.error("collegeCategory is not defined or empty:", collegeCategory);
    }
  }, [collegeCategory]);

  useEffect(() => {
    // Calculate overall total enrollment for all semesters
    const grandTotal = semesters.reduce((acc, semester) => {
      const boys = (selectedCategory === 'Boys' || selectedCategory === 'Co-Education') ? (inputValues[`${semester}_boys`] || 0) : 0;
      const girls = (selectedCategory === 'Girls' || selectedCategory === 'Co-Education') ? (inputValues[`${semester}_girls`] || 0) : 0;
      const semesterTotal = boys + girls;
  
      return {
        overallTotal: acc.overallTotal + semesterTotal,
        totalBoys: acc.totalBoys + boys,
        totalGirls: acc.totalGirls + girls,
        inputValues: inputValues,
      };
    }, {
      overallTotal: 0,
      totalBoys: 0,
      totalGirls: 0,
      inputValues: 0,
    });
  
    const calculateSemesterTotal = () => {
      return semesters.reduce((acc, semester) => {
        const boys = (selectedCategory === 'Boys' || selectedCategory === 'Co-Education') ? (inputValues[`${semester}_boys`] || 0) : 0;
        const girls = (selectedCategory === 'Girls' || selectedCategory === 'Co-Education') ? (inputValues[`${semester}_girls`] || 0) : 0;
        const semesterTotal = boys + girls;
  
        return { ...acc, [semester]: semesterTotal };
      }, {});
    };
  
    const grandTotalWithSemesterTotal = {
      ...grandTotal,
      semesterTotal: calculateSemesterTotal(),
    };
  
    // Update state or perform other actions with grandTotalWithSemesterTotal
    setDegreeFourYears(grandTotalWithSemesterTotal);
    onuUpdateDegreeFourYears(grandTotalWithSemesterTotal);
  
  }, [selectedCategory, inputValues]);
  
  const handleInputChange = (semester, gender, value) => {
    setInputValues({
      ...inputValues,
      [`${semester}_${gender}`]: value,
    });
  };

  const generateInputFields = (semester) => {
    const boys = (selectedCategory === 'Boys' || selectedCategory === 'Co-Education') ? (inputValues[`${semester}_boys`] || 0) : 0;
    const girls = (selectedCategory === 'Girls' || selectedCategory === 'Co-Education') ? (inputValues[`${semester}_girls`] || 0) : 0;
    const semesterTotal = boys + girls;

    return (
      <div key={semester} className="m-4 border-8 p-4">
        <div className="border p-4">
          <div className="border-4 rounded-lg flex text-xl font-bold items-center justify-between p-4">
            <div className="text-xl font-bold">Semester {semester}</div>
            <div className='flex justify-between space-x-4'>
              <div>Total : </div>
              <div>{semesterTotal}</div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          {selectedCategory === 'Boys' && (
            <>
              <CustomInputBox
                label={`Semester ${semester} Boys`}
                value={boys}
                onChange={(e) => handleInputChange(semester, 'boys', parseInt(e.target.value, 10))}
              />
              <CustomInputBox
                label={`Semester ${semester} Total`}
                value={semesterTotal}
                disabled
              />
            </>
          )}

          {selectedCategory === 'Girls' && (
            <>
              <CustomInputBox
                label={`1st Semester Girls`}
                value={girls}
                onChange={(e) => handleInputChange(semester, 'girls', parseInt(e.target.value, 10))}
              />
              <CustomInputBox
                label={`Semester ${semester} Total`}
                value={semesterTotal}
                disabled
              />
            </>
          )}
        </div>

        <div className="grid grid-cols-3 gap-4">
          {selectedCategory === 'Co-Education' && (
            <>
              <CustomInputBox
                label={`Semester ${semester} Boys`}
                value={boys}
                onChange={(e) => handleInputChange(semester, 'boys', parseInt(e.target.value, 10))}
              />
              <CustomInputBox
                label={`Semester ${semester} Girls`}
                value={girls}
                onChange={(e) => handleInputChange(semester, 'girls', parseInt(e.target.value, 10))}
              />
              <CustomInputBox
                label={`Semester ${semester} Total`}
                value={semesterTotal}
                disabled
              />
            </>
          )}
        </div>
      </div>
    );
  };

  // Calculate overall total enrollment for all semesters
  // const overallTotal = semesters.reduce((acc, semester) => {
  //   const boys = (selectedCategory === 'Boys' || selectedCategory === 'Co-Education') ? (inputValues[`${semester}_boys`] || 0) : 0;
  //   const girls = (selectedCategory === 'Girls' || selectedCategory === 'Co-Education') ? (inputValues[`${semester}_girls`] || 0) : 0;
  //   const semesterTotal = boys + girls;
  //   return acc + semesterTotal;
  // }, 0);

  return (
    <div className="space-y-4">
      <div className="border p-4">
        <div className="border-4 rounded-lg flex text-xl font-bold items-center justify-between p-4">
          <div>Total Enrollment</div>
          <div>{degreeFourYears.overallTotal}</div>
        </div>
        {semesters.map((semester) => generateInputFields(semester))}
      </div>
    </div>
  );
};

export default DegreeFourYears;
