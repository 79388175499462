import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import CustomInputBox from "../../common/CustomInputBox/CustomInputBox";
import AuthUser from "../../../../../api/Api";

const Affiliation = ({ collegeId, https }) => {
  const { http } = AuthUser();
  const AFFILIATION_ENDPOINT = "affiliation";

  const [file, setFile] = useState(null);
  const [affiliation, setAffiliation] = useState("");
  const [filePreview, setFilePreview] = useState(null);
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false); // State for button disabled
  const [showInputFields, setShowInputFields] = useState(false); // State for showing input fields

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await http.get(`${AFFILIATION_ENDPOINT}/${collegeId}`);
      setFiles(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching affiliation data:", error);
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
  
    // Check file type
    const allowedTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];
    if (selectedFile && !allowedTypes.includes(selectedFile.type)) {
      setMessage('Invalid file type. Please select a JPG, JPEG, PNG, or SVG file.');
      setFile(null);
      setFilePreview(null);
      return;
    }
  
    setFile(selectedFile);
    const reader = new FileReader();
    reader.onload = (event) => {
      setFilePreview(event.target.result);
    };
    reader.readAsDataURL(selectedFile);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!file) {
        setMessage("Please select a file.");
        return;
      }

      // Disable the button to prevent multiple submissions
      setButtonDisabled(true);

      const formData = new FormData();
      formData.append("file", file);
      formData.append("affiliation", affiliation);

      const response = await http.post(AFFILIATION_ENDPOINT, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 201) {
        setMessage(response.data.message);
        setFile(null);
        setAffiliation("");
        setFilePreview(null);
        fetchData();
      } else {
        setMessage("An error occurred while processing the request.");
      }
    } catch (error) {
      setMessage("An error occurred while processing the request.");
      console.error(error);
    } finally {
      // Enable the button after form submission is completed (whether successful or failed)
      setButtonDisabled(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await http.delete(`${AFFILIATION_ENDPOINT}/${id}`);
      if (response.status === 200) {
        setMessage(response.data.message);
        fetchData();
      } else {
        setMessage("An error occurred while deleting the file.");
      }
    } catch (error) {
      setMessage("An error occurred while deleting the file.");
      console.error(error);
    }
  };

  const handleView = (filePath) => {
    window.open(`${https}${filePath}`);
  };

  return (
    <div className="md:p-6 mt-3">
      <div className="w-full border rounded-md">
        <div className="p-4">
          <div className="p-4 text-gray-600">
            <h1 className="text-lg font-semibold">
              Details of Affiliation with Board / University
            </h1>
          </div>
          {loading ? (
            <CircularProgress />
          ) : files.length > 0 ? (
            <table className="min-w-full divide-y mt-10 border divide-gray-200">
              <thead className="bg-blue-100">
                <tr>
                  <th
                    scope="col"
                    className="text-center px-6 py-3 text-sm font-medium text-gray-800 uppercase tracking-wider"
                  >
                    Affiliated Board / University Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {files.map((item) => (
                  <tr key={item.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item.affiliation}
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap">
                      {item.file_path && (
                        <button
                          onClick={() => handleView(item.file_path)}
                          className="px-3 py-1 bg-blue-500 text-white rounded mr-2 hover:bg-blue-600"
                        >
                          View
                        </button>
                      )}
                      <button
                        onClick={() => handleDelete(item.id)}
                        className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                      >
                        Delete
                        </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="mt-4">No records found.</p>
          )}
          <div className="py-4">

          <button
            className="py-2 px-4 bg-indigo-600 text-white rounded hover:bg-indigo-700"
            onClick={() => setShowInputFields(true)}
          >
            Add Affiliation Details
          </button>
          </div>
          {showInputFields && (
            <form onSubmit={handleSubmit}>
              <div className="flex mb-10 space-x-36">
                <CustomInputBox
                  label="Please Enter Affiliated Board / University Name"
                  name="affiliation"
                  type="text"
                  value={affiliation}
                  onChange={(e) => setAffiliation(e.target.value)}
                />
              </div>
              <div className="flex mb-10 space-x-36">
                <div>
                  <label
                    htmlFor="file"
                    className="font-bold text-gray-700"
                  >
                    Attach Affiliation Certificate:
                  </label>
                  <input
                    type="file"
                    id="file"
                    accept=".jpg, .jpeg, .png, .svg" // Specify allowed file types
                    onChange={handleFileChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
                {/* File preview */}
                {filePreview && (
                  <div className="mb-4">
                    <img
                      src={filePreview}
                      alt="File Preview"
                      className="max-w-xs h-auto"
                    />
                  </div>
                )}
              </div>
              <button
                type="submit"
                className="py-2 px-4 bg-indigo-600 text-white rounded hover:bg-indigo-700"
                disabled={buttonDisabled} // Disable the button when loading
              >
                {buttonDisabled ? (
                  <div className="flex items-center">
                    <CircularProgress
                      size={20}
                      color="inherit"
                      className="mr-2"
                    />
                    Please wait...
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          )}
          {message && (
            <p className="mt-4 text-green-500">{message}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Affiliation;
