import React from "react";

const IntermediateCourses = ({ collegeProfileData, handleChange }) => {
  const intermediateCourses = [
    { value: "Pre-Engineering", label: "Pre-Engineering" },
    { value: "Pre-Medical", label: "Pre-Medical" },
    { value: "Science General", label: "Science General" },
    { value: "Commerce", label: "Commerce" },
    { value: "Humanities", label: "Humanities" },
    { value: "Bio Technology", label: "Bio Technology" },
    { value: "Physical", label: "Physical Education" },
  ];

  const renderIntermediateCheckboxes = () => {
    return (
      <div className="flex space-x-20 justify-center">
        {intermediateCourses.map((course) => (
          <label key={course.value} className="flex items-center space-x-4">
            <input
              type="checkbox"
              name="college_selected_courses"
              value={course.value}
              checked={collegeProfileData.college_intermediate_courses?.includes(course.value)}
              onChange={handleChange}
            />
            <span>{course.label}</span>
          </label>
        ))}
      </div>
    );
  };

  return (
    <div className="md:p-6 mt-3">
      {renderIntermediateCheckboxes()}
    </div>
  );
};

export default IntermediateCourses;
