import React, { useState, useEffect, useRef } from 'react';
import { Paper, Typography, Grid, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
// import AuthUser from '../../../../../api/Api'

const Dashboard = ({ collegestudentData, collegestaffData, collegeregistrationData }) => {
  console.log("Props:", collegestudentData, collegestaffData, collegeregistrationData);
  // const { http, collegeId } = AuthUser();
  const Get_DASHBOARD = 'college-dashboard';
  // Remove useState declarations for studentData, teachingstaffData, registrationData, and nonteachingstaffData
  const [studentData, setStudentData] = useState('');
  const [teachingstaffData, setTeachingstaffData] = useState('');
  const [registrationData, setRegistrationData] = useState('');
  // console.log("Registration", registrationData)
  const [nonteachingstaffData, setNonTeachingstaffData] = useState('');
  const [showAllStudentData, setShowAllStudentData] = useState(false);
  const [showAllTeachingData, setShowAllTeachingData] = useState(false);
  const [showAllNonTeachingData, setShowAllNonTeachingData] = useState(false);
  const [showAllRegistrationData, setShowAllRegistrationData] = useState(false);
  // const [data, setData] =useState('')
  const ref = useRef(null); // Ref to store the Paper component reference

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from API or props
        // For props, you don't need to use the 'http' object or 'collegeId' since the data is already provided as props
        // const response = await http.get(`${Get_DASHBOARD}/${collegeId}`);
        // Instead, you can directly use the props passed to the component
        // console.log("apidata", response);
        // const data = response.data?.data;

        // Set studentData from prop
        const totalEnrollment = collegestudentData[0]?.overall_total;
        const acadYear = collegestudentData[0]?.acadYear;
        const totalBoys = collegestudentData[0]?.total_boys;
        const totalGirls = collegestudentData[0]?.total_girls;
        // Set studentData state
        setStudentData({ totalEnrollment, totalBoys, totalGirls, acadYear });

        // Set teachingstaffData from prop
        const totalTeaching = collegestaffData?.teaching_count;
        const totalMaleTeaching = collegestaffData?.teaching_male_count;
        const totalFemaleTeaching = collegestaffData?.teaching_female_count;
        const totalFullTimeTeaching = collegestaffData?.teaching_full_time_count;
        const totalPartTimeTeaching = collegestaffData?.teaching_part_time_count;
        // Set teachingstaffData state
        setTeachingstaffData({ totalTeaching, totalMaleTeaching, totalFemaleTeaching, totalFullTimeTeaching, totalPartTimeTeaching });

        // Set nonteachingstaffData from prop
        const totalNonTeaching = collegestaffData?.non_teaching_count;
        const totalMaleNonTeaching = collegestaffData?.non_teaching_male_count;
        const totalFemaleNonTeaching = collegestaffData?.non_teaching_female_count;
        const totalFullTimeNonTeaching = collegestaffData?.non_teaching_full_time_count;
        const totalPartTimeNonTeaching = collegestaffData?.non_teaching_part_time_count;
        // Set nonteachingstaffData state
        setNonTeachingstaffData({ totalNonTeaching, totalMaleNonTeaching, totalFemaleNonTeaching, totalFullTimeNonTeaching, totalPartTimeNonTeaching });

        const renewalDate = collegeregistrationData?.renewal_date;
        const issuanceDate = collegeregistrationData?.issuance_date;
        const regno = renewalDate !== undefined ? collegeregistrationData.registration_no : collegeregistrationData?.fresh_registration_no;
        let reg_status;
        
        if (renewalDate !== undefined) {
          const dateValue = renewalDate !== undefined ? renewalDate : issuanceDate;
          const issuancedate = dateValue ? new Date(dateValue).toLocaleDateString('en-GB') : '';
          const period = collegeregistrationData?.valid_registration_period;
          const startdate = new Date(collegeregistrationData?.validity_date_wef).toLocaleDateString('en-GB');
          const enddate = new Date(collegeregistrationData?.validity_date_ends).toLocaleDateString('en-GB');
          reg_status = collegeregistrationData?.registration_status;
         
          // Set registrationData state with renewal registration details
          setRegistrationData({ regno, issuancedate, period, startdate, enddate, reg_status });
        } else {
          const issuancedate = new Date(issuanceDate).toLocaleDateString('en-GB');
          const period = collegeregistrationData?.valid_registration_period;
          const startdate = new Date(collegeregistrationData?.validity_date_wef).toLocaleDateString('en-GB');
          const enddate = new Date(collegeregistrationData?.validity_date_ends).toLocaleDateString('en-GB');
        
          // Set registrationData state with fresh registration details
          reg_status = collegeregistrationData?.registration_status;
          setRegistrationData({
            regno,
            fresh_registration_no: collegeregistrationData?.fresh_registration_no,
            issuancedate,
            period,
            startdate,
            enddate,
            reg_status,
          });
        }
        
     
       

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    fetchData();
  }, []); // Remove dependencies to avoid potential infinite loops caused by updating state variables that are used as dependencies. Since we're only fetching data once when the component mounts, we don't need any dependencies here.


  const registrationStatus = () => {
    if (registrationData === '') {
      return (
        <div className='underline text-blue-600'>
          <a href="#">Apply for Registration</a>
        </div>
      );
    } else {
      if (registrationData.reg_status === 'apply' || registrationData.reg_status === 'expired') {
        return (
          <>
            {registrationData.reg_status === 'expired' && <div>Expired</div>}
            <div className='underline text-blue-600'>
              <a href="#">Apply for Registration</a>
            </div>
          </>
        );
      } else {
        return 'Valid';
      }
    }
  };
  
  
  

  const collegeData = {
    totalEnrollment: studentData?.totalEnrollment,
    academicYear: studentData?.acadYear,
    totalTeachingStaff: teachingstaffData.totalTeaching,
    totalNonTeachingStaff: nonteachingstaffData.totalNonTeaching,
   RegistrationStatus: registrationStatus(),
  
    enrollmentgenderWise: [
      { gender: 'Boys', count: studentData.totalBoys },
      { gender: 'Girls', count: studentData.totalGirls },
    ],
    teachinggenderWise: [
      { gender: 'Male', count: teachingstaffData.totalMaleTeaching },
      { gender: 'Female', count: teachingstaffData.totalFemaleTeaching },
    ],
    teachingcategoryWise: [
      { category: 'Full time', count: teachingstaffData.totalFullTimeTeaching },
      { category: 'Part Time', count: teachingstaffData.totalPartTimeTeaching },
    ],
    nonteachinggenderWise: [
      { gender: 'Male', count: nonteachingstaffData.totalMaleNonTeaching },
      { gender: 'Female', count: nonteachingstaffData.totalFemaleNonTeaching },
    ],
    nonteachingcategoryWise: [
      { category: 'Full time', count: nonteachingstaffData.totalFullTimeNonTeaching },
      { category: 'Part Time', count: nonteachingstaffData.totalPartTimeNonTeaching },
    ],
    registrationdetails: [
      { category: 'Reg. No.', count: registrationData.regno },
      { category: 'Date of Iss.', count: registrationData.issuancedate },
    ],
    registrationvalidity: [
      { category: 'Reg. Valid For', count: `${registrationData.period} Years` },
      { category: 'W.e.f.', count:  `${registrationData.startdate} To ${registrationData.enddate}`},
    ],
  };
  console.log("college Data", collegeData)

// Event listener to handle clicks outside Paper components
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowAllStudentData(false);
        setShowAllTeachingData(false);
        setShowAllNonTeachingData(false);
        setShowAllRegistrationData(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);


  const handleToggle = (setter) => {
    setShowAllStudentData(false);
    setShowAllTeachingData(false);
    setShowAllNonTeachingData(false);
    setShowAllRegistrationData(false);
    setter((prevState) => !prevState);
  };

  return (
    <div>
      {/* <Typography variant="h4">Dashboard</Typography> */}
      <Grid container spacing={3} style={{ marginTop: '10px' }} justifyContent="center"> {/* Center the grid items horizontally */}
        {/* Total Enrollment */}
       <Grid item xs={12} sm={10} md={3}>
  <Paper
    ref={ref}
    elevation={6}
    style={{
      padding: '20px',
      textAlign: 'center',
      marginTop: '16px',
      cursor: 'pointer',
      height: '150px', // Set a fixed height for all Paper components
      overflowY: 'auto', // Add scrollbar if content overflows
      transition: 'height 0.5s ease', // Add transition for height change
      height: showAllStudentData ? '600px' : '200px', // Initial height based on showAll state
    }}
    onClick={() => handleToggle(setShowAllStudentData)}
    className={showAllStudentData ? 'hovered' : ''}
  >
    <div className='flex items-center justify-between'>

    <Typography variant="h5">Total Enrollment : </Typography>
    <Typography variant="h5">{studentData?.totalEnrollment}
    </Typography>
    </div>
    <div className='flex mt-2 items-center justify-between'>

    <Typography variant="h5">Academic Session:</Typography>
<Typography variant="h5">
  {studentData?.acadYear && (
    `${studentData.acadYear}-${(studentData.acadYear % 100) + 1}`
  )}
</Typography>
    </div>
    {showAllStudentData && (
      <div className='mt-10'>
        <Typography variant="h6">Gender Wise Enrollment</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Gender</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collegeData.enrollmentgenderWise.map((item) => (
              <TableRow key={item.gender}>
                <TableCell>{item.gender}</TableCell>
                <TableCell>{item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    )}
  </Paper>
</Grid>

        {/* Teaching Staff */}
        <Grid item xs={12} sm={10} md={3}>
  <Paper
    ref={ref}
    elevation={6}
    style={{
      padding: '20px',
      textAlign: 'center',
      marginTop: '16px',
      cursor: 'pointer',
      height: '150px', // Set a fixed height for all Paper components
      overflowY: 'auto', // Add scrollbar if content overflows
      transition: 'height 0.5s ease', // Add transition for height change
      height: showAllTeachingData ? '600px' : '200px', // Initial height based on showAll state
    }}
    onClick={() => handleToggle(setShowAllTeachingData)}
    className={showAllTeachingData ? 'hovered' : ''}
  >
    <Typography variant="h6">Total Teaching Staff</Typography>
    <Typography variant="h4">{collegeData?.totalTeachingStaff}</Typography>
    {showAllTeachingData && (
      <>
      <div className='mt-10'>
        <Typography variant="h6">Gender Wise</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Gender</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collegeData.teachinggenderWise.map((item) => (
              <TableRow key={item.gender}>
                <TableCell>{item.gender}</TableCell>
                <TableCell>{item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className='mt-10'>
        <Typography variant="h6">Category Wise</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collegeData.teachingcategoryWise.map((item) => (
              <TableRow key={item.category}>
                <TableCell>{item.category}</TableCell>
                <TableCell>{item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      </>
    )}
  </Paper>
</Grid>

        {/* Non-Teaching Staff */}
        <Grid item xs={12} sm={10} md={3}>
  <Paper
    ref={ref}
    elevation={6}
    style={{
      padding: '20px',
      textAlign: 'center',
      marginTop: '16px',
      cursor: 'pointer',
      height: '150px', // Set a fixed height for all Paper components
      overflowY: 'auto', // Add scrollbar if content overflows
      transition: 'height 0.5s ease', // Add transition for height change
      height: showAllNonTeachingData ? '600px' : '200px', // Initial height based on showAll state
    }}
    onClick={() => handleToggle(setShowAllNonTeachingData)}
    className={showAllNonTeachingData ? 'hovered' : ''}
  >
    <Typography variant="h6">Total Non Teaching Staff</Typography>
    <Typography variant="h4">{collegeData?.totalNonTeachingStaff}</Typography>
    {showAllNonTeachingData && (
      <>
      <div className='mt-10'>
        <Typography variant="h6">Gender Wise</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Gender</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collegeData.nonteachinggenderWise.map((item) => (
              <TableRow key={item.gender}>
                <TableCell>{item.gender}</TableCell>
                <TableCell>{item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className='mt-10'>
        <Typography variant="h6">Category Wise</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collegeData.nonteachingcategoryWise.map((item) => (
              <TableRow key={item.category}>
                <TableCell>{item.category}</TableCell>
                <TableCell>{item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      </>
    )}
  </Paper>
</Grid>
{/* Non-Teaching Staff */}
<Grid item xs={12} sm={10} md={3}>
  <Paper
    ref={ref}
    elevation={6}
    style={{
      padding: '20px',
      textAlign: 'center',
      marginTop: '16px',
      cursor: 'pointer',
      height: '150px', // Set a fixed height for all Paper components
      overflowY: 'auto', // Add scrollbar if content overflows
      transition: 'height 0.5s ease', // Add transition for height change
      height: showAllRegistrationData ? '600px' : '200px', // Initial height based on showAll state
    }}
    onClick={() => handleToggle(setShowAllRegistrationData)}
    className={showAllRegistrationData ? 'hovered' : ''}
  >
    <Typography variant="h6">Registration Status</Typography>
    <Typography variant="h4">{collegeData?.RegistrationStatus}</Typography>
    {showAllRegistrationData && (
      <>
      <div className='mt-10'>
      <Typography variant="h6"className='py-4 underline'>Registration Details</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* <TableCell>Gender</TableCell> */}
                    {/* <TableCell>Count</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {collegeData.registrationdetails.map((item) => (
                    <TableRow key={item.category}>
                      <TableCell>{item.category}</TableCell>
                      <TableCell>{item.count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
      </div>
      <div className='mt-10'>
      <Typography variant="h6" className='py-4 underline'>Registration Validation</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* <TableCell>Gender</TableCell> */}
                    {/* <TableCell>Count</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {collegeData.registrationvalidity.map((item) => (
                    <TableRow key={item.category}>
                      <TableCell>{item.category}</TableCell>
                      <TableCell>{item.count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
      </div>
      </>
    )}
  </Paper>
</Grid>
        
      </Grid>
    </div>
  );
};

export default Dashboard;
