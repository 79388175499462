// RegistrationData.js

import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import AuthUser from '../../../api/Api';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './RegistrationData.css'; // Import custom CSS

const GET_COLLEGES_URL = 'college-users';

const RegistrationData = () => {
  const { http } = AuthUser();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await http.get(GET_COLLEGES_URL);
      const fetchedData = response.data.data.map((item, index) => ({
        ...item,
        serialNumber: index + 1, // Add serial number starting from 1
      }));
      setData(fetchedData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data');
      setLoading(false);
    }
  };
  console.log('Colleges', data);

  const columns = [
    { field: 'serialNumber', headerName: 'S. No.', width: 70 },
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'collegeName', headerName: 'College Name', width: 300 },
    { field: 'collegeRegion', headerName: 'Region', width: 150 },
    { field: 'principalName', headerName: 'Principal Name', width: 200 },
    { field: 'principalMobile', headerName: 'Principal Mobile', width: 150 },
    // { field: 'isRegisteredInCollege', headerName: 'Fresh Registration Certificate Upload', width: 70 },
    { field: 'isRegisteredInCollege', headerName: 'Fresh Registration Certificate Upload', width: 70 },
    { field: 'isRegisteredInRenewal', headerName: 'Renewal Registration Certificate Upload Status', width: 70 },
    { field: 'profileLink', headerName: 'View Profile', width: 150, renderCell: (params) => <Link to={`profile/${params.id}`}>View Profile</Link> },
    // Add more fields as needed
  ];

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <Box sx={{ width: '100%', padding: '4px', marginTop: '5px' }}>
      <Paper elevation={5}>
        <div className="p-6 underline">
          <Typography variant="h4">Private Colleges</Typography>
        </div>
        <div className="px-6 py-2" style={{ height: "70vh" }}>
          <DataGrid
            rows={data}
            columns={columns}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </div>
      </Paper>
    </Box>
  );
};

export default RegistrationData;
