

import React, { useState, useEffect } from 'react';
import CustomInputBox from '../../common/CustomInputBox/CustomInputBox';

const DegreeTwoYears = ({degreeCourses, collegeCategory, onUpdateDegreeTwoYears}) => {
  const [selectedFaculties, setSelectedFaculties] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(''); // Default to boys
  const [inputValues, setInputValues] = useState({});
  const [degreeTwoYearsEnrollment, setDegreeTwoYearsEnrollment] = useState({
    overallTotal: 0,
    facultyTotal: 0,
    totalXI: 0,
    totalXII: 0,
    inputValues: 0,
  });

  useEffect(() => {
    // Check if intermediatecourses is an object with the expected properties
    if (degreeCourses && Array.isArray(degreeCourses)) {
      // Set selected faculties directly from intermediatecourses
      setSelectedFaculties(degreeCourses);
    } else {
      // console.error("intermediatecourses is not in the expected format:", intermediatecourses);
    }
  
    // Set selected category
    if (collegeCategory) {
      setSelectedCategory(collegeCategory);
    } else {
      console.error("collegecategory is not defined or empty:", collegeCategory);
    }
  
    // Log the selected category value
    console.log("selected category:", selectedCategory);
  
    // fetchData();
    console.log("Data :", degreeCourses, selectedCategory);
  }, [degreeCourses, collegeCategory]);


  useEffect(() => {
   

    const grandTotal = selectedFaculties.reduce((acc, faculty) => {
      const xiBoys = (selectedCategory === 'Boys' || selectedCategory === 'Co-Education') ? (inputValues[`${faculty}_XI_boys`] || 0) : 0;
      const xiGirls = (selectedCategory === 'Girls' || selectedCategory === 'Co-Education') ? (inputValues[`${faculty}_XI_girls`] || 0) : 0;
      const totalXI = xiBoys + xiGirls;

      const xiiBoys = (selectedCategory === 'Boys' || selectedCategory === 'Co-Education') ? (inputValues[`${faculty}_XII_boys`] || 0) : 0;
      const xiiGirls = (selectedCategory === 'Girls' || selectedCategory === 'Co-Education') ? (inputValues[`${faculty}_XII_girls`] || 0) : 0;
      const totalXII = xiiBoys + xiiGirls;

      const Total = totalXI + totalXII;
 // Accumulate the totals for boys and girls separately
 const boysTotal = xiBoys + xiiBoys;
 const girlsTotal= xiGirls + xiiGirls;

      const facultyData = {
        faculty,
        
  [`${faculty}_XI_Boys`]: xiBoys,
  [`${faculty}_XI_Girls`]: xiGirls,
  [`${faculty}_Total_XI`]: xiBoys + xiGirls,
  [`${faculty}_XII_Boys`]: xiiBoys,
  [`${faculty}_XII_Girls`]: xiiGirls,
  [`${faculty}Total_XII`]: xiiBoys + xiiGirls,
  [`${faculty}_Total`]: Total,
  [`${faculty}_boysTotal`]: xiBoys + xiiBoys,
  [`${faculty}_girlsTotal`]: xiGirls + xiiGirls,
      };

      return {
        overallTotal: acc.overallTotal + Total,
        totalBoys: acc.totalBoys + boysTotal ,
        totalGirls: acc.totalGirls + girlsTotal,
        facultyData: [...acc.facultyData, facultyData],
      };
    }, {
      overallTotal: 0,
      facultyData: [],
      totalBoys:0,
      totalGirls:0
    });

    setDegreeTwoYearsEnrollment(grandTotal);
    onUpdateDegreeTwoYears(grandTotal);
    // Display console value after update
    // console.log("Updated state:", grandTotal, inputValues);
  }, [selectedFaculties, selectedCategory, inputValues]);


  const handleInputChange = (faculty, type, gender, value) => {
    setInputValues({
      ...inputValues,
      [`${faculty}_${type}_${gender}`]: value,
    });
  };

  const generateInputFields = (faculty) => {
    const xiBoys = (selectedCategory === 'Boys' || selectedCategory === 'Co-Education') ? (inputValues[`${faculty}_XI_boys`] || 0) : 0;
    const xiGirls = (selectedCategory === 'Girls' || selectedCategory === 'Co-Education') ? (inputValues[`${faculty}_XI_girls`] || 0) : 0;
    const totalXI = xiBoys + xiGirls;
  
    const xiiBoys = (selectedCategory === 'Boys' || selectedCategory === 'Co-Education') ? (inputValues[`${faculty}_XII_boys`] || 0) : 0;
    const xiiGirls = (selectedCategory === 'Girls' || selectedCategory === 'Co-Education') ? (inputValues[`${faculty}_XII_girls`] || 0) : 0;
    const totalXII = xiiBoys + xiiGirls;
    const facultyTotal = totalXI + totalXII;
  
    return (
      <div key={faculty} className="m-4 border-8 p-4">
        {/* Overall Total at the top */}
        <div className="border p-4">
          <div className="border-4  rounded-lg flex text-xl font-bold items-center justify-between p-4">
            <div className="text-xl font-bold ">{faculty}</div>
            <div className='flex justify-between space-x-4'>

            <div>Total : </div>
            <div>{facultyTotal}</div>
            </div>
          </div>
        </div>
        {/* 1st Year ENROLLMENT */}
        <div className="grid grid-cols-2 gap-4">
          {selectedCategory === 'Boys' && (
            <>
              <CustomInputBox
                label="1st Year Boys"
                value={xiBoys}
                onChange={(e) => handleInputChange(faculty, 'XI', 'boys', parseInt(e.target.value, 10))}
              />
               <CustomInputBox
            label="Total 1st Year"
            value={totalXI}
            disabled
          />
            </>
          )}
          
          {selectedCategory === 'Girls' && (
            <>
              <CustomInputBox
                label="1st Year Girls"
                value={xiGirls}
                onChange={(e) => handleInputChange(faculty, 'XI', 'girls', parseInt(e.target.value, 10))}
              />
               <CustomInputBox
            label="Total 1st Year"
            value={totalXI}
            disabled
          />
            </>
          )}
          
          </div>
          <div className="grid grid-cols-3 gap-4">
          {selectedCategory === 'Co-Education' && (
            <>
              <CustomInputBox
                label="1st Year Boys"
                value={xiBoys}
                onChange={(e) => handleInputChange(faculty, 'XI', 'boys', parseInt(e.target.value, 10))}
              />
              <CustomInputBox
                label="1st Year Girls"
                value={xiGirls}
                onChange={(e) => handleInputChange(faculty, 'XI', 'girls', parseInt(e.target.value, 10))}
              />
               <CustomInputBox
            label="Total 1st Year"
            value={totalXI}
            disabled
          />
            </>
          )}
         
        </div>
        {/* 2nd Year ENROLLMENT */}
        <div className="grid grid-cols-2 gap-4">
          {selectedCategory === 'Boys' && (
            <>
              <CustomInputBox
                label="2nd Year Boys"
                value={xiiBoys}
                onChange={(e) => handleInputChange(faculty, 'XII', 'boys', parseInt(e.target.value, 10))}
              />
               <CustomInputBox
            label="Total 2nd Year"
            value={totalXII}
            disabled
          />
            </>
          )}
          {selectedCategory === 'Girls' && (
            <>
              <CustomInputBox
                label="2nd Year Girls"
                value={xiiGirls}
                onChange={(e) => handleInputChange(faculty, 'XII', 'girls', parseInt(e.target.value, 10))}
              />
               <CustomInputBox
            label="Total 2nd Year"
            value={totalXII}
            disabled
          />
            </>
          )}
        </div>
<div className="grid grid-cols-3 gap-4">
          {selectedCategory === 'Co-Education' && (
            <>
              <CustomInputBox
                label="2nd Year Boys"
                value={xiiBoys}
                onChange={(e) => handleInputChange(faculty, 'XII', 'boys', parseInt(e.target.value, 10))}
              />
              <CustomInputBox
                label="2nd Year Girls"
                value={xiiGirls}
                onChange={(e) => handleInputChange(faculty, 'XII', 'girls', parseInt(e.target.value, 10))}
              />
               <CustomInputBox
            label="Total 2nd Year"
            value={totalXII}
            disabled
          />
            </>
          )}
         
        </div>
      </div>
    );
  };

  // Calculate overall total enrollment for all faculties
  // Calculate overall total enrollment for all selected faculties
// const overallTotal = selectedFaculties.reduce((acc, faculty) => {
//   const xiBoys = (selectedCategory === 'Boys' || selectedCategory === 'Co-Education') ? (inputValues[`${faculty}_XI_boys`] || 0) : 0;
//   const xiGirls = (selectedCategory === 'girls' || selectedCategory === 'Co-Education') ? (inputValues[`${faculty}_XI_girls`] || 0) : 0;
//   const totalXI = xiBoys + xiGirls;

//   const xiiBoys = (selectedCategory === 'Boys' || selectedCategory === 'Co-Education') ? (inputValues[`${faculty}_XII_boys`] || 0) : 0;
//   const xiiGirls = (selectedCategory === 'girls' || selectedCategory === 'Co-Education') ? (inputValues[`${faculty}_XII_girls`] || 0) : 0;
//   const totalXII = xiiBoys + xiiGirls;

//   const facultyTotal = totalXI + totalXII;

//   return acc + facultyTotal;
// }, 0);


  return (
    <div className="space-y-4">
    
       
      {/* Faculty checkboxes */}
      {/* <div className="border p-4"> */}
        {/* <div className="flex justify-between px-48">
          {faculties.map((faculty) => (
            <div key={faculty} className="flex items-center">
              <input
                type="checkbox"
                id={faculty}
                checked={selectedFaculties.includes(faculty)}
                onChange={() => handleCheckboxChange(faculty)}
              />
              <label htmlFor={faculty} className="ml-2">{faculty}</label>
            </div>
          ))}
        </div> */}

         {/* Category radio buttons */}
        {/* <div className="flex justify-between px-48 mt-6">
          <div className="flex items-center">
            <input
              type="radio"
              id="boys"
              name="category"
              value="boys"
              checked={selectedCategory === 'boys'}
              onChange={() => handleCategoryChange('boys')}
            />
            <label htmlFor="boys" className="ml-2">Boys</label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              id="girls"
              name="category"
              value="girls"
              checked={selectedCategory === 'girls'}
              onChange={() => handleCategoryChange('girls')}
            />
            <label htmlFor="girls" className="ml-2">Girls</label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              id="Co-Education"
              name="category"
              value="Co-Education"
              checked={selectedCategory === 'Co-Education'}
              onChange={() => handleCategoryChange('Co-Education')}
            />
            <label htmlFor="Co-Education" className="ml-2">Co-education</label>
          </div>
        </div> */}
      {/* </div> */}
 {/* Overall Total at the top */}
 <div className="border p-4">
        <div className="border-4 rounded-lg flex text-xl font-bold items-center justify-between p-4">
          <div>Total Enrollment</div>
          <div>{degreeTwoYearsEnrollment.overallTotal}</div>
        </div>
      </div>
      {/* Display input fields for selected faculties */}
      {selectedFaculties.map((faculty) => generateInputFields(faculty))}
    </div>
    
  );
};

export default DegreeTwoYears;


