import React, { useState, useEffect } from "react";
// import { getCollegeGeneralInfo } from "../../../../../services/collegeService";
import { HiPencil } from "react-icons/hi";
import AuthUser from "../../../../../api/Api";

const CollegeBasicFacilities = ({ setEdit, collegeId }) => {
  const { http } = AuthUser();
  const GET_COLLEGE_BASIC_FACILITIES = "college-basicfacilities";
  const [collegeBasicFacilities, setCollegeBasicFacilities] = useState({});
  // const getMyCollegeBasicFacilities = async () => {
  //   const res = await getMyCollegeBasicFacilities();
  //   if (res?.data?.data) {
  //     setCollegeBasicFacilities(res?.data?.data);
  //   }
  // };

  // useEffect(() => {
  //   getMyCollegeBasicFacilities();
  // }, []);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const res = await http.get(
        `${GET_COLLEGE_BASIC_FACILITIES}/${collegeId}`
      );
      // console.log('API Response:', res);
      if (res?.data?.data) {
        setCollegeBasicFacilities(res.data.data[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(collegeId); // Pass collegeId as an argument to fetchData
  }, [collegeId]);

  return (
    <div className="p-6">
      <div className="w-full border rounded-md">
        <div className="p-4 text-gray-400 border-b bg-gray-100 flex justify-between items-center">
          <h1 className="text-lg font-semibold">Basic Facilites</h1>
          <div className="text-lg border border-blue-800 rounded-full cursor-pointer w-9 h-9 shadow-md bg-white flex justify-center items-center">
            <HiPencil
              className="text-blue-800 text-2xl"
              onClick={() => setEdit(true)}
            />
          </div>
        </div>

        <div className=" border-4 m-4 p-4">
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Purified Drinking Water Facility
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeBasicFacilities?.drinking_water_facility
                  ? collegeBasicFacilities?.drinking_water_facility
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>

        <div className=" border-4 m-4 p-4">
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Alternative Energy Source
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeBasicFacilities?.alternative_energy_source
                  ? collegeBasicFacilities?.alternative_energy_source
                  : "N/A"}
              </p>
            </div>
          </div>

          {collegeBasicFacilities?.alternative_energy_source === "Yes" && (
            <div className="md:flex p-4">
              {/* Render Area of Ground */}
              <div className="w-1/3">
                <h2 className="font-semibold text-gray-500 text-sm">
                  Type of Alternative Energy
                </h2>
              </div>
              <div className="w-2/3">
                <p className="text-sm text-blue-800 font-semibold">
                  {collegeBasicFacilities?.alternative_energy_type
                    ? collegeBasicFacilities?.alternative_energy_type
                    : "N/A"}
                </p>
              </div>
            </div>
          )}
          {collegeBasicFacilities?.alternative_energy_source === "Yes" &&
            collegeBasicFacilities?.alternative_energy_type === "others" && (
              <div className="md:flex p-4">
                {/* Render Area of Ground */}
                <div className="w-1/3">
                  <h2 className="font-semibold text-gray-500 text-sm">
                    Type of Alternative Source of Energy
                  </h2>
                </div>
                <div className="w-2/3">
                  <p className="text-sm text-blue-800 font-semibold">
                    {collegeBasicFacilities?.alternative_other_energy_type
                      ? collegeBasicFacilities?.alternative_other_energy_type
                      : "N/A"}
                  </p>
                </div>
              </div>
            )}
        </div>

        <div className=" border-4 m-4 p-4">
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                CCTV Camera
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeBasicFacilities?.cctv_camera
                  ? collegeBasicFacilities?.cctv_camera
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>

        <div className=" border-4 m-4 p-4">
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Fire Extinguisher
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeBasicFacilities?.fire_extinguisher
                  ? collegeBasicFacilities?.fire_extinguisher
                  : "N/A"}
              </p>
            </div>
          </div>

          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Emergency Exit
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeBasicFacilities?.emergency_exit
                  ? collegeBasicFacilities?.emergency_exit
                  : "N/A"}
              </p>
            </div>
          </div>

          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">First Aid</h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeBasicFacilities?.first_aid
                  ? collegeBasicFacilities?.first_aid
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className=" border-4 m-4 p-4">
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Ramp for Disable Persons
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeBasicFacilities?.ramp_for_disable_person
                  ? collegeBasicFacilities?.ramp_for_disable_person
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className=" border-4 m-4 p-4">
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Transport for Pick & Drop of Students by
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeBasicFacilities?.transport_pick_drop
                  ? collegeBasicFacilities?.transport_pick_drop
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollegeBasicFacilities;
