import React, { useState, useEffect } from "react";
import { FaFacebookF, FaYoutube, FaTwitter, FaInstagram } from "react-icons/fa";
import "./TopNav.css";

const TopNav = () => {
  // ... Your existing code ...

  return (
    <div className="top-nav-container">
      <div className="icon-group">
        <FaFacebookF className="icon-style" />
        <FaYoutube className="icon-style" />
        <FaTwitter className="icon-style" />
        <FaInstagram className="icon-style" />
      </div>
      <div className="contact-info">Hotline: 021-12345678</div>
    </div>
  );
};

export default TopNav;
