import React, { useRef, useState, useEffect } from "react";
import {TextField,  Button,  Container,  Typography,  Link as MuiLink,  Grid,  Paper,  FormControl,  InputLabel,  Select,  MenuItem,} from "@mui/material";
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthUser from "../api/Api";
import { Link as RouterLink } from "react-router-dom";
import PrimaryNavbar from '../PrimaryNavbar'
import { useNavigate} from 'react-router-dom';

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const REGISTER_URL = "register";
const GET_REGIONS_URL = "regions"
const Register = () => {
  // Refs
  const{http} = AuthUser();
  const collegeNameRef = useRef();
  const errRef = useRef();

  // College Name State
  const [collegeName, setCollegeName] = useState("");
  const [collegeRegion, setCollegeRegion] = useState("");
  const [validCollegeName, setValidCollegeName] = useState(false);
  const [collegeNameFocus, setCollegeNameFocus] = useState(false);

  // College Management State
  const [collegeManagedBy, setCollegeManagedBy] = useState("");
  const [showIndividualFields, setShowIndividualFields] = useState(false);
  const [showOrganizationFields, setShowOrganizationFields] = useState(false);

    // College Management State
  const [organisationName, setOrganisationName] = useState();
  const [organisationOwnedBy, setOrganisationOwnedBy] = useState();
  
  // Individual Fields State
  const [ownerName, setownerName] = useState("");
  const [ownerCnic, setownerCnic] = useState("");
  const [validownerCnic, setValidownerCnic] = useState(false);
  const [cnicFocus, setCnicFocus] = useState(false);

  const [ownerMobile, setownerMobile] = useState("");
  const [validownerMobile, setValidownerMobile] = useState(false);
  const [ownerMobileFocus, setownerMobileFocus] = useState(false);

    // Principal Fields State
  const [principalName, setprincipalName] = useState("");
  const [principalCnic, setprincipalCnic] = useState("");
  const [validprincipalCnic, setValidprincipalCnic] = useState(false);
  const [principalCnicFocus, setprincipalCnicFocus] = useState(false);
  
  const [principalMobile, setprincipalMobile] = useState("");
  const [validprincipalMobile, setValidprincipalMobile] = useState(false);
  const [principalMobileFocus, setprincipalMobileFocus] = useState(false);

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [EmailFocus, setEmailFocus] = useState(false);
  // Password State
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);

  // Confirm Password State
  const [matchPassword, setMatchPassword] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

   // React Router hook for navigation
   const navigate = useNavigate();

  // Error and Success State
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    collegeNameRef.current.focus();
  }, []);

  useEffect(() => {
    const COLLEGE_NAME_REGEX = /^[A-Za-z\s]{3,}$/;
    setValidCollegeName(COLLEGE_NAME_REGEX.test(collegeName));
  }, [collegeName]);


  useEffect(() => {
    // Toggle showing fields based on the selected value
    setShowIndividualFields(collegeManagedBy === "INDIVIDUAL");
    setShowOrganizationFields(collegeManagedBy === "ORGANISATION");
  }, [collegeManagedBy]);

  useEffect(() => {
    const CNIC_REGEX = /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/;
    setValidownerCnic(CNIC_REGEX.test(ownerCnic));
  }, [ownerCnic]);

  useEffect(() => {
    const MOBILE_NUMBER_REGEX = /^(03[0-9]{2}-[0-9]{7})$/;
    setValidownerMobile(MOBILE_NUMBER_REGEX.test(ownerMobile));
  }, [ownerMobile]);

  useEffect(() => {
    const MOBILE_NUMBER_REGEX = /^(03[0-9]{2}-[0-9]{7})$/;
    setValidprincipalMobile(MOBILE_NUMBER_REGEX.test(principalMobile));
  }, [principalMobile]);

  useEffect(() => {
    const CNIC_REGEX = /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/;
    setValidprincipalCnic(CNIC_REGEX.test(principalCnic));
  }, [principalCnic]);

  useEffect(() => {
    const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
    setValidMatch(password === matchPassword);
  }, [password, matchPassword]);


//   const fetchData = async () => {
//     try {
//       const response = await http.get(GET_REGIONS_URL);
//       // console.log('Response Data:', response.data);
  
//       const formattedData = response.data.data.map((item) => ({
//         value: item.name,
//         label: item.name,
//       }));
//       // console.log('Formatted Data:', formattedData);
  
//       setCollegeRegion(formattedData);
//     } catch (error) {
//       // console.error('Error fetching data:', error);
//       setError('Error fetching data');
//     } finally {
//     //   setLoading(false);
//     }

    
//   };


  const [isLoading, setIsLoading] = useState(false);

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
  
    // Basic validations
    const isCollegeNameValid = validCollegeName;
    const isPasswordValid = PWD_REGEX.test(password);
  
    // Additional validation logic based on the selected value
    let additionalValidation = true;
    if (collegeManagedBy === "INDIVIDUAL") {
      additionalValidation = ownerName && ownerCnic && ownerMobile && principalName && principalCnic && principalMobile && email;
    } else if (collegeManagedBy === "ORGANISATION") { // Updated condition to "ORGANISATION"
      additionalValidation = organisationName && organisationOwnedBy && principalName && principalCnic && principalMobile && email;
    }
  
    if (!isCollegeNameValid || !isPasswordValid || !additionalValidation) {
      setErrMsg("Invalid Entry");
      setIsLoading(false); // Stop loading
      return;
    }
  
    // Create the form data object with fields set to null if empty
    const formData = {
      collegeName,
      collegeRegion,
      password,
      collegeManagedBy,
      ownerName: collegeManagedBy === "INDIVIDUAL" ? ownerName || null : null,
      ownerCnic: collegeManagedBy === "INDIVIDUAL" ? ownerCnic || null : null,
      ownerMobile: collegeManagedBy === "INDIVIDUAL" ? ownerMobile || null : null,
      organisationName: collegeManagedBy === "ORGANISATION" ? organisationName || null : null, // Updated condition to "ORGANISATION"
      organisationOwnedBy: collegeManagedBy === "ORGANISATION" ? organisationOwnedBy || null : null, // Updated condition to "ORGANISATION"
      principalName,
      principalCnic,
      principalMobile,
      email,
    };
  
    try {
      // Send the data to the server
      const response = await http.post(REGISTER_URL, formData);
  
      // Handle success
      // console.log("Registration successful:", response.data);
      navigate('/login');
      setSuccess(true);
      setCollegeName("");
      setPassword("");
      setMatchPassword("");
      // Reset other form fields here
    } catch (err) {
      // Handle errors
      if (!err.response) {
        setErrMsg("No Server Response");
      } else if (err.response.status === 409) {
        setErrMsg("College Name Taken");
      } else {
        setErrMsg("Registration Failed");
      }
  
      // Focus on the error reference
      //   errRef.current.focus();
    }
  
    setIsLoading(false); // Stop loading
  };
  
  

  return (

<>

<PrimaryNavbar />

<Container maxWidth="sm" style={{ marginTop: "40px" }}>
      
      <Paper elevation={20} style={{ padding: "20px" }}>
        
        <Typography variant="h4" align="center" gutterBottom>
          Create an Account of Your College!
        </Typography>
        
        <form onSubmit={handleSubmit}>



          <Grid container spacing={2} align="left" gutterBottom
          > 

<Grid item xs={12}>
  
              <FormControl fullWidth >
                <InputLabel>Select Region</InputLabel>
                <Select
                  value={collegeRegion}
                  onChange={(e) => setCollegeRegion(e.target.value)}
                  >
          <MenuItem value="KARACHI">KARACHI</MenuItem>
          <MenuItem value="HYDERABAD">HYDERABAD</MenuItem>
          <MenuItem value="LARKANA">LARKANA</MenuItem>
          <MenuItem value="MIRPURKHAS">MIRPURKHAS</MenuItem>
          <MenuItem value="SHAHEEDBENAZIRABAD">SHAHEEDBENAZIRABAD</MenuItem>
          <MenuItem value="SUKKUR">SUKKUR</MenuItem>
                    
                </Select>
              </FormControl>
            </Grid>
            {/* College Name */}
            <Grid item xs={12}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <TextField
        fullWidth
        id="collegeName"
        label="College Name"
        placeholder="As per Reg. Certificate / Name of College Needs to be registered."
        variant="outlined"
        inputRef={collegeNameRef}
        autoComplete="off"
        onChange={(e) => setCollegeName(e.target.value.toUpperCase())} // Convert to uppercase
        value={collegeName}
        required
        error={!validCollegeName && collegeName.length > 0}
        onFocus={() => setCollegeNameFocus(true)}
        onBlur={() => setCollegeNameFocus(false)}
      />
      {validCollegeName && (
        <FontAwesomeIcon icon={faCheck} color="green" style={{ marginLeft: 8 }} />
      )}
    </div>
  </Grid>
  

            {/* College Managed By */}
            <Grid item xs={12}>
              
              <FormControl fullWidth >
                <InputLabel>College Managed by</InputLabel>
                <Select
                  value={collegeManagedBy}
                  onChange={(e) => setCollegeManagedBy(e.target.value)}
                  >
                  <MenuItem value="INDIVIDUAL">INDIVIDUAL</MenuItem>
                  <MenuItem value="ORGANISATION">
                    ORGANISATION
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Individual Fields */}
            {showIndividualFields && (
              <>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="ownerName"
                    label="Name of Owner"
                    variant="outlined"
                    onChange={(e) => setownerName(e.target.value)}
                    value={ownerName}
                    required
                    />
                </Grid>
                <Grid item xs={12}>
                <div style={{ display: "flex", alignItems: "center" }}>
  <TextField
    fullWidth
    id="cnic"
    label="CNIC No. of Owner"
    placeholder="12345-1234567-1"
    variant="outlined"
    onChange={(e) => {
      // Remove non-numeric and non-hyphen characters
      let numericValue = e.target.value.replace(/[^0-9-]/g, '');
      
      // Limit the input to 15 characters
      if (numericValue.length > 15) {
        numericValue = numericValue.slice(0, 15);
      }
      
      setownerCnic(numericValue);
    }}
    value={ownerCnic}
    required
    onFocus={() => setCnicFocus(true)}
    onBlur={() => setCnicFocus(false)}
    error={!validownerCnic && ownerCnic.length > 0}/>
    {
      validownerCnic && (
        <FontAwesomeIcon icon={faCheck} color="green" style={{ marginLeft: 8 }} />
        )
      }
      </div>
</Grid>

<Grid item xs={12}>
<div style={{ display: "flex", alignItems: "center" }}>
  <TextField
    fullWidth
    id="ownerMobile"
    label="Mobile No. of Owner"
    placeholder="0300-1234567"
    variant="outlined"
    onChange={(e) => {
      // Remove non-numeric and non-hyphen characters
      let numericValue = e.target.value.replace(/[^0-9-]/g, '');
      
      // Limit the input to 12 characters
      if (numericValue.length > 12) {
        numericValue = numericValue.slice(0, 12);
      }
      
      setownerMobile(numericValue);
    }}
    value={ownerMobile}
    required
    error={!validownerMobile && ownerMobile.length > 0}
    onFocus={() => setownerMobileFocus(true)}
    onBlur={() => setownerMobileFocus(false)}
    />
    {
      validownerMobile && (
        <FontAwesomeIcon icon={faCheck} color="green" style={{ marginLeft: 8 }} />
        )
      }
      </div>

</Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="principalName"
                    label="Name of Principal / Administrator"
                    variant="outlined"
                    onChange={(e) => setprincipalName(e.target.value)}
                    value={principalName}
                    required
                    />
                </Grid>
                <Grid item xs={12}>
                <div style={{ display: "flex", alignItems: "center" }}>

  <TextField
    fullWidth
    id="principalCnic"
    label="CNIC No. of Principal / Administrator"
    placeholder="12345-1234567-1"
    variant="outlined"
    onChange={(e) => {
      // Remove non-numeric and non-hyphen characters
      let numericValue = e.target.value.replace(/[^0-9-]/g, '');
      
      // Limit the input to 15 characters
      if (numericValue.length > 15) {
        numericValue = numericValue.slice(0, 15);
      }
      
      setprincipalCnic(numericValue);
    }}
    value={principalCnic}
    required
onFocus={() => setprincipalCnicFocus(true)}
onBlur={() => setprincipalCnicFocus(false)}
    error={!validprincipalCnic && principalCnic.length > 0}/>

    {
       validprincipalCnic && (
        <FontAwesomeIcon icon={faCheck} color="green" style={{ marginLeft: 8 }} />
        ) 
      }
      </div>
</Grid>

                <Grid item xs={12}>
                <div style={{ display: "flex", alignItems: "center" }}>
  <TextField
    fullWidth
    id="principalMobile"
    label="Mobile No. of Principal / Administrator"
    placeholder="0300-1234567"
    variant="outlined"
    onChange={(e) => {
      // Remove non-numeric and non-hyphen characters
      const numericValue = e.target.value.replace(/[^0-9-]/g, '');
      
      // Limit the length of the numeric part of the mobile number
      const maxLength = 12; // Change this to your desired length
      const limitedValue = numericValue.slice(0, maxLength);
      
      setprincipalMobile(limitedValue);
    }}
    value={principalMobile}
    required
    error={!validprincipalMobile && principalMobile.length > 0}
    onFocus={() => setprincipalMobileFocus(true)}
    onBlur={() => setprincipalMobileFocus(false)}
    />
     {validprincipalMobile && (
        <FontAwesomeIcon icon={faCheck} color="green" style={{ marginLeft: 8 }} />
        ) 
      }
      </div>
</Grid>
                <Grid item xs={12}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    fullWidth
                    id="email"
                    label="Official Email ID"
                    placeholder="abc@mail.com"
                    variant="outlined"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                    error={!validEmail && email.length > 0}
                    onFocus={() => setEmailFocus(true)}
                    onBlur={() => setEmailFocus(false)}
/>
                    {
                    validEmail && (
                        <FontAwesomeIcon icon={faCheck} color="green" style={{ marginLeft: 8 }} />
                        )
                      }
                      </div>
                </Grid>
                            {/* Password */}
            <Grid item xs={12}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                id="password"
                label="Password"
                placeholder="Minimum 8 Characters as described below "
                type="password"
                variant="outlined"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
                error={!validPassword && password.length > 0}
                  onFocus={() => setPasswordFocus(true)}
                  onBlur={() => setPasswordFocus(false)}
                />
                {
                  validPassword && (
                    <FontAwesomeIcon icon={faCheck} color="green" style={{ marginLeft: 8 }} />
                    )
                  }
                  </div>
              <Typography
                sx={{
                  display: passwordFocus && !validPassword ? "block" : "none",
                  fontSize: "12px",
                  color: "red",
                  mt: 1,
                }}
                >
                <FontAwesomeIcon icon={faInfoCircle} /> 
                &nbsp;{/* Add a non-breaking space here */}
                Must include atleast 1 Uppercase , lowercase letters, a number, and a
                special character.<br />
                Allowed special characters:{" "}
                <span aria-label="exclamation mark">!</span>{" "}
                <span aria-label="at symbol">@</span>{" "}
                <span aria-label="hashtag">#</span>{" "}
                <span aria-label="dollar sign">$</span>{" "}
                <span aria-label="percent">%</span>
              </Typography>
            </Grid>

            {/* Confirm Password */}
            <Grid item xs={12}>
            <div style={{ display: "flex", alignItems: "center" }}>

              <TextField
                fullWidth
                id="confirm_password"
                label="Confirm Password"
                placeholder="Must match to password "
                type="password"
                variant="outlined"
                onChange={(e) => setMatchPassword(e.target.value)}
                value={matchPassword}
                required
                error={!validMatch && matchPassword.length > 0}
                onFocus={() => setMatchFocus(true)}
                onBlur={() => setMatchFocus(false)}
                />
                { validMatch && matchPassword && (
                    <FontAwesomeIcon icon={faCheck} color="green" style={{ marginLeft: 8 }} />
                    )
                  }
                  </div>
              <Typography
                sx={{
                  display: matchFocus && !validMatch ? "block" : "none",
                  fontSize: "12px",
                  color: "red",
                  mt: 1,
                }}
                >
                <FontAwesomeIcon icon={faInfoCircle} />
                &nbsp;{/* Add a non-breaking space here */}
                Must match the password input field.
              </Typography>
            </Grid>

              </>
            )}

            {/* Organization Fields */}
            {showOrganizationFields && (
              <>
                <Grid item xs={12}>
                  
                  <TextField
                    fullWidth
                    id="organisationName"
                    label="Name of Organisation"
                    variant="outlined"
                    onChange={(e) => setOrganisationName(e.target.value)}
                    value={organisationName}
                    required
                    />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="organisationOwnedBy"
                    label="Organisation owned by"
                    variant="outlined"
                    onChange={(e) => setOrganisationOwnedBy(e.target.value)}
                    value={organisationOwnedBy}
                    required
                    />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="principalName"
                    label="Name of Principal / Administrator"
                    variant="outlined"
                    onChange={(e) => setprincipalName(e.target.value)}
                    value={principalName}
                    required
                    />
                </Grid>
                <Grid item xs={12}>
                <div style={{ display: "flex", alignItems: "center" }}>

  <TextField
    fullWidth
    id="principalCnic"
    label="CNIC No. of Principal / Administrator"
    placeholder="12345-1234567-1"
    variant="outlined"
    onChange={(e) => {
      // Remove non-numeric and non-hyphen characters
      let numericValue = e.target.value.replace(/[^0-9-]/g, '');
      
      // Limit the input to 15 characters
      if (numericValue.length > 15) {
        numericValue = numericValue.slice(0, 15);
      }
      
      setprincipalCnic(numericValue);
    }}
    value={principalCnic}
    required
onFocus={() => setprincipalCnicFocus(true)}
onBlur={() => setprincipalCnicFocus(false)}
    error={!validprincipalCnic && principalCnic.length > 0}/>

    {
       validprincipalCnic && (
        <FontAwesomeIcon icon={faCheck} color="green" style={{ marginLeft: 8 }} />
        ) 
      }
      </div>
</Grid>

                <Grid item xs={12}>
                <div style={{ display: "flex", alignItems: "center" }}>
  <TextField
    fullWidth
    id="principalMobile"
    label="Mobile No. of Principal / Administrator"
    placeholder="0300-1234567"
    variant="outlined"
    onChange={(e) => {
      // Remove non-numeric and non-hyphen characters
      const numericValue = e.target.value.replace(/[^0-9-]/g, '');
      
      // Limit the length of the numeric part of the mobile number
      const maxLength = 12; // Change this to your desired length
      const limitedValue = numericValue.slice(0, maxLength);
      
      setprincipalMobile(limitedValue);
    }}
    value={principalMobile}
    required
    error={!validprincipalMobile && principalMobile.length > 0}
    onFocus={() => setprincipalMobileFocus(true)}
    onBlur={() => setprincipalMobileFocus(false)}
    />
     {validprincipalMobile && (
        <FontAwesomeIcon icon={faCheck} color="green" style={{ marginLeft: 8 }} />
        ) 
      }
      </div>
</Grid>
                <Grid item xs={12}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    fullWidth
                    id="email"
                    label="Official Email ID"
                    placeholder="abc@mail.com"
                    variant="outlined"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                    error={!validEmail && email.length > 0}
                    onFocus={() => setEmailFocus(true)}
                    onBlur={() => setEmailFocus(false)}
/>
                    {
                    validEmail && (
                        <FontAwesomeIcon icon={faCheck} color="green" style={{ marginLeft: 8 }} />
                        )
                      }
                      </div>
                </Grid>
                            {/* Password */}
            <Grid item xs={12}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                id="password"
                label="Password"
                placeholder="Minimum 8 Characters as described below "
                type="password"
                variant="outlined"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
                error={!validPassword && password.length > 0}
                  onFocus={() => setPasswordFocus(true)}
                  onBlur={() => setPasswordFocus(false)}
                />
                {
                  validPassword && (
                    <FontAwesomeIcon icon={faCheck} color="green" style={{ marginLeft: 8 }} />
                    )
                  }
                  </div>
              <Typography
                sx={{
                  display: passwordFocus && !validPassword ? "block" : "none",
                  fontSize: "12px",
                  color: "red",
                  mt: 1,
                }}
                >
                <FontAwesomeIcon icon={faInfoCircle} /> 
                &nbsp;{/* Add a non-breaking space here */}
                Must include atleast 1 Uppercase , lowercase letters, a number, and a
                special character.<br />
                Allowed special characters:{" "}
                <span aria-label="exclamation mark">!</span>{" "}
                <span aria-label="at symbol">@</span>{" "}
                <span aria-label="hashtag">#</span>{" "}
                <span aria-label="dollar sign">$</span>{" "}
                <span aria-label="percent">%</span>
              </Typography>
            </Grid>

            {/* Confirm Password */}
            <Grid item xs={12}>
            <div style={{ display: "flex", alignItems: "center" }}>

              <TextField
                fullWidth
                id="confirm_password"
                label="Confirm Password"
                placeholder="Must match to password "
                type="password"
                variant="outlined"
                onChange={(e) => setMatchPassword(e.target.value)}
                value={matchPassword}
                required
                error={!validMatch && matchPassword.length > 0}
                onFocus={() => setMatchFocus(true)}
                onBlur={() => setMatchFocus(false)}
                />
                { validMatch && matchPassword && (
                    <FontAwesomeIcon icon={faCheck} color="green" style={{ marginLeft: 8 }} />
                    )
                  }
                  </div>
              <Typography
                sx={{
                  display: matchFocus && !validMatch ? "block" : "none",
                  fontSize: "12px",
                  color: "red",
                  mt: 1,
                }}
                >
                <FontAwesomeIcon icon={faInfoCircle} />
                &nbsp;{/* Add a non-breaking space here */}
                Must match the password input field.
              </Typography>
            </Grid>

              </>
            )}

           {/* Submit Button */}
            <Grid item xs={12}>
              {/* <MuiLink component={RouterLink} to="/login"> */}

              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                disabled={!validCollegeName || !validPassword || !validMatch}
                >
                Sign Up
              </Button>
                  {/* </MuiLink> */}
            </Grid>
          </Grid>
        </form>
        <Typography variant="body1">
          Already have Created Account?{" "}
          <span className="line">
            <MuiLink component={RouterLink} to="/login">
              Login
            </MuiLink>
          </span>
        </Typography>
      </Paper>
    </Container>
                </>
  );
};

export default Register;

