import React, { useState, useEffect } from "react";
import { HiPencil } from "react-icons/hi";
import CircularProgress from "@mui/material/CircularProgress";
import AuthUser from "../../../../../api/Api";
import Divider from "@mui/material/Divider";

const RegDetails = ({ setEdit, collegeId }) => {
  const [registrations, setRegistrations] = useState([]);
  const [renewalregistrations, setRenewalRegistrations] = useState([]);
  const [affiliations, setAffiliations] = useState([]);
  // console.log("Registrations", registrations)
  const [loading, setLoading] = useState(true);
  const { http } = AuthUser();
  const GET_FRESH_REG = "fresh-registration";
  const GET_RENEW_REG = "renewal-registration";
  const AFFILIATION_ENDPOINT = "affiliation";
  const https = "http://backend.dirpc.form-seef.com";
  const fetchRegistrations = async () => {
    try {
      const response = await http.get(`${GET_FRESH_REG}/${collegeId}`);
      setRegistrations(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching registrations:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRegistrations();
  }, []);
  const fetchRenewalRegistrations = async () => {
    try {
      const response = await http.get(`${GET_RENEW_REG}/${collegeId}`);
      // console.log("Renewal", response),
      setRenewalRegistrations(response.data.data);
      setLoading(false);
      // console.log(renewalregistrations);
    } catch (error) {
      console.error("Error fetching registrations:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRenewalRegistrations();
  }, []);

  const fetchAffiliations = async () => {
    try {
      const response = await http.get(`${AFFILIATION_ENDPOINT}/${collegeId}`);
      // console.log("Affiliation", response);
      setAffiliations(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching registrations:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAffiliations();
  }, []);


  const handleViewCertificate = (filePath) => {
    window.open(`${https}${filePath}`);
  };

  return (
    <div className="p-6">
      <div className="w-full border rounded-md">
        <div className="p-4 text-gray-400 border-b bg-gray-100 flex justify-between items-center">
          <h1 className="text-lg font-semibold">
            Registration and Affiliation Details
          </h1>
          <div className="text-lg border border-blue-800 rounded-full cursor-pointer w-9 h-9 shadow-md bg-white flex justify-center items-center">
            <HiPencil
              className="text-blue-800 text-2xl"
              onClick={() => setEdit(true)}
            />
          </div>
        </div>
        <div className="border-4 m-4 p-4">
          {loading ? (
            
            <div className="flex justify-center">
            
              <CircularProgress />
            </div>
          ) : (
            <>
            <div className="p-4 text-gray-600">
            <h1 className="text-lg font-semibold underline">Fresh (First Time) Registration Details</h1>
          </div>

            <table className="min-w-full divide-y border divide-gray-200">
              <thead className="bg-blue-100 ">
                <tr>
                  <th
                    scope="col"
                    className=" text-center px-6 py-3 text-sm font-medium text-gray-800 uppercase tracking-wider"
                    >
                    Registration Certificate No.
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                    Date of Issuance
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                   Registration Valid Period
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                    Validity Date W.E.F
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                   Validity Date Ends On
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {registrations.map((registration) => (
                  <tr key={registration.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {registration.fresh_registration_no || "N/A"}
                    </td>
                    <td className="px-6 text-center py-4 whitespace-nowrap">
                      {registration.issuance_date || "N/A"}
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap">
                      {registration.valid_registration_period || "N/A"}
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap">
                      {registration.validity_date_wef || "N/A"}
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap">
                      {registration.validity_date_ends || "N/A"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {registration.file_path && (
                        <button
                        onClick={() =>
                          handleViewCertificate(registration.file_path)
                        }
                        className="text-indigo-600 hover:text-indigo-900"
                        >
                          View Certificate
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="mt-10 text-xl border b-20 bg-blue-600">

            <Divider />
            </div>
            <div className="p-4 text-gray-600">
            <h1 className="text-lg font-semibold underline">Renewal of Registration Details</h1>
          </div>

            <table className="min-w-full divide-y border divide-gray-200">
              <thead className="bg-blue-100 ">
                <tr>
                  <th
                    scope="col"
                    className=" text-center px-6 py-3 text-sm font-medium text-gray-800 uppercase tracking-wider"
                    >
                    Registration Certificate No.
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                    Date of Issuance
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                   Registration Valid Period
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                    Validity Date W.E.F
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                   Validity Date Ends On
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {renewalregistrations.map((registration) => (
                  <tr key={registration.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {registration.registration_no || "N/A"}
                    </td>
                    <td className="px-6 text-center py-4 whitespace-nowrap">
                      {registration.renewal_date || "N/A"}
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap">
                      {registration.valid_registration_period || "N/A"}
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap">
                      {registration.validity_date_wef || "N/A"}
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap">
                      {registration.validity_date_ends || "N/A"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {registration.file_path && (
                        <button
                        onClick={() =>
                          handleViewCertificate(registration.file_path)
                        }
                        className="text-indigo-600 hover:text-indigo-900"
                        >
                          View Certificate
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="mt-10 text-xl border b-20 bg-blue-600">

<Divider />
</div>
            <div className="p-4 text-gray-600">
            <h1 className="text-lg font-semibold underline">Details of Affiliation with Board / University</h1>
          </div>

            <table className="min-w-full divide-y border divide-gray-200">
              <thead className="bg-blue-100 ">
                <tr>
                  <th
                    scope="col"
                    className=" text-center px-6 py-3 text-sm font-medium text-gray-800 uppercase tracking-wider"
                    >
                    Affiliated Board / University Name
                  </th>
                 
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {affiliations.map((affiliation) => (
                  <tr key={affiliation.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {affiliation.affiliation || "N/A"}
                    </td>
                    
                    <td className="px-6 py-4 whitespace-nowrap">
                      {affiliation.file_path && (
                        <button
                        onClick={() =>
                          handleViewCertificate(affiliation.file_path)
                        }
                        className="text-indigo-600 hover:text-indigo-900"
                        >
                          View Certificate
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
                </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegDetails;
