import { Popover } from '@headlessui/react'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

const Home = () => {
  return (
    <div className='relative bg-white overflow-hidden'>
      <div className='max-w-7xl mx-auto'>
      <div className="relative bg-white z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
        <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
            >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <Popover>
            <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
              <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                  <div className="flex items-center justify-between w-full md:w-auto">
                    <a href="#">
                      <span className="sr-only">Workflow</span>
                      
                    </a>
                    
                  </div>
                </div>
                
              </nav>
            </div>
          </Popover>
          <div className="mx-auto max-w-7xl px-4 sm:mt-20 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-5">
                          <div className="sm:text-center lg:text-left">
                            <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-3xl text-center md:text-3xl">
                              <span className="block xl:inline">Welcome to </span>
                              <br/>
                              <span className="block text-green-800 xl:inline text-center">DIRECTORATE OF INSPECTION AND REGISTRATION OF PRIVATE COLLEGES <br /> SINDH @ KARACHI</span>
                              </h1>
                              <p className="mb-24 mt-10 text-base text-gray-500 sm:mt-5 sm:text-3xl sm:max-w-xl text-center sm:mx-auto md:mt-5 md:text-3xl lg:mx-0">College Education Department, <br /> Government of Sindh.</p>
                              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                              <div className="rounded-md shadow">
                  <RouterLink
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-900 md:py-4 md:text-lg md:px-10"
                    to="/login"
                  >
                    Login
                  </RouterLink>
                </div>
                
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <RouterLink
                    to="/register"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-sky-800 bg-sky-200 hover:bg-sky-400 md:py-4 md:text-lg md:px-10"
                  >
                    Register
                  </RouterLink>
                </div>
                        </div>
                        </div>
                      
                        </div>


        </div>
      </div>
<div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src="https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80"
          alt=""
          />
      </div>
    </div>
  )
}

export default Home
