import React from 'react'
import {Paper} from '@mui/material'
import StudentList from './StudentList'
// import NonTeachingStaff from './NonTeachingStaff'
const Stafflist = () => {
  return (
    <Paper elevation={3}>
      

        <StudentList />
      
        {/* <NonTeachingStaff /> */}
    </Paper>
  )
}

export default Stafflist
