import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbar, GridToolbarExport } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import AuthUser from '../../../../api/Api';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Paper from "@mui/material/Paper"
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useParams } from 'react-router-dom';

const GET_STAFF = 'allstaff';

const RegistrationData = () => {
  const { http } = AuthUser();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [openViewDialog, setOpenViewDialog] = useState(false); // State for viewing salary slip
  const [salarySlipUrl, setSalarySlipUrl] = useState(null); // State to hold the URL of the salary slip
  const {  } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await http.get(GET_STAFF);
      const formattedData = response.data.data.map((item, index) => ({
        ...item,
        serialNumber: index + 1,
        college_name: item.college ? item.college.college_name : 'N/A',
      }));
      setData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { field: 'serialNumber', headerName: 'S. No', width: 30 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'fatherName', headerName: 'Father Name', width: 150 },
    { field: 'gender', headerName: 'Gender', width: 70 },
    { field: 'dateOfBirth', headerName: 'Date of Birth', width: 100 },
    { field: 'cnic', headerName: 'CNIC No.', width: 150 },
    { field: 'qualification', headerName: 'Qualification', width: 150 },
    { field: 'dateOfAppointment', headerName: 'Date of Appointment', width: 150 },
    { field: 'staffCategory', headerName: 'Category', width: 150 },
    { field: 'teachingSubject', headerName: 'Teaching Subject', width: 150 },
    { field: 'designation', headerName: 'Designation', width: 100 },
    { field: 'college_name', headerName: 'College', width: 350 },
    { field: 'residentialAddress', headerName: 'Residential Address', width: 350 },
    { field: 'emailAddress', headerName: 'Email Address', width: 200 },
    { field: 'cellNo', headerName: 'Cell No.', width: 120 },
    { 
      field: 'category', 
      headerName: 'Category', 
      width: 100,
      renderCell: (params) => {
        if (params.value === 'Fulltime') return 'Full Time';
        if (params.value === 'Parttime') return 'Part Time';
        return params.value;
      }
    },
    { field: 'salaryDrawn', headerName: 'Salary Drawn', width: 100 },
    { field: 'netSalary', headerName: 'Net Salary', width: 100 },
    { field: 'bankaccount', headerName: 'Bank Account No.', width: 150 },
    {
      field: 'viewSalarySlip',
      headerName: 'View Salary Slip',
      width: 180,
      renderCell: (params) => (
        <IconButton color="primary" onClick={() => handleOpenViewDialog(params.id)}>
          <VisibilityIcon />
        </IconButton>
      )
    },
  ];

  const handleOpenViewDialog = async (id) => {
    setSelectedUserId(id);
    
    try {
      const response = await http.get(`/users/${id}/salary-slip`, { responseType: 'blob' });
      const url = URL.createObjectURL(new Blob([response.data]));
      setSalarySlipUrl(url);
      setOpenViewDialog(true);
    } catch (error) {
      console.error('Error fetching salary slip:', error);
    }
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    setSalarySlipUrl(null);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <>
      <Box sx={{ width: '100%', padding: '4px', marginTop: '5px' }}>
        <Paper elevation={5}>
          <div className='p-6 underline'>
            <Typography variant="h4">Staff List</Typography>
          </div>
          <div className='px-6 py-2' style={{height:"70vh"}}>
            <DataGrid
              rows={data}
              columns={columns}
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </div>
        </Paper>
      </Box>

      <Dialog open={openViewDialog} onClose={handleCloseViewDialog}>
        <DialogTitle>View Salary Slip</DialogTitle>
        <DialogContent>
          {salarySlipUrl ? (
            <iframe src={salarySlipUrl} title="Salary Slip" width="100%" height="600px" />
          ) : (
            <Typography>No salary slip available.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseViewDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RegistrationData;
