import React, { useState, useEffect } from 'react';
import CustomInputBox from '../../common/CustomInputBox/CustomInputBox';

const DegreeFourYears = ({ collegeCategory, onuUpdateDegreeFourYears }) => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    if (collegeCategory) {
      setSelectedCategory(collegeCategory);
    } else {
      console.error("collegeCategory is not defined or empty:", collegeCategory);
    }
  }, [collegeCategory]);

  useEffect(() => {
    const appeared = inputValues[`appeared`] || 0;
    const passed = inputValues[`passed`] || 0;
    const passPercentage = ((passed / appeared) * 100 || 0).toFixed(1);

    const degreePassPercentage = {
      appeared,
      passed,
      passPercentage,
    };

    onuUpdateDegreeFourYears({ degreePassPercentage: [degreePassPercentage] });
  }, [selectedCategory, inputValues]);

  const handleInputChange = (type, value) => {
    setInputValues({
      ...inputValues,
      [type]: value,
    });
  };

  const generateInputFields = () => {
    const appeared = inputValues[`appeared`] || 0;
    const passed = inputValues[`passed`] || 0;
    const passPercentage = ((passed / appeared) * 100 || 0).toFixed(1);

    return (
      <div className="m-4 border-8 p-4">
        <div className="grid grid-cols-3 gap-4">
          <>
            <CustomInputBox
              label={`Appeared`}
              value={appeared}
              onChange={(e) => handleInputChange('appeared', parseInt(e.target.value, 10))}
            />
            <CustomInputBox
              label={`Passed`}
              value={passed}
              onChange={(e) => handleInputChange('passed', parseInt(e.target.value, 10))}
            />
            <CustomInputBox
              label={`Pass Percentage`}
              value={passPercentage}
              disabled
            />
          </>
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-4 border p-4">
      {generateInputFields()}
    </div>
  );
};

export default DegreeFourYears;
