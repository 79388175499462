import React from "react";

const CustomRadioButton = ({ name, value, onChange, label, checked }) => {
  return (
    <div className="flex items-center mr-6">
      <input
        id={label}
        name={name}
        type="radio"
        value={value}
        onChange={onChange}
        checked={checked && checked}
        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
      />
      <label
        htmlFor={label}
        className="ml-3 block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
    </div>
  );
};

export default CustomRadioButton;
