import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AuthUser from '../../../api/Api';
import CircularProgress from '@mui/material/CircularProgress';
import './RegistrationData.css'; // Import custom CSS

const GET_REGISTRATIONS_URL = 'all-registrations';

const RegistrationData = () => {
  const { http } = AuthUser();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const https = "http://backend.dirpc.form-seef.com";
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await http.get(GET_REGISTRATIONS_URL);
      const { college_registrations, renewal_registrations } = response.data;
  
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      };
  
      const formattedData = [
        ...college_registrations.map((item, index) => ({
          id: index + 1,
          registrationNo: item.fresh_registration_no,
          dateOfIssuance: formatDate(item.issuance_date),
          nameOfCollege: item.college_general?.college_name || '',
          addressOfCollege: item.college_general?.college_address || '',
          collegeLevelFaculty: item.college_general?.college_level === 'Intermediate'
            ? `${item.college_general?.college_level}\n${item.college_general?.college_intermediate_courses}`
            : item.college_general?.college_level === 'Degree'
              ? 'Degree'
              : item.college_general?.college_level || '',
          collegeManagebBy: item.college_ownership?.college_owner || '',
          nameOfOrganization: item.college_ownership?.college_owner === 'individual'
            ? `${item.college_ownership?.individual_owner_name}\n${item.college_ownership?.individual_owner_cnic}`
            : item.college_ownership?.organisation_name || '',
          registrationType: 'Fresh',
          fromDate: item.validity_date_wef,
          toDate: item.validity_date_ends,
          totalValidityPeriod: `${item.valid_registration_period} years`,
          remarks: item.remarks || '', // Assuming there's a remarks field in the data
          certificate: item.file_path, // Assuming the file_path field contains the path to the certificate
        })),
        ...renewal_registrations.map((item, index) => ({
          id: college_registrations.length + index + 1,
          registrationNo: item.registration_no,
          dateOfIssuance: formatDate(item.renewal_date),
          nameOfCollege: item.college_general?.college_name || '',
          addressOfCollege: item.college_general?.college_address || '',
          collegeLevelFaculty: item.college_general?.college_level || '',
          collegeManagebBy: item.college_ownership?.college_owner || '',
          nameOfOrganization: item.college_ownership?.college_owner === 'individual'
            ? `${item.college_ownership?.individual_owner_name}\n${item.college_ownership?.individual_owner_cnic}`
            : item.college_ownership?.organisation_name || '',
          registrationType: 'Renewal',
          fromDate: item.validity_date_wef,
          toDate: item.validity_date_ends,
          totalValidityPeriod: `${item.valid_registration_period} years`,
          remarks: item.remarks || '', // Assuming there's a remarks field in the data
          certificate: item.file_path, // Assuming the file_path field contains the path to the certificate
        }))
      ];
  
      // Sort the formatted data by dateOfIssuance in ascending order
      const sortedData = formattedData.sort((a, b) => {
        const [dayA, monthA, yearA] = a.dateOfIssuance.split('-');
        const [dayB, monthB, yearB] = b.dateOfIssuance.split('-');
        const dateA = new Date(yearA, monthA - 1, dayA);
        const dateB = new Date(yearB, monthB - 1, dayB);
        return dateA - dateB;
      });
  
      // Add serial numbers starting from 1
      const dataWithSerialNumbers = sortedData.map((item, index) => ({
        ...item,
        serialNumber: index + 1,
      }));
  
      setData(dataWithSerialNumbers);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data');
      setLoading(false);
    }
  };
  

  const handleViewCertificate = (filePath) => {
    window.open(`${https}${filePath}`, '_blank');
  };

  const columns = [
    { field: 'serialNumber', headerName: 'S. No.', width: 50, cellClassName: 'wrap-text' },
    { field: 'registrationNo', headerName: 'Registration No.', width: 130, cellClassName: 'wrap-text' },
    { field: 'dateOfIssuance', headerName: 'Date of Issuance', width: 100, cellClassName: 'wrap-text' },
    { field: 'nameOfCollege', headerName: 'Name of College', width: 300, cellClassName: 'wrap-text' },
    { field: 'addressOfCollege', headerName: 'Address of College', width: 300, cellClassName: 'wrap-text' },
    { 
      field: 'collegeLevelFaculty', 
      headerName: 'College Level / Faculty', 
      width: 300,
      cellClassName: 'wrap-text', // Enable text wrapping
    },
    { field: 'collegeManagebBy', headerName: 'Individual / Organization', width: 150, cellClassName: 'wrap-text' },
    { field: 'nameOfOrganization', headerName: 'Individual with CNIC / Name of Organization', width: 250, cellClassName: 'wrap-text' },
    { field: 'registrationType', headerName: 'Registration Type (Fresh / Renewal)', width: 100, cellClassName: 'wrap-text' },
    { field: 'fromDate', headerName: 'W.E.F', width: 100, cellClassName: 'wrap-text' },
    { field: 'toDate', headerName: 'To', width: 100, cellClassName: 'wrap-text' },
    { field: 'totalValidityPeriod', headerName: 'Validity Period', width: 80, cellClassName: 'wrap-text' },
    { 
      field: 'certificate', 
      headerName: 'View Registration Certificate', 
      width: 200,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleViewCertificate(params.value)}
        >
          View Certificate
        </Button>
      ),
    },
  ];

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <Box sx={{ width: '100%', padding: '4px', marginTop: '5px' }}>
      <Paper elevation={5}>
        <div className='p-6 underline'>
          <Typography variant="h4">Registration Data</Typography>
        </div>
        <div className='px-6 py-2' style={{ height: "70vh" }}>
          <DataGrid
            rows={data}
            columns={columns}
            components={{
              Toolbar: GridToolbar,
            }}
            getRowClassName={() => 'wrap-text'}
          />
        </div>
      </Paper>
    </Box>
  );
};

export default RegistrationData;
