import React, { useState, useEffect, useRef } from 'react';
import { Paper, Typography, Grid, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import AuthUser from '../../../api/Api'

const Dashboard = () => {
  const { http, collegeId } = AuthUser();
  const Get_DASHBOARD = 'college-dashboard';
  const [studentData, setStudentData] = useState('');
  // console.log("Student", studentData);
  const [teachingstaffData, setTeachingstafData] = useState('');
  const [registrationData, setRegistrationData] = useState('');
  const [nonteachingstaffData, setNonTeachingstafData] = useState('');
  const [showAllStudentData, setShowAllStudentData] = useState(false);
  const [showAllTeachingData, setShowAllTeachingData] = useState(false);
  const [showAllNonTeachingData, setShowAllNonTeachingData] = useState(false);
  const [showAllRegistrationData, setShowAllRegistrationData] = useState(false);
  // const [data, setData] =useState('')
  const ref = useRef(null); // Ref to store the Paper component reference
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http.get(`${Get_DASHBOARD}/${collegeId}`);
        console.log("apidata", response);
        const data = response.data?.data;
        // console.log("FetchedData", data)
      



        const totalEnrollment = data.students[0].overall_total;
        const acadYear = data.students[0].acadYear;
        const totalBoys = data.students[0].total_boys;
        const totalGirls = data.students[0].total_girls;
        setStudentData({totalEnrollment, totalBoys, totalGirls, acadYear});

        const totalTeaching = data?.staff.teaching_count
        const totalMaleTeaching = data?.staff.teaching_male_count
        const totalFemaleTeaching = data?.staff.teaching_female_count
        const totalFullTimeTeaching = data?.staff.teaching_full_time_count
        const totalPartTimeTeaching = data?.staff.teaching_part_time_count
        setTeachingstafData({totalTeaching, totalMaleTeaching, totalFemaleTeaching, totalFullTimeTeaching, totalPartTimeTeaching })
        
        const totalNonTeaching = data?.staff.non_teaching_count
        const totalMaleNonTeaching = data?.staff.non_teaching_male_count
        const totalFemaleNonTeaching = data?.staff.non_teaching_female_count
        const totalFullTimeNonTeaching = data?.staff.non_teaching_full_time_count
        const totalPartTimeNonTeaching = data?.staff.non_teaching_part_time_count
        setNonTeachingstafData({totalNonTeaching, totalMaleNonTeaching, totalFemaleNonTeaching, totalFullTimeNonTeaching, totalPartTimeNonTeaching })
      
       const regno = data.registration.fresh_registration_no !== undefined ? data.registration.fresh_registration_no : data.registration.registration_no;
       const renewalDate = data.registration.renewal_date;
       const issuanceDate = data.registration.issuance_date;
       
       // Determine the date value based on which key exists
       const dateValue = renewalDate !== undefined ? renewalDate : issuanceDate;
       
       // Convert the date value to the appropriate format
       const issuancedate = dateValue ? new Date(dateValue).toLocaleDateString('en-GB') : '';
const period = data.registration.valid_registration_period;
const startdate = new Date(data.registration.validity_date_wef).toLocaleDateString('en-GB');
const enddate = new Date(data.registration.validity_date_ends).toLocaleDateString('en-GB');
const registration_Status = data.registration.status;
setRegistrationData({regno, issuancedate, period, startdate, enddate, registration_Status});

        // setRegistrationData({regno, issuancedate, period, startdate, enddate})  
        console.log("Registration", registrationData)
        // setData(response.data.data); // Assuming the API returns the necessary data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    fetchData();
  }, [Get_DASHBOARD]);


 
  
  const registrationStatus = () => {
    if (registrationData.registration_Status === 'Valid') {
      return 'Valid';
    } else if (registrationData.registration_Status === 'Expired') {
      return (
        <>
          Expired <br />
          <div className="underline text-blue-600">
            <a href="#">Apply for Registration</a>
          </div>
        </>
      );
    } else {
      return (
        <div className="underline text-blue-600">
          <a href="#">Apply for Registration</a>
        </div>
      );
    }
  };

  const collegeData = {
    totalEnrollment: studentData?.totalEnrollment,
    academicYear: studentData?.acadYear,
    totalTeachingStaff: teachingstaffData.totalTeaching,
    totalNonTeachingStaff: nonteachingstaffData.totalNonTeaching,
   RegistrationStatus: registrationStatus(),
  
    enrollmentgenderWise: [
      { gender: 'Boys', count: studentData.totalBoys },
      { gender: 'Girls', count: studentData.totalGirls },
    ],
    teachinggenderWise: [
      { gender: 'Male', count: teachingstaffData.totalMaleTeaching },
      { gender: 'Female', count: teachingstaffData.totalFemaleTeaching },
    ],
    teachingcategoryWise: [
      { category: 'Full time', count: teachingstaffData.totalFullTimeTeaching },
      { category: 'Part Time', count: teachingstaffData.totalPartTimeTeaching },
    ],
    nonteachinggenderWise: [
      { gender: 'Male', count: nonteachingstaffData.totalMaleNonTeaching },
      { gender: 'Female', count: nonteachingstaffData.totalFemaleNonTeaching },
    ],
    nonteachingcategoryWise: [
      { category: 'Full time', count: nonteachingstaffData.totalFullTimeNonTeaching },
      { category: 'Part Time', count: nonteachingstaffData.totalPartTimeNonTeaching },
    ],
    registrationdetails: [
      { category: 'Reg. No.', count: registrationData.regno },
      { category: 'Date of Iss.', count: registrationData.issuancedate },
    ],
    registrationvalidity: [
      { category: 'Reg. Valid For', count: `${registrationData.period} Years` },
      { category: 'W.e.f.', count:  `${registrationData.startdate} To ${registrationData.enddate}`},
    ],
  };
  // console.log("college Data", collegeData)

// Event listener to handle clicks outside Paper components
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowAllStudentData(false);
        setShowAllTeachingData(false);
        setShowAllNonTeachingData(false);
        setShowAllRegistrationData(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);


  const handleToggle = (setter) => {
    setShowAllStudentData(false);
    setShowAllTeachingData(false);
    setShowAllNonTeachingData(false);
    setShowAllRegistrationData(false);
    setter((prevState) => !prevState);
  };

  return (
    <div>
      <Typography variant="h4">Dashboard</Typography>
      <Grid container spacing={3} style={{ marginTop: '10px' }} justifyContent="center"> {/* Center the grid items horizontally */}
        {/* Total Enrollment */}
       <Grid item xs={12} sm={10} md={3}>
  <Paper
    ref={ref}
    elevation={6}
    style={{
      padding: '20px',
      textAlign: 'center',
      marginTop: '16px',
      cursor: 'pointer',
      height: '150px', // Set a fixed height for all Paper components
      overflowY: 'auto', // Add scrollbar if content overflows
      transition: 'height 0.5s ease', // Add transition for height change
      height: showAllStudentData ? '400px' : '150px', // Initial height based on showAll state
    }}
    onClick={() => handleToggle(setShowAllStudentData)}
    className={showAllStudentData ? 'hovered' : ''}
  >
    <div className='flex items-center justify-between'>

    <Typography variant="h5">Total Enrollment : </Typography>
    <Typography variant="h5">{studentData?.totalEnrollment}
    </Typography>
    </div>
    <div className='flex mt-2 items-center justify-between'>

    <Typography variant="h5">Academic Session:</Typography>
<Typography variant="h5">
  {studentData?.acadYear && (
    `${studentData.acadYear}-${(studentData.acadYear % 100) + 1}`
  )}
</Typography>
    </div>
    {showAllStudentData && (
      <div className='mt-10'>
        <Typography variant="h6">Gender Wise Enrollment</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Gender</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collegeData.enrollmentgenderWise.map((item) => (
              <TableRow key={item.gender}>
                <TableCell>{item.gender}</TableCell>
                <TableCell>{item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    )}
  </Paper>
</Grid>

        {/* Teaching Staff */}
        <Grid item xs={12} sm={10} md={3}>
  <Paper
    ref={ref}
    elevation={6}
    style={{
      padding: '20px',
      textAlign: 'center',
      marginTop: '16px',
      cursor: 'pointer',
      height: '150px', // Set a fixed height for all Paper components
      overflowY: 'auto', // Add scrollbar if content overflows
      transition: 'height 0.5s ease', // Add transition for height change
      height: showAllTeachingData ? '600px' : '150px', // Initial height based on showAll state
    }}
    onClick={() => handleToggle(setShowAllTeachingData)}
    className={showAllTeachingData ? 'hovered' : ''}
  >
    <Typography variant="h6">Total Teaching Staff</Typography>
    <Typography variant="h4">{collegeData?.totalTeachingStaff}</Typography>
    {showAllTeachingData && (
      <>
      <div className='mt-10'>
        <Typography variant="h6">Gender Wise</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Gender</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collegeData.teachinggenderWise.map((item) => (
              <TableRow key={item.gender}>
                <TableCell>{item.gender}</TableCell>
                <TableCell>{item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className='mt-10'>
        <Typography variant="h6">Category Wise</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collegeData.teachingcategoryWise.map((item) => (
              <TableRow key={item.category}>
                <TableCell>{item.category}</TableCell>
                <TableCell>{item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      </>
    )}
  </Paper>
</Grid>

        {/* Non-Teaching Staff */}
        <Grid item xs={12} sm={10} md={3}>
  <Paper
    ref={ref}
    elevation={6}
    style={{
      padding: '20px',
      textAlign: 'center',
      marginTop: '16px',
      cursor: 'pointer',
      height: '150px', // Set a fixed height for all Paper components
      overflowY: 'auto', // Add scrollbar if content overflows
      transition: 'height 0.5s ease', // Add transition for height change
      height: showAllNonTeachingData ? '600px' : '150px', // Initial height based on showAll state
    }}
    onClick={() => handleToggle(setShowAllNonTeachingData)}
    className={showAllNonTeachingData ? 'hovered' : ''}
  >
    <Typography variant="h6">Total Non Teaching Staff</Typography>
    <Typography variant="h4">{collegeData?.totalNonTeachingStaff}</Typography>
    {showAllNonTeachingData && (
      <>
      <div className='mt-10'>
        <Typography variant="h6">Gender Wise</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Gender</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collegeData.nonteachinggenderWise.map((item) => (
              <TableRow key={item.gender}>
                <TableCell>{item.gender}</TableCell>
                <TableCell>{item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className='mt-10'>
        <Typography variant="h6">Category Wise</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collegeData.nonteachingcategoryWise.map((item) => (
              <TableRow key={item.category}>
                <TableCell>{item.category}</TableCell>
                <TableCell>{item.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      </>
    )}
  </Paper>
</Grid>
{/* Non-Teaching Staff */}
<Grid item xs={12} sm={10} md={3}>
  <Paper
    ref={ref}
    elevation={6}
    style={{
      padding: '20px',
      textAlign: 'center',
      marginTop: '16px',
      cursor: 'pointer',
      height: '150px', // Set a fixed height for all Paper components
      overflowY: 'auto', // Add scrollbar if content overflows
      transition: 'height 0.5s ease', // Add transition for height change
      height: showAllRegistrationData ? '600px' : '150px', // Initial height based on showAll state
    }}
    onClick={() => handleToggle(setShowAllRegistrationData)}
    className={showAllRegistrationData ? 'hovered' : ''}
  >
    <Typography variant="h6">Registration Status</Typography>
    <Typography variant="h4">{collegeData?.RegistrationStatus}</Typography>
    {showAllRegistrationData && (
      <>
      <div className='mt-10'>
      <Typography variant="h6"className='py-4 underline'>Registration Details</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* <TableCell>Gender</TableCell> */}
                    {/* <TableCell>Count</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {collegeData.registrationdetails.map((item) => (
                    <TableRow key={item.category}>
                      <TableCell>{item.category}</TableCell>
                      <TableCell>{item.count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
      </div>
      <div className='mt-10'>
      <Typography variant="h6" className='py-4 underline'>Registration Validation</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* <TableCell>Gender</TableCell> */}
                    {/* <TableCell>Count</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {collegeData.registrationvalidity.map((item) => (
                    <TableRow key={item.category}>
                      <TableCell>{item.category}</TableCell>
                      <TableCell>{item.count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
      </div>
      </>
    )}
  </Paper>
</Grid>
        
      </Grid>
    </div>
  );
};

export default Dashboard;
