import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import TablePagination from '@mui/material/TablePagination';
import AuthUser from "../../../../api/Api";
import Pagination from '@mui/material/Pagination';

import {
  Paper,
  TextField,
  Button,
  FormControlLabel,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const AddStaff = () => {
  const { http, collegeId } = AuthUser();
  const POST_COLLEGE_Teaching_Staff = "teachingstaff";
  const Get_COLLEGE_Teaching_Staff = "teachingstaff";
  const [staffList, setStaffList] = useState([]);
  const [newStaff, setNewStaff] = useState({
    name: '',
    fatherName: '',
    gender: 'Select Gender',
    dateOfBirth: '',
    dateOfAppointment: '',
    cnic: '',
    qualification: '',
    staffCategory: 'Select Staff Category',
    teachingSubject: '',
    residentialAddress: '',
    emailAddress: '',
    cellNo: '',
    category: 'Select Category',
    salaryDrawn: 'Select Mode',
    designation: '',
    netSalary: '',
    bankaccount: '',
  });

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [editStaffId, setEditStaffId] = useState(null);

  // New state variables for previewing data
  const [isPreviewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [previewData, setPreviewData] = useState([]);

  const fetchStaffList = async () => {
    try {
      const response = await http.get(`${Get_COLLEGE_Teaching_Staff}/${collegeId}`);
      setStaffList(response.data.data);
      console.log('staff list:', staffList);
    } catch (error) {
      console.error('Error fetching staff list:', error);
    }
  };

  useEffect(() => {
    fetchStaffList();
  }, []);

  const addStaffToBackend = async () => {
    try {
      console.log('New Staff Data:', newStaff); // Log the new staff data
      if (editStaffId !== null) {
        await http.put(`${POST_COLLEGE_Teaching_Staff}/${editStaffId}`, newStaff);
        alert('Staff member updated successfully!');
      } else {
        await http.post(POST_COLLEGE_Teaching_Staff, newStaff);
        alert('Staff member added successfully!');
      }
  
      // Reset the new staff data after successful addition or update
      setNewStaff({
        name: '',
        fatherName: '',
        gender: 'Select Gender',
        dateOfBirth: '',
        dateOfAppointment: '',
        cnic: '',
        qualification: '',
        teachingSubject: '',
        residentialAddress: '',
        emailAddress: '',
        cellNo: '',
        category: 'Select Category',
        salaryDrawn: 'Select Mode',
        designation: '',
        netSalary: '',
        bankaccount: '',
      });
  
      // Fetch the updated staff list and close the dialog
      fetchStaffList();
      closeDialog();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        // Extract error messages from the response data
        const errorMessages = Object.values(error.response.data.errors).join('\n');
        alert('Error adding/updating staff:\n' + errorMessages); // Display specific error messages
      } else if (error.response && error.response.status === 422) {
        alert('Error adding/updating staff: Some fields are missing or in the wrong format.'); // Inform about missing or incorrect fields
      } else {
        console.error('Error adding/updating staff:', error);
        alert('Error adding/updating staff: ' + error.message); // Display generic error message in alert
      }
    }
  };
  
  

  const deleteStaffFromBackend = async (id) => {
    try {
      await http.delete(`${POST_COLLEGE_Teaching_Staff}/${id}`);
      alert('Staff member deleted successfully!');
      fetchStaffList();
    } catch (error) {
      console.error('Error deleting staff:', error);
    }
  };

  const editStaff = (id) => {
    const staffToEdit = staffList.find((staff) => staff.id === id);
    if (staffToEdit) {
      setNewStaff({ ...staffToEdit });
      setEditStaffId(id);
      openDialog();
    }
  };

  const handleDeleteStaff = (id) => {
    if (window.confirm('Are you sure you want to delete this staff member?')) {
      deleteStaffFromBackend(id);
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = event.target.value === -1 ? staffList.length : parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to the first page
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(staffList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'TeachingStaff');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(data, 'TeachingStaff.xlsx');
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setEditStaffId(null);
  };

  const handleStaffCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setNewStaff({
      ...newStaff,
      staffCategory: selectedCategory,
      teachingSubject: selectedCategory === 'Teaching' ? '' : newStaff.teachingSubject,
    });
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false });
        const formattedData = jsonData.map(row => {
          const formattedRow = { ...row };
          // Check each cell in the row
          Object.keys(formattedRow).forEach(key => {
            const value = formattedRow[key];
            // Convert number to date string if it represents a date
            if (!isNaN(value) && typeof value === 'number') {
              const dateValue = XLSX.SSF.format('yyyy-mm-dd', value);
              formattedRow[key] = dateValue;
            }
          });
          return formattedRow;
        });
        setPreviewData(formattedData);
        openPreviewDialog();
      };
      reader.readAsArrayBuffer(file);
    }
  };
  
  const formatDateToISO = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  };

  const formatDateFromISO = (isoString) => {
    if (!isoString) return '';
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const openPreviewDialog = () => {
    setPreviewDialogOpen(true);
  };

  const closePreviewDialog = () => {
    setPreviewDialogOpen(false);
  };

  const confirmUpload = async () => {
    console.log('Preview Data before upload:', previewData); // Log preview data before making the API call
    try {
      const response = await http.post(POST_COLLEGE_Teaching_Staff + '/bulk', { data: previewData });
      console.log('Response from server:', response); // Log the response from the server
  
      if (response.status === 201) {
        alert('Bulk data uploaded successfully!');
        fetchStaffList();
        closePreviewDialog();
      } else {
        console.log('Unexpected response status:', response.status); // Log unexpected response status
        alert('Unexpected response status: ' + response.status); // Display unexpected response status in alert
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessage = Object.entries(error.response.data.errors)
          .map(([key, value]) => `${key}: ${value}`)
          .join('\n');
        alert('Error uploading bulk data:\n' + errorMessage); // Display error message with missing or erroneous fields
      } else {
        console.error('Error uploading bulk data:', error); // Log any other errors caught during the process
        alert('Error uploading bulk data: ' + error.message); // Display generic error message in alert
      }
    }
  };
  

  

  return (
    <div className="container mx-auto px-4">
  <div elevation={3} className="p-4 w-full mx-auto">
      <div className='text-right p-4'>
        <Button variant="contained" color="primary" onClick={openDialog}>
          Add Staff
        </Button>
        <Button variant="outlined" color="primary" onClick={exportToExcel} style={{ marginLeft: '16px' }}>
          Export
        </Button>
        <input
          accept=".xlsx, .xls"
          style={{ display: 'none' }}
          id="raised-button-file"
          type="file"
          onChange={handleFileUpload}
        />
        <label htmlFor="raised-button-file">
          <Button variant="outlined" color="primary" component="span" style={{ marginLeft: '16px' }}>
            Upload Excel
          </Button>
        </label>
      </div>
     

      
      <Dialog
         className='p-18'
         open={isDialogOpen}
         onClose={closeDialog}
         disableBackdropClick
         fullWidth // Add fullWidth prop to make the dialog box wider
         maxWidth="sm" // Set the maximum width of the dialog box to small (sm) to ensure it remains responsive
       >  
        <DialogTitle className='text-center uppercase underline'>Add Teaching Staff</DialogTitle>
        <DialogContent>
          <div>

        <h1 className='font-semibold'>Name</h1>
          <TextField
            // label="Name"
            fullWidth
            margin="normal"
            value={newStaff.name}
            onChange={(e) => setNewStaff({ ...newStaff, name: e.target.value })}
            />
            </div>

            <div>
            <h1 className='font-semibold'>Father Name</h1>
          <TextField
            // label="Father's Name"
            fullWidth
            margin="normal"
            value={newStaff.fatherName}
            onChange={(e) => setNewStaff({ ...newStaff, fatherName: e.target.value })}
            />
            </div>
          {/* <div className='flex space-x-4 flex-1'> */}
            <div>
              <h1 className='font-semibold'>Date of Birth</h1>
              <TextField
                type="date"
                fullWidth
                margin="normal"
                value={newStaff.dateOfBirth}
                onChange={(e) => setNewStaff({ ...newStaff, dateOfBirth: e.target.value })}
              />
            </div>
            <div>
              <h1 className='font-semibold'>CNIC No.</h1>
              <TextField
                label="40001-1234567-1"
                fullWidth
                margin="normal"
                value={newStaff.cnic}
                onChange={(e) => setNewStaff({ ...newStaff, cnic: e.target.value })}
              />
            </div>
          {/* </div> */}
          {/* <div className='flex space-x-4 flex-1'> */}
            <div>
              <h1 className='font-semibold'>Gender</h1>
              <Select
                label="Gender"
                fullWidth
                margin="normal"
                value={newStaff.gender}
                onChange={(e) => setNewStaff({ ...newStaff, gender: e.target.value })}
              >
                <MenuItem value="Select Gender">Select Gender</MenuItem>
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </Select>
            </div>
            <div>
              <h1 className='font-semibold'>Date of Appointment</h1>
              <TextField
                type="date"
                fullWidth
                margin="normal"
                value={newStaff.dateOfAppointment}
                onChange={(e) => setNewStaff({ ...newStaff, dateOfAppointment: e.target.value })}
              />
            </div>
          {/* </div> */}
          {/* <div className='flex space-x-4 flex-1'> */}
            <div>
              <h1 className='font-semibold'>Qualification</h1>
              <TextField
                // label="Enter Qualification"
                fullWidth
                margin="normal"
                value={newStaff.qualification}
                onChange={(e) => setNewStaff({ ...newStaff, qualification: e.target.value })}
              />
            </div>
            <div className='mt-2'>
              <h1 className='font-semibold'>Staff Category</h1>
              <Select
                label="Staff Category"
                fullWidth
                margin="normal"
                value={newStaff.staffCategory}
                onChange={handleStaffCategoryChange}
              >
                <MenuItem value="Select Staff Category">Select Staff Category</MenuItem>
                <MenuItem value="Teaching">Teaching</MenuItem>
                <MenuItem value="Non-Teaching">Non-Teaching</MenuItem>
              </Select>
            </div>
          {/* </div> */}


          {newStaff.staffCategory === 'Teaching' && (
          <div className='mt-2 '>

          <h1 className='font-semibold'>Teaching Subject</h1>
          <TextField
              // label="Teaching Subject"
              fullWidth
              margin="normal"
              value={newStaff.teachingSubject}
              onChange={(e) => setNewStaff({ ...newStaff, teachingSubject: e.target.value })}
              />
              </div>
          )}
          <div>
          <h1 className='font-semibold'>Residential Address</h1>
          <TextField
            // label="Residential Address"
            fullWidth
            margin="normal"
            value={newStaff.residentialAddress}
            onChange={(e) => setNewStaff({ ...newStaff, residentialAddress: e.target.value })}
            />
            </div>
            <div>
            <h1 className='font-semibold'>Email Address</h1>
          <TextField
            label="abc@xyz.com"
            fullWidth
            margin="normal"
            value={newStaff.emailAddress}
            onChange={(e) => setNewStaff({ ...newStaff, emailAddress: e.target.value })}
            />
            </div>
            <div>
            <h1 className='font-semibold'>Cell No.</h1>
          <TextField
            label="0300-1234567"
            fullWidth
            margin="normal"
            value={newStaff.cellNo}
            onChange={(e) => setNewStaff({ ...newStaff, cellNo: e.target.value })}
            />
            </div>
          {/* <div className='flex space-x-4 flex-1'> */}
            <div>
              <h1 className='font-semibold'>Category</h1>
              <Select
                label="Category"
                fullWidth
                margin="normal"
                value={newStaff.category}
                onChange={(e) => setNewStaff({ ...newStaff, category: e.target.value })}
              >
                <MenuItem value="Select Category">Select Category</MenuItem>
                <MenuItem value="Fulltime">Full Time</MenuItem>
                <MenuItem value="Parttime">Part Time</MenuItem>
              </Select>
            </div>
            <div>
              <h1 className='font-semibold'>Salary Drawn</h1>
              <Select
                label="Salary Drawn"
                fullWidth
                margin="normal"
                value={newStaff.salaryDrawn}
                onChange={(e) => setNewStaff({ ...newStaff, salaryDrawn: e.target.value })}
              >
                <MenuItem value="Select Mode">Select Mode</MenuItem>
                <MenuItem value="Payroll">Pay Roll</MenuItem>
                <MenuItem value="Cheque">Cheque</MenuItem>
                <MenuItem value="Cash">Cash</MenuItem>
              </Select>
            </div>
          {/* </div> */}
          <div>

          <h1 className='font-semibold'>Designation</h1>          

          <TextField
            // label="Designation"
            fullWidth
            margin="normal"
            value={newStaff.designation}
            onChange={(e) => setNewStaff({ ...newStaff, designation: e.target.value })}
            />
            </div>
            <div>
            <h1 className='font-semibold'>Salary Per Month</h1>
          <TextField
            // label="Net Salary"
            fullWidth
            margin="normal"
            value={newStaff.netSalary}
            onChange={(e) => setNewStaff({ ...newStaff, netSalary: e.target.value })}
            />
            </div>
            <div>
            <h1 className='font-semibold'>Bank Account Details</h1>
          <TextField
            label="(IBAN NO. FORMAT PK36SCBL0000001123456702"
            fullWidth
            margin="normal"
            value={newStaff.bankaccount}
            onChange={(e) => setNewStaff({ ...newStaff, bankaccount: e.target.value })}
            />
            </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">Cancel</Button>
          <Button onClick={addStaffToBackend} color="primary">{editStaffId !== null ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
     
      <Dialog open={isPreviewDialogOpen} onClose={closePreviewDialog}>
        <DialogTitle>Preview Uploaded Data</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                {/* Render table headers dynamically based on preview data */}
                {previewData.length > 0 && Object.keys(previewData[0]).map((key) => (
                  <TableCell key={key}>{key}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {previewData.map((row, index) => (
                <TableRow key={index}>
                  {Object.values(row).map((value, cellIndex) => (
                    <TableCell key={cellIndex}>{value}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={closePreviewDialog} color="secondary">Cancel</Button>
          <Button onClick={confirmUpload} color="primary">Confirm Upload</Button>
        </DialogActions>
      </Dialog>
      <div className="overflow-x-auto">
  <Table className="min-w-full">
    <TableHead>
      <TableRow>
        <TableCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Serial No.</TableCell>
        <TableCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</TableCell>
        <TableCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Father's Name</TableCell>
        <TableCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Gender</TableCell>
        <TableCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date of Birth</TableCell>
        <TableCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">CNIC</TableCell>
        <TableCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Qualification</TableCell>
        <TableCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Staff Category</TableCell>
        <TableCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date of Appointment</TableCell>
        <TableCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Teaching Subject</TableCell>
        <TableCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Residential Address</TableCell>
        <TableCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email Address</TableCell>
        <TableCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cell No.</TableCell>
        <TableCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Category</TableCell>
        <TableCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Salary Drawn</TableCell>
        <TableCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Designation</TableCell>
        <TableCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Net Salary</TableCell>
        <TableCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Bank Account</TableCell>
        <TableCell className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {staffList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((staff, index) => (
        <TableRow key={staff.id}>
          <TableCell className="px-6 py-4 whitespace-nowrap">{index + 1}</TableCell>
          <TableCell className="px-6 py-4 whitespace-nowrap">{staff.name}</TableCell>
          <TableCell className="px-6 py-4 whitespace-nowrap">{staff.fatherName}</TableCell>
          <TableCell className="px-6 py-4 whitespace-nowrap">{staff.gender}</TableCell>
          <TableCell className="px-6 py-4 whitespace-nowrap">{staff.dateOfBirth}</TableCell>
          <TableCell className="px-6 py-4 whitespace-nowrap">{staff.cnic}</TableCell>
          <TableCell className="px-6 py-4 whitespace-nowrap">{staff.qualification}</TableCell>
          <TableCell className="px-6 py-4 whitespace-nowrap">{staff.staffCategory}</TableCell>
          <TableCell className="px-6 py-4 whitespace-nowrap">{staff.dateOfAppointment}</TableCell>
          <TableCell className="px-6 py-4 whitespace-nowrap">{staff.teachingSubject}</TableCell>
          <TableCell className="px-6 py-4 whitespace-nowrap">{staff.residentialAddress}</TableCell>
          <TableCell className="px-6 py-4 whitespace-nowrap">{staff.emailAddress}</TableCell>
          <TableCell className="px-6 py-4 whitespace-nowrap">{staff.cellNo}</TableCell>
          <TableCell className="px-6 py-4 whitespace-nowrap">{staff.category}</TableCell>
          <TableCell className="px-6 py-4 whitespace-nowrap">{staff.salaryDrawn}</TableCell>
          <TableCell className="px-6 py-4 whitespace-nowrap">{staff.designation}</TableCell>
          <TableCell className="px-6 py-4 whitespace-nowrap">{staff.netSalary}</TableCell>
          <TableCell className="px-6 py-4 whitespace-nowrap">{staff.bankaccount}</TableCell>
          <TableCell className="px-6 py-4 whitespace-nowrap">
            <IconButton onClick={() => editStaff(staff.id)}><EditIcon /></IconButton>
            <IconButton onClick={() => handleDeleteStaff(staff.id)}><DeleteIcon /></IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</div>

<TablePagination
  className="mt-4"
  component="div"
  count={staffList.length}
  page={page}
  onPageChange={handleChangePage}
  rowsPerPage={rowsPerPage}
  onRowsPerPageChange={handleChangeRowsPerPage}
  rowsPerPageOptions={[25, 100, { label: 'All', value: -1 }]}
/>

    </div>
    </div>
  );
};

export default AddStaff;

