import { useRef, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom"; // Import Link from react-router-dom
import "./Navbar.css";


function Navbar() {
 

  const navRef = useRef();
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNavbar = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeNavbar = () => {
    setIsNavOpen(false);
  };


  
  return (
    <div className="navbar-container">
      <div className="sindhi-container">
        <img src="/sindhi2.jpg" alt="Sindhi Background" className="sindhi" />
      </div>
      <header>
        <div className="logo">
        <RouterLink to ='/'>
          <img src="/logo2.png" alt="Logo" className="logo-image" />
         </RouterLink>
        </div>
        
        <nav className={isNavOpen ? "responsive_nav " : ""} ref={navRef}>
          
          <ScrollLink to="home" smooth={true} duration={500} onClick={closeNavbar}>
            Home
          </ScrollLink>

          <ScrollLink to="vision" smooth={true} duration={500} onClick={closeNavbar}>
            Vision
          </ScrollLink>
          <ScrollLink to="privatecolleges" smooth={true} duration={500} onClick={closeNavbar}>
            Private Colleges in Sindh
          </ScrollLink>
          <ScrollLink to="downloads" smooth={true} duration={500} onClick={closeNavbar}>
            Downloads
          </ScrollLink>
          <ScrollLink to="parents-corner" smooth={true} duration={500} onClick={closeNavbar}>
            Parents Corner
          </ScrollLink>
          <ScrollLink to="about-us" smooth={true} duration={500} onClick={closeNavbar}>
            About Us
          </ScrollLink>

          <div className="lg:border-l border-green-700 h-16 mx-4">
            <div className="flex lg:flex-column">
              <RouterLink to="/login" onClick={closeNavbar} className="lg:mr-4">
                Login
              </RouterLink>
              <RouterLink to="/register" onClick={closeNavbar}>
                Register
              </RouterLink>
              {/* <RouterLink to="/main" onClick={closeNavbar}>
                Dashboard
              </RouterLink> */}
            </div>
          </div>
          
          <button className="nav-btn nav-close-btn" onClick={toggleNavbar}>
            {isNavOpen ? <FaTimes /> : <FaBars />}
          </button>
        </nav>
        <button className="nav-btnbar" onClick={toggleNavbar}>
          {isNavOpen ? <FaTimes /> : <FaBars />}
        </button>
      </header>
    </div>
    
  );
}

export default Navbar;
