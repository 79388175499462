import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import Box from '@mui/material/Box';
import { Container, Typography, FormControl, Select, MenuItem, Popover } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';

const columns = [
  { field: 'id', headerName: 'Regd. ID', flex: 1 },
  { field: 'Region', headerName: 'Region', flex: 2 },
  { field: 'name', headerName: 'College Name', flex: 2 },
  { field: 'District', headerName: 'District', flex: 2 },
  { field: 'Address', headerName: 'Address', flex: 2 },
  { field: 'level', headerName: 'Level of Study', flex: 2 },
  { field: 'Principal', headerName: 'Principal Name', flex: 2 },
  { field: 'Contact', headerName: 'Contact No.', flex: 2 },
  { field: 'inspectionDate', headerName: 'Inspection Scheduled On', flex: 2 },
];

function createData(id, Region, name, District, Address, level, Expiring, Applied_Online, Submitted_to_Directorate, Inspected_on, Observation_Querries, Replies_Submitted, Generate_Outward_Letter, Submitted_to_Secretariate_on) {
  const today = new Date();
  const expirationDate = new Date(Expiring);
  const daysRemaining = Math.floor((expirationDate - today) / (1000 * 60 * 60 * 24));
  let status = 'Active';

  if (daysRemaining < 0) {
    status = 'Expired';
  } else if (daysRemaining <= 15) {
    status = 'Expiring Soon';
  }

  return {
    id,
    Region,
    name,
    District,
    Address,
    level,
    Expiring,
    Status: status,
    Applied_Online,
    Submitted_to_Directorate,
    Inspected_on,
    Observation_Querries,
    Replies_Submitted,
    Generate_Outward_Letter,
    Submitted_to_Secretariate_on,
    inspectionDate: Inspected_on ? new Date(Inspected_on) : null,
  };
}

const rows = [
  createData('01010001', 'KARACHI', 'ADLER COLLEGE', 'Karachi', '123 Main St', 'INTERMEDIATE', 'abc'),
  createData('02010002', 'HYDERABAD', 'HIAST COLLEGE', 'Hyderabad', '456 Elm St', 'DEGREE', '2023-10-15', '2023-09-02', '2023-09-06', '2023-09-11', '2023-09-13', '2023-09-16', '2023-09-19', '2023-09-22'),
  createData('03010003', 'SUKKUR', 'BAHRIA COLLEGE KHAIRPUR', 'Sukkur', '789 Oak St', 'INTERMEDIATE', '2023-09-27', '2023-09-03', '2023-09-07', '2023-09-12', '2023-09-14', '2023-09-17', '2023-09-20', '2023-09-23'),
];

export default function CollegeDetailsTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [filteredRows, setFilteredRows] = useState(rows);
  const [selectedDistrict, setSelectedDistrict] = useState('All');
  const [selectedDate, setSelectedDate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = () => {
    const filteredData = rows.filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === 'string' &&
          value.toLowerCase().includes(searchText.toLowerCase())
      )
    );
    setFilteredRows(filteredData);
  };

  const handleExport = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredRows);
    XLSX.utils.book_append_sheet(wb, ws, 'CollegeData');
    XLSX.writeFile(wb, 'CollegeData.xlsx');
  };

  const handleDistrictChange = (event) => {
    const selected = event.target.value;
    setSelectedDistrict(selected);
    if (selected === 'All') {
      setFilteredRows(rows);
    } else {
      const filteredData = rows.filter((row) =>
        row.District.toLowerCase() === selected.toLowerCase()
      );
      setFilteredRows(filteredData);
    }
  };

  const openDatePicker = (event, rowIndex) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowIndex(rowIndex);
  };

  const handleDatePickerClose = () => {
    setAnchorEl(null);
    setSelectedRowIndex(null);
  };

  const handleDateChange = (date) => {
    if (selectedRowIndex !== null) {
      const updatedRows = [...filteredRows];
      updatedRows[selectedRowIndex].inspectionDate = date;
      setFilteredRows(updatedRows);
    }
    handleDatePickerClose();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Typography variant="h4">Schedule for Inspection of Colleges</Typography>
      <Paper elevation={5}>
        <Container style={{ paddingTop: '10px', marginTop: '20px', Width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: '8%', marginTop: '10px', marginBottom: '10px', padding: '2px' }}>
            <div>
              <TextField
                label="Search"
                variant="outlined"
                size="small"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <Button variant="contained" onClick={handleSearch} style={{ marginLeft: '8px' }}>
                Search
              </Button>
            </div>
            <div>
              <FormControl variant="outlined" size="small">
                <Select
                  value={selectedDistrict}
                  onChange={handleDistrictChange}
                  label="District"
                >
                  <MenuItem value="All">All Districts</MenuItem>
                  <MenuItem value="Karachi">Karachi</MenuItem>
                  <MenuItem value="Hyderabad">Hyderabad</MenuItem>
                  <MenuItem value="Sukkur">Sukkur</MenuItem>
                </Select>
              </FormControl>
              <Button variant="contained" onClick={handleExport} style={{ marginLeft: '8px' }}>
                Download
              </Button>
            </div>
          </div>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <TableContainer component={Paper} elevation={3} style={{ height: 470, width: '100%',  marginBottom: '16px' }}>
              <Table Header aria-label="table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.field}
                        align="center"
                        style={{ flex: column.flex }}
                      >
                        {column.headerName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {columns.map((column) => {
                            const value = row[column.field];
                            return (
                              <TableCell key={column.field} align="center">
                                {column.field === 'inspectionDate' ? (
                                  <>
                                    <div>
                                      <span>
                                        {row.inspectionDate instanceof Date && !isNaN(row.inspectionDate)
                                          ? dayjs(row.inspectionDate).format('YYYY-MM-DD')
                                          : row.inspectionDate
                                            ? dayjs(row.inspectionDate).format('YYYY-MM-DD')
                                            : 'Not Set'}
                                      </span>
                                      <EditIcon
                                        fontSize="small"
                                        onClick={(e) => openDatePicker(e, index)}
                                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                                      />
                                    </div>
                                    <Popover
                                      open={selectedRowIndex === index && Boolean(anchorEl)}
                                      anchorEl={anchorEl}
                                      onClose={handleDatePickerClose}
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                      }}
                                    >
                                      <DatePicker
                                        label="Edit Inspection Date"
                                        inputFormat="yyyy-MM-dd"
                                        value={row.inspectionDate || null}
                                        onChange={(date) => handleDateChange(date)}
                                        renderInput={(params) => <TextField {...params} size="small" variant="outlined" />}
                                      />
                                    </Popover>
                                  </>
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100, { value: filteredRows.length, label: 'All' }]}
              component="div"
              count={filteredRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Container>
      </Paper>
    </LocalizationProvider>
  );
}
