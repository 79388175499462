import React from 'react'

function Queries() {
  return (
    <div>
     <h1>Observation Queries</h1>
    </div>
  )
}

export default Queries
