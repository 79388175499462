import React from "react";
import CustomRadioButton from "../CustomRadioButton/CustomRadioButton";

const RadioButtonWrapper = ({ error, onChange, rData, mainLabel }) => {
  return (
    <div className="my-8">
      {mainLabel && (
        <label style={{ color: "#AAA9A9" }} className="block text-sm font-bold mb-1">
          {mainLabel}
        </label>
      )}

      <div className="w-full flex">
        {rData?.map((item) => (
          <CustomRadioButton
            name={item?.name}
            value={item?.value}
            onChange={onChange}
            label={item?.label}
            checked={item?.checked}
          />
        ))}
      </div>
      {error && <p className="mb-4 text-red-500 text-xs italic">{error}</p>}
    </div>
  );
};

export default RadioButtonWrapper;
