import { Routes, Route } from "react-router-dom"
import Landing from "./Landing"
// import Register from './components/register/Register'
// import Login from './components/login/Login'
// import ChangePassword from "./components/forgotpassword/Change"
import CollegePortal from "../components/collegeadmin/Layout/Main"
import Director from "../components/directorateadmin/Layout/Main"
import AuthUser from "../components/api/Api"

import Guest from "./Guest"





function Auth() {
const {getToken} = AuthUser();
  if(!getToken()){
    return <Guest/>
  }

  return (
    <>
    
    <Routes>
      {/* <Route path="/" element={<Landing/>}/> */}
      {/* <Route path="/register" element={<Register/>}/> */}
      <Route path="/admin/*" element={<Director/>}/>
      <Route path="/college/*" element={<CollegePortal/>}/>
      {/* <Route path="/login" element={<Login/>}/> */}
      {/* <Route path="/forgotpassword" element={<ChangePassword/>}/> */}
      </Routes>
      
</>
  )
}

export default Auth
