import React, { useState, useEffect } from "react";
import { HiPencil } from "react-icons/hi";

const CollegeOwnerShip = ({ ownershipdata }) => {
  const [collegeOwnerShip, setCollegeOwnerShip] = useState({});
  const [error, setError] = useState(null);
// console.log('owernship',collegeOwnerShip)
  useEffect(() => {
    if (ownershipdata[0]) {
      setCollegeOwnerShip(ownershipdata[0]);
    } 
  }, [ownershipdata]);

  

  return (
    <div className="md:py-1 p-1 ">
      <div className="w-full border rounded-md">
        <div className="p-3 text-gray-400 border-b bg-gray-100 flex justify-between items-center">
          <h1 className="text-lg font-semibold">OwnerShip</h1>
          {/* <div className="text-lg border border-blue-800 rounded-full cursor-pointer w-9 h-9 shadow-md bg-white flex justify-center items-center">
            <HiPencil
              className="text-blue-800 text-2xl"
              // onClick={() => setEdit(true)}
            />
          </div> */}
        </div>
        <div className=' border-4 mt-1 p-2'>
        <div className="md:flex p-2">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Total Area of College
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeOwnerShip?.college_area
                  ? collegeOwnerShip?.college_area
                  : "N/A"} Sq. Yds
              </p>
            </div>
          </div>
</div>
<div className=' border-4 mt-1 p-2'>
          <div className="md:flex p-2">
          <div className="w-1/3">
            <h2 className="font-semibold text-gray-500 text-sm">
              College Owned By
            </h2>
          </div>
          <div className="w-2/3">
            <p className="text-sm text-blue-800 font-semibold">
              {collegeOwnerShip?.college_owner
                ? collegeOwnerShip?.college_owner
                : "N/A"}
            </p>
          </div>
        </div>

        {collegeOwnerShip?.college_owner === "individual" && (
  <>
    
    <div className="md:flex p-2">
      <div className="w-1/3">
        <h2 className="font-semibold text-gray-500 text-sm">
          Name of Owner
        </h2>
      </div>
      <div className="w-2/3">
        <p className="text-sm text-blue-800 font-semibold">
          {collegeOwnerShip?.individual_owner_name || "N/A"}
        </p>
      </div>
    </div>

    <div className="md:flex p-2">
      <div className="w-1/3">
        <h2 className="font-semibold text-gray-500 text-sm">
          CNIC No. of Owner
        </h2>
      </div>
      <div className="w-2/3">
        <p className="text-sm text-blue-800 font-semibold">
          {collegeOwnerShip?.individual_owner_cnic || "N/A"}
        </p>
      </div>
    </div>

    <div className="md:flex p-2">
      <div className="w-1/3">
        <h2 className="font-semibold text-gray-500 text-sm">
          Contact No. of Owner
        </h2>
      </div>
      <div className="w-2/3">
        <p className="text-sm text-blue-800 font-semibold">
          {collegeOwnerShip?.contact_no || "N/A"}
        </p>
      </div>
    </div>
  </>
)}

{collegeOwnerShip?.college_owner === "organisation" && (
  <>
  <div className="md:flex p-2">
      <div className="w-1/3">
        <h2 className="font-semibold text-gray-500 text-sm">
          Name of Organisation
        </h2>
      </div>
      <div className="w-2/3">
        <p className="text-sm text-blue-800 font-semibold">
          {collegeOwnerShip?.organisation_name || "N/A"}
        </p>
      </div>
    </div>
    <div className="md:flex p-2">
      <div className="w-1/3">
        <h2 className="font-semibold text-gray-500 text-sm">
          Organisation Managed By
        </h2>
      </div>
      <div className="w-2/3">
        <p className="text-sm text-blue-800 font-semibold">
          {collegeOwnerShip?.organisation_managed_by || "N/A"}
        </p>
      </div>
    </div>
  </>
)}
</div>

<div className=' border-4 mt-1 p-2'>
          
        <div className="md:flex p-2">
          <div className="w-1/3">
            <h2 className="font-semibold text-gray-500 text-sm">
              Building Category
            </h2>
          </div>
          <div className="w-2/3">
            <p className="text-sm text-blue-800 font-semibold">
              {collegeOwnerShip?.building
                ? collegeOwnerShip?.building
                : "N/A"}
            </p>
          </div>
        </div>

        {collegeOwnerShip?.building === "owned" && (
          <>
            <div className="md:flex p-2">
              <div className="w-1/3">
                <h2 className="font-semibold text-gray-500 text-sm">
                  Name of Building Owner
                </h2>
              </div>
              <div className="w-2/3">
                <p className="text-sm text-blue-800 font-semibold">
                  {collegeOwnerShip?.building_owner_name
                    ? collegeOwnerShip?.building_owner_name
                    : "N/A"}
                </p>
              </div>
            </div>
            <div className="md:flex p-2">
              <div className="w-1/3">
                <h2 className="font-semibold text-gray-500 text-sm">
                  CNIC of Building Owner
                </h2>
              </div>
              <div className="w-2/3">
                <p className="text-sm text-blue-800 font-semibold">
                  {collegeOwnerShip?.building_owner_cnic
                    ? collegeOwnerShip?.building_owner_cnic
                    : "N/A"}
                </p>
              </div>
            </div>
            <div className="md:flex p-2">
              <div className="w-1/3">
                <h2 className="font-semibold text-gray-500 text-sm">
                  Contact No. of Building owner
                </h2>
              </div>
              <div className="w-2/3">
                <p className="text-sm text-blue-800 font-semibold">
                  {collegeOwnerShip?.building_contact_no
                    ? collegeOwnerShip?.building_contact_no
                    : "N/A"}
                </p>
              </div>
            </div>
          </>
        )}

        {collegeOwnerShip?.building === "rented" && (
          <>
            <div className="md:flex p-2">
              <div className="w-1/3">
                <h2 className="font-semibold text-gray-500 text-sm">
                  Duration of Agreement
                </h2>
              </div>
              <div className="w-2/3">
                <p className="text-sm text-blue-800 font-semibold">
                  {collegeOwnerShip?.duration_of_agreement
                    ? collegeOwnerShip?.duration_of_agreement
                    : "N/A"}
                </p>
              </div>
            </div>
            <div className="md:flex p-2">
              <div className="w-1/3">
                <h2 className="font-semibold text-gray-500 text-sm">
                  Agreement Starts On
                </h2>
              </div>
              <div className="w-2/3">
                <p className="text-sm text-blue-800 font-semibold">
                  {collegeOwnerShip?.agreement_start_date
                    ? collegeOwnerShip?.agreement_start_date
                    : "N/A"}
                </p>
              </div>
            </div>
            <div className="md:flex p-2">
              <div className="w-1/3">
                <h2 className="font-semibold text-gray-500 text-sm">
                  Agreement Ends On
                </h2>
              </div>
              <div className="w-2/3">
                <p className="text-sm text-blue-800 font-semibold">
                  {collegeOwnerShip?.agreement_end_date
                    ? collegeOwnerShip?.agreement_end_date
                    : "N/A"}
                </p>
              </div>
            </div>
          </>
        )}
          
        </div>
      


      
        <div className="py-3  text-gray-400  flex justify-between items-center">
          <h1 className="text-lg font-semibold">Bank Account Details</h1>
          
        </div>
        <div className=' border-4 p-2'>
        <div className="md:flex p-2">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Name of Bank
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeOwnerShip?.college_bank_name
                  ? collegeOwnerShip?.college_bank_name
                  : "N/A"}
              </p>
            </div>
          </div>


        <div className="md:flex p-2">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Bank Account Title
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeOwnerShip?.college_bank_title
                  ? collegeOwnerShip?.college_bank_title
                  : "N/A"}
              </p>
            </div>
          </div>



        <div className="md:flex p-2">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">
                Bank Account No. (IBAN)
              </h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeOwnerShip?.college_bank_iban
                  ? collegeOwnerShip?.college_bank_iban
                  : "N/A"}
              </p>
            </div>
          </div>


      </div>
    </div>
    </div>
  );
};

export default CollegeOwnerShip;
