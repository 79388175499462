import React from 'react'
import {Paper} from '@mui/material'
import TeachingStaff from './TeachingStaff'
// import NonTeachingStaff from './NonTeachingStaff'
const Stafflist = () => {
  return (
    <Paper elevation={3}>
      

        <TeachingStaff />
      
        {/* <NonTeachingStaff /> */}
    </Paper>
  )
}

export default Stafflist
