import React, { useState, useEffect } from "react";
import AuthUser from "../../../../../api/Api";
import { HiPencil } from "react-icons/hi";
import Images from './PreviewMOUDisplay';

const CollegeBuildingInfo = ({ setEdit, collegeId }) => {
  const { http } = AuthUser();
  const [collegeBuildingInfo, setCollegeBuildingInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const res = await http.get(`college-building/${collegeId}`);
      if (res?.data?.data) {
        setCollegeBuildingInfo(res.data.data[0]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [collegeId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="p-6">
      <div className="w-full border rounded-md">
        <div className="p-4 text-gray-400 border-b bg-gray-100 flex justify-between items-center">
          <h1 className="text-lg font-semibold">Building Structure</h1>
          <div className="text-lg border border-blue-800 rounded-full cursor-pointer w-9 h-9 shadow-md bg-white flex justify-center items-center">
            <HiPencil className="text-blue-800 text-2xl" onClick={() => setEdit(true)} />
          </div>
        </div>
        <div className="border-4 m-4 p-4">
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">Type of Building</h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeBuildingInfo?.building_type || "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className="border-4 m-4 p-4">
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">Total No. of Rooms</h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeBuildingInfo?.total_rooms || "N/A"}
              </p>
            </div>
          </div>
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">Total No. of Class Rooms</h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeBuildingInfo?.total_classrooms || "N/A"}
              </p>
            </div>
          </div>
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">Total Staff Rooms</h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeBuildingInfo?.total_staffrooms || "N/A"}
              </p>
            </div>
          </div>
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">Total No. of Principal Rooms</h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeBuildingInfo?.total_principalrooms || "N/A"}
              </p>
            </div>
          </div>
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">Total No. of Admin Office</h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeBuildingInfo?.total_adminoffice || "N/A"}
              </p>
            </div>
          </div>
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">Total No. of Miscellaneous Rooms (Store Room etc.)</h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeBuildingInfo?.total_miscrooms || "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className="border-4 m-4 p-4">
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">Availability of Laboratories</h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeBuildingInfo?.availability_of_laboratories || "N/A"}
              </p>
            </div>
          </div>
          {collegeBuildingInfo?.availability_of_laboratories === 'Yes' && (
            <>
              <div className="md:flex p-4">
                <div className="w-1/3">
                  <h2 className="font-semibold text-gray-500 text-sm">Number of Physics Labs</h2>
                </div>
                <div className="w-2/3">
                  <p className="text-sm text-blue-800 font-semibold">
                    {collegeBuildingInfo?.physics_lab_count || "N/A"}
                  </p>
                </div>
              </div>
              <div className="md:flex p-4">
                <div className="w-1/3">
                  <h2 className="font-semibold text-gray-500 text-sm">Number of Chemistry Labs</h2>
                </div>
                <div className="w-2/3">
                  <p className="text-sm text-blue-800 font-semibold">
                    {collegeBuildingInfo?.chemistry_lab_count || "N/A"}
                  </p>
                </div>
              </div>
              <div className="md:flex p-4">
                <div className="w-1/3">
                  <h2 className="font-semibold text-gray-500 text-sm">Number of Biology Labs</h2>
                </div>
                <div className="w-2/3">
                  <p className="text-sm text-blue-800 font-semibold">
                    {collegeBuildingInfo?.biology_lab_count || "N/A"}
                  </p>
                </div>
              </div>
              <div className="md:flex p-4">
                <div className="w-1/3">
                  <h2 className="font-semibold text-gray-500 text-sm">Number of Computer Labs</h2>
                </div>
                <div className="w-2/3">
                  <p className="text-sm text-blue-800 font-semibold">
                    {collegeBuildingInfo?.computer_lab_count || "N/A"}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="border-4 m-4 p-4">
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">Availability of Library</h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeBuildingInfo?.availability_of_library || "N/A"}
              </p>
            </div>
          </div>
          {collegeBuildingInfo?.availability_of_library === 'Yes' && (
            <div className="md:flex p-4">
              <div className="w-1/3">
                <h2 className="font-semibold text-gray-500 text-sm">Total No. of Books</h2>
              </div>
              <div className="w-2/3">
                <p className="text-sm text-blue-800 font-semibold">
                  {collegeBuildingInfo?.total_books || "N/A"}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="border-4 m-4 p-4">
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">Total No. of Washrooms</h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeBuildingInfo?.availability_of_washrooms || "N/A"}
              </p>
            </div>
          </div>
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">Total No. of Boys Washrooms</h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeBuildingInfo?.washroom_boys_count || "N/A"}
              </p>
            </div>
          </div>
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">Total No. of Girls Washrooms</h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeBuildingInfo?.washroom_girls_count || "N/A"}
              </p>
            </div>
          </div>
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">Total No. of Staff Washrooms</h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeBuildingInfo?.washroom_staff_count || "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className="border-4 m-4 p-4">
          <div className="md:flex p-4">
            <div className="w-1/3">
              <h2 className="font-semibold text-gray-500 text-sm">Availability of Playground</h2>
            </div>
            <div className="w-2/3">
              <p className="text-sm text-blue-800 font-semibold">
                {collegeBuildingInfo?.availability_of_playground || "N/A"}
              </p>
            </div>
          </div>
          {collegeBuildingInfo?.availability_of_playground === 'Yes' && (
            <div className="md:flex p-4">
              <div className="w-1/3">
                <h2 className="font-semibold text-gray-500 text-sm">Area of Playground (In Sq.Yds)</h2>
              </div>
              <div className="w-2/3">
                <p className="text-sm text-blue-800 font-semibold">
                  {collegeBuildingInfo?.playground_area || "N/A"}
                </p>
              </div>
            </div>
          )}
          {collegeBuildingInfo?.availability_of_playground === 'No' && <Images />}
        </div>
      </div>
    </div>
  );
};

export default CollegeBuildingInfo;
