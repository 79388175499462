import React, { useState, useEffect } from 'react';
import Paper from "@mui/material/Paper";
import CircularProgress from '@mui/material/CircularProgress';
import AuthUser from '../../../api/Api';
import Region from '../../components/Region'
import District from '../../components/District'
import Town from '../../components/Town'

const GET_PROVINCE = '/regions';  // Adjust the API endpoint

const Location = () => {
  const { http } = AuthUser();
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0); // Initialize progress state

  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [towns, setTowns] = useState([]);

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch regions data
        const provinceResponse = await http.get(GET_PROVINCE);
        const regionsData = provinceResponse.data.regions;
        // Extract districts and towns from regions data
        const districtsData = provinceResponse.data.districts;
        const townsData = provinceResponse.data.towns;

        setRegions(regionsData);
        setDistricts(districtsData);
        setTowns(townsData);
        setLoading(false); // Set loading to false after data fetching is complete
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call fetchData function
    fetchData();
  }, []); // Empty dependency array to ensure the effect runs only once

  // Calculate loading progress percentage
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const diff = Math.random() * 10;
        return prevProgress >= 100 ? 100 : prevProgress + diff;
      });
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {loading ? ( // Render CircularProgress while loading
        <div className="flex justify-center items-center w-full h-full">
          <div>
            <CircularProgress />
            <p>Please wait, loading... ({Math.round(progress)}%)</p>
          </div>
        </div>
      ) : (
        <Paper elevation={5} className="mt-10">
          <div className="flex">
            <Region regionsData={regions} />
            <District districtsData={districts} />
            <Town townsData={towns} />
          </div>
        </Paper>
      )}
    </>
  );
};

export default Location;
