/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
const callouts = [
  {
    name: 'Syed Sardar Ali Shah',
    description: 'Minister of Education,Sindh',
    imageSrc: '/moe.jpeg',
    imageAlt: 'Minister of Education, Sindh',
    href: '#',
  },
  {
    name: 'Asif Ikram',
    description: 'Secretary, College Education Department, Sindh',
    imageSrc: '/sced.jpeg', // Corrected path
    imageAlt: 'Secretary College Education Department',
    href: '#',
  },
  {
    name: 'Prof. Dr Abdul Qadir Shah',
    description: 'Director, Directorate of Inspection And Registration of Private Colleges, Sindh @ Karachi, College Education Department, Sindh',
    imageSrc: '/director.jpeg',
    imageAlt: 'Director, Private College Education Department',
    href: '#',
  },
]

export default function Leaders() {
  return (
    <div className="py-12 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto py-16 sm:py-24 lg:py-32 lg:max-w-none">
          <h2 className="text-3xl text-center font-bold text-sky-800 sm:text-4xl">Visionary Leaders</h2>

          <div className="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-6">
            {callouts.map((callout) => (
              <div key={callout.name} className="group">
                <div className=" bg-white rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-96 lg:aspect-w-1 lg:aspect-h-1">
                  <img
                    src={callout.imageSrc}
                    alt={callout.imageAlt}
                    className="w-full h-full object-center object-fill"
                  />
                </div>
                <h3 className="mt-6 text-sm text-emerald-700 font-medium">
                    <span className="absolute inset-0" />
                    {callout.name}
                </h3>
                <p className="text-base font-semibold text-gray-900">{callout.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
